import { denormalisedResponseEntities } from '../../util/data';
import { fetchCurrentUser } from '../../ducks/user.duck';

// ================ Thunks ================ //

export const updateHasVtiger = () => (dispatch, getState, sdk) => {
  return new Promise((resolve, reject) => {
    return sdk.currentUser
      .updateProfile(
        {
          publicData: {
            hasVtiger: true,
          },
        },
        {
          expand: true,
          include: ['profileImage'],
          'fields.image': ['variants.square-small', 'variants.square-small2x'],
        }
      )
      .then(response => {
        const entities = denormalisedResponseEntities(response);
        dispatch(fetchCurrentUser());
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }

        const currentUser = entities[0];
        return resolve(currentUser);
      })
      .catch(err => {
        // pass the same error so that the SAVE_ADDRESS_DETAILS_SUCCESS
        // action will not be fired
        reject(err);

        throw err;
      });
  });
};
