import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionLocations.module.css';

import nyImage from './images/ny-yogi.jpg';
import laImage from './images/la-yogi.jpg';
import sfImage from './images/sf-yogi.jpg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
        />
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.locations}>
        {locationLink(
          'Liverpool',
          nyImage,
          '?address=Liverpool%2C%20Merseyside%2C%20England&bounds=53.589405%2C-2.777467%2C53.322243%2C-3.10547'
        )}
        {locationLink(
          'Sefton',
          laImage,
          '?address=Sefton%2C%20Merseyside%2C%20England&bounds=53.5195298045611%2C-2.94641842948246%2C53.4892822088692%2C-2.99944021112905'
        )}
        {locationLink(
          'Knowsley',
          sfImage,
          '?address=Knowsley%2C%20Merseyside%2C%20England&bounds=53.5039071217145%2C-2.80569515811073%2C53.3949445792062%2C-2.92261611058295'
        )}
        {locationLink(
          'St Helens',
          sfImage,
          '?address=St%20Helens%2C%20Merseyside%2C%20England&bounds=53.4927847143492%2C-2.57674303884982%2C53.4094723383281%2C-2.77929402531203'
        )}
        {locationLink(
          'Wirral',
          sfImage,
          '?address=Wirral%2C%20Merseyside%2C%20England&bounds=53.425108%2C-2.939519%2C53.296265%2C-3.229018'
        )}
        {locationLink(
          'Halton',
          sfImage,
          '?address=Halton%2C%20England&bounds=53.4025371549728%2C-2.5952223988011%2C53.3050200681699%2C-2.83245725594396'
        )}
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
