import React, { Component } from "react";
import PropTypes from "prop-types";
import css from "./Styles.css";

class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const { onClick, props: { isOpen, label } } = this;

    return (
      <div>
      <div >
        <div className={css.label} onClick={onClick} 
        style={{ 
          background: "#f7f7f7",
          cursor: "pointer", 
          padding: "15px 20px", 
          margin: "2px", 
          fontSize: "1.2rem",
          }}
        >
          {label}
          <div style={{ float: "right", color: "#4c0d96", fontSize: "1rem"}}>
            {!isOpen && <span>&#x25B2;</span>}
            {isOpen && <span>&#x25BC;</span>}
          </div>
        </div>
      </div>
        {isOpen && (
          <div
            style={{
              background: "#ffffff",
              padding: "10px 0px 0px 0px",
              marginBottom: "15px",
              position: "relative",
            }}
          >
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

export default AccordionSection;
