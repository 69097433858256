import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import runningLeftHand from './runningLeftHand.png';
import css from './DeafCommJoinContent.module.css';

const DeafCommJoinContent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <h2>Who can join?</h2>
      <p>Anyone who is Deaf, uses interpreters or other communication professionals and lives in the North West.</p>

      <h2>Why should I join?</h2>
      <p>Our aim is for Deaf members to co-create and run an interpreting service in a way that 
        has never been done before. This includes the development of our platform.</p>

      <img src={runningLeftHand} alt="Signalise Hand running left" className={css.runningLeftHand}/>

      <h2>Benefits of User membership:</h2>
      <ul className={css.listContainer}>
        <li>Ownership and involvement in a local community interpreting business</li>
        <li>More choice and control of how you receive access to services</li>
        <li>Use and support more local professionals</li>
        <li>Being able to feedback directly and change how the business works</li>
        <li>Opportunity to help campaign for change to make sure access for Deaf people improves</li>
        <li>Access to training, workshops, meetings</li>
        <li>Close relationship to interpreting service and interpreters</li>
        <li>Protecting the future of local interpreting services</li>
        <li>If buying shares, opportunity to earn interest in future &#40;see <Link to="join-us-investors">Investor Membership</Link> for more information&#41;</li>
      </ul>
      
      <p>If you are in the North West and would like to become a member see our <Link to="join-us">Join Us</Link> page.
</p>
      
    </div>
  );
};

DeafCommJoinContent.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

DeafCommJoinContent.propTypes = {
  rootClassName: string,
  className: string,
};

export default DeafCommJoinContent;
