//import merge from 'lodash/merge';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SAVE_ADDRESS_DETAILS_REQUEST = 'app/AddressDetailsPage/SAVE_ADDRESS_DETAILS_REQUEST';
export const SAVE_ADDRESS_DETAILS_SUCCESS = 'app/AddressDetailsPage/SAVE_ADDRESS_DETAILS_SUCCESS';
export const SAVE_ADDRESS_DETAILS_ERROR = 'app/AddressDetailsPage/SAVE_ADDRESS_DETAILS_ERROR';

export const SAVE_ADDRESS_DETAILS_CLEAR = 'app/AddressDetailsPage/SAVE_ADDRESS_DETAILS_CLEAR';

// ================ Reducer ================ //

const initialState = {
  saveAddressDetailsInProgress: false,
  addressDetailsChanged: false,
  saveAddressDetailsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_ADDRESS_DETAILS_REQUEST:
      return {
        ...state,
        saveAddressDetailsInProgress: true,
        saveAddressDetailsError: null,
        addressDetailsChanged: false,
      };
    case SAVE_ADDRESS_DETAILS_SUCCESS:
      return { ...state, saveAddressDetailsInProgress: false, addressDetailsChanged: true };
    case SAVE_ADDRESS_DETAILS_ERROR:
      return { ...state, saveAddressDetailsInProgress: false, saveAddressDetailsError: payload };
    case SAVE_ADDRESS_DETAILS_CLEAR:
      return {
        ...state,
        saveAddressDetailsInProgress: false,
        saveAddressDetailsError: null,
        addressDetailsChanged: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveAddressDetailsRequest = () => ({ type: SAVE_ADDRESS_DETAILS_REQUEST });
export const saveAddressDetailsSuccess = () => ({ type: SAVE_ADDRESS_DETAILS_SUCCESS });

export const saveAddressDetailsError = error => ({
  type: SAVE_ADDRESS_DETAILS_ERROR,
  payload: error,
  error: true,
});

export const saveAddressDetailsClear = () => ({ type: SAVE_ADDRESS_DETAILS_CLEAR });

// ================ Thunks ================ //

/**
 * Make a address details update request to the API and return the current user.
 */
const requestSaveAddressDetails = params => (dispatch, getState, sdk) => {
  const {
    jobTitle,
    addressDepartment,
    addressLineOne,
    location,
    addressLineTwo,
    addressCity,
    postalCode,
    deafUserGpName,
    deafUserGpAddress,
    deafUserGpCoords,
  } = params;

  let locationData = {};

  if (location) {
    const {
      selectedPlace: { address, origin, bounds },
    } = location;

    const { lat, lng } = origin;
    let boundingBoxMaybe;
    if (!bounds?.ne || !bounds?.sw) {
      boundingBoxMaybe = {};
    } else {
      const { ne, sw } = bounds;
      boundingBoxMaybe = {
        boundingBox: [
          { NElat: ne.lat, NElng: ne.lng },
          { SWlat: sw.lat, SWlng: sw.lng },
        ],
      };
    }

    locationData = {
      address: {
        address,
        building: '',
      },
      origin: { lat, lng },
      ...boundingBoxMaybe,
    };
  }

  return sdk.currentUser
    .updateProfile(
      {
        privateData: {
          jobTitle,
          addressDepartment,
          // addressLineOne,
          addressLineTwo,
          addressCity,
          postalCode,
          deafUserGpName,
          deafUserGpAddress,
          deafUserGpCoords,
          ...locationData,
        },
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveAddressDetailsError(storableError(e)));
      // pass the same error so that the SAVE_ADDRESS_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

// Exported function
export const saveAddressDetails = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestSaveAddressDetails(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveAddressDetailsSuccess());
      })
      // error action dispatched in requestSavePhoneNumber
      .catch(e => null)
  );
};
