import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { NamedLink, ExternalLink } from '..';
import { FormattedMessage } from '../../util/reactIntl';

import hand from './helping-hands.png';
import tipHand from './helping-hand-alert-transparent.png';
import runningLeftHand from './runningLeftHand.png';
import css from './ContractsInfoContent.module.css';

import BritishSignLanguageAlphabetContracts from '../../assets/BritishSignLanguageAlphabetContracts.pdf';
import CommunicationTipsDeafPeopleContracts from '../../assets/CommunicationTipsDeafPeopleContracts.pdf';
import WhyAndHowToBookCommProfessionalsForDeafPeopleContracts from '../../assets/WhyAndHowToBookCommProfessionalsForDeafPeopleContracts.pdf';
import HowToBookContracts from '../../assets/HowToBookContracts.pdf';

const ContractsInfoContent = props => {
  const { rootClassName, className, link } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={`${classes} ${css.staticPageWrapper}`}>
      <div className={css.contentWrapper}>
        <div className={css.intro}>
          <img src={hand} className={css.hand} alt="Signalise helping hands" />
          <h1 className={css.pageTitle}>Welcome to Signalise Co&#x2011;op!</h1>
        </div>

        <section className={css.contentSection}>
          <p>
            We wanted to take this opportunity to introduce ourselves and welcome you to our
            services.
          </p>
          <p>
            Signalise Co-op is co-owned and co-run by members of the local Deaf community and
            British Sign Language (BSL)/English interpreters. Any profit made is reinvested into the co-op and its
            community.
          </p>
          <section className={css.introLinks}>
            This page has the following information:
            <br />
            <a href="#usingServices">Using Our Services</a>
            <br />
            <a href="#infoResources">Information and Resources</a>
            <br />
            <a href="#training">Training</a>
            <br />
            <a href="#ourServices">Our Services</a>
            <br />
            <a href="#ourTeam">Our Team</a>
            <br />
            <a href="#assistance">Assistance</a>
            <br />
          </section>

          <section className={css.sectionContent}>
            <h2 id="usingServices">Using our services</h2>
            <p>
              Services provided are all 24/7/365 and are accessed online, by phone or email -
              details for each service are below.
            </p>
            <p>
              Face to face is the preferred means of communication for D/deaf and deafblind
              patients.
            </p>
            <h3>Booking our face to face services via our platform</h3>
            <p>You can create bookings via the top navigation bar or the button below.</p>
            <NamedLink name="CreateRequirementPage" className={css.bookingButton}>
              <FormattedMessage id="TopbarDesktop.createRequirementListing" />
            </NamedLink>
            <p>
              Please note if you are making a booking after 6.30pm for the following day, please
              call our number below.
            </p>

            <h3>Booking via email/phone</h3>
            <p>
              If you would prefer to use email to book our face to face services, please email:{' '}
              <a href="mailto:bookings@signalise.coop">bookings@signalise.coop</a>, providing the
              following details:
            </p>
            <ul className={css.listContainer}>
              <li>Name of the Deaf service user</li>
              <li>
                <NamedLink name="CPTypesPage">Communication Professional Type</NamedLink>: BSL
                interpreter / Deafblind interpreter / Lipspeaker / Lipspeaker with additional sign
              </li>
              <li>
                Appointment details: start time / end time / location / clinician's name (if
                applicable)
              </li>
              <li>Does the service user have a preferred interpreter?</li>
              <li>Does the service user have a preference for the sex of the interpreter?</li>
              <li>Does the service user have any additional needs? e.g. additional disability</li>
              <li>Is there any further information we need to be aware of?</li>
            </ul>

            <p>
              We are also available to take your bookings by phone. <br /> Please ring:{' '}
              <a href="tel:01518080373">0151 808 0373</a>.
            </p>
            <h3>Booking an immediate/on-demand or pre-booked video interpreted call</h3>
            <p className={css.bookingInfoHelp}>
              <img src={tipHand} alt="Signalise helping hand alert" className={css.hand}></img>
              Please note: you will need to use a work device with a camera and a microphone.
              <br />
              The video interpreting service does not work on Internet Explorer 11. <br />
              You will need to copy the link into Chrome, Edge or Safari.
            </p>
            <p>
              Our video service enables you to call and be connected to a Sign Language interpreter 24/7/365.
              It is useful for short calls such as making appointments or emergencies before an
              interpreter can be on site.
            </p>
            <p>
              This can be used for on demand calls or bookings made in advance. The patient can be
              present for the call or we can connect to them remotely.
            </p>
            <p>Make a booking below and you will receive a link via email.</p>
            <NamedLink name="VideoConferencePage" className={css.bookingButton}>
              Book video service
            </NamedLink>
          </section>

          <section>
            <h2 id="infoResources">Information and Resources</h2>
            <p>
              Information in British Sign Language for patients, families and professionals is on
              the <NamedLink name="DeafPage">Deaf Users' page</NamedLink>.
            </p>
            <p>
              Information about different{' '}
              <NamedLink name="CPTypesPage">Communication Professionals</NamedLink> and why they are
              used.
            </p>
            <h3>Resources to download:</h3>
            <p>
              <a href={BritishSignLanguageAlphabetContracts} download>
                British Sign Language Alphabet Poster
              </a>
            </p>
            <p>
              <a href={CommunicationTipsDeafPeopleContracts} download>
                Communication Tips for Talking to Deaf People
              </a>
            </p>
            <p>
              <a href={WhyAndHowToBookCommProfessionalsForDeafPeopleContracts} download>
                Why and How to Book Communication Professionals for Deaf People
              </a>
            </p>
            <p>
              <a href={HowToBookContracts} download>
                How to Book a Communication Professional for Deaf People
              </a>
            </p>
          </section>

          <section className={css.sectionContent}>
            <h2 id="training">Training</h2>
            <div>
              We have a{' '}
              <ExternalLink
                href={link ?? 'https://training.signalise.coop/users/sign_up?th__ug=ab87fa18'}
              >
                training portal
              </ExternalLink>{' '}
              where you can find short courses on everything you need to know about providing access
              to Deaf service users and using our service and the platform, including walkthrough
              videos. The courses are:
              <br />
              <div>
                <p>
                  <a href="https://training.signalise.coop/courses/how-to-communicate-with-deaf-users">
                    Deaf equality training: how to communicate with Deaf users
                  </a>
                </p>
                <p>
                  <a href="https://training.signalise.coop/courses/social-model-of-disability-the-cultural-model-of-deafness">
                    Social Model of Disability and the cultural model of Deafness
                  </a>
                </p>
                <p>
                  <a href="https://training.signalise.coop/courses/how-to-use-an-interpreter">
                    How to use a British Sign Language (BSL) interpreter / communication professional
                  </a>
                </p>
                <p>
                  <a href="https://training.signalise.coop/courses/how-to-book-an-interpreter">
                    How to book a British Sign Language (BSL) interpreter / communication professional
                  </a>
                </p>
              </div>
            </div>
          </section>

          <img
            src={runningLeftHand}
            alt="Signalise Hand running left"
            className={css.runningLeftHand}
          />

          <h2 id="ourServices">Our services</h2>
          <p>
            Our services are slightly different from what you have experienced before and we hope
            you will enjoy the benefits Signalise provides, including:
          </p>
          <ul className={css.listContainer}>
            <li>
              A dedicated local team Online booking service &#40; as well as options to book by
              email or phone&#41;
            </li>
            <li>Option to favourite particular interpreters</li>
            <li>Option to state preferences of the Deaf user for the sex of their interpreter</li>
            <li>
              Option to request repeat appointments with one interpreter &#40;providing
              continuity&#41;
            </li>
            <li>
              Secure and easy to use video interpreting service for short conversations e.g. booking
              an appointment / telephone triage
            </li>
          </ul>
          <p>
            We hope you will enjoy using our platform but we also have booking staff who are able to
            take bookings over the phone or via email if this better suits your needs. As a local
            provider and a co-op, we are co-owned and run by local interpreters and Deaf users.
          </p>
          <p>
            We offer the highest level of standards and quality of provision. We engage with our
            members and are able to respond quickly to feedback and are responsive to their and your
            needs.
          </p>
        </section>

        <section className={css.sectionContent}>
          <h2 id="ourTeam">Our team</h2>
          <p>These are the people you will be dealing with on a daily basis:</p>
          <div className={css.teamBio}>
            <h3>
              <strong>Communication Services Team Leader</strong>
            </h3>
            <p>
              Julie Connolly is our Communication Services Team Leader. Julie is well known to both
              the deaf community and Sign Language interpreters in the area, having been a team leader at a
              national interpreting service previously based in Liverpool. Julie has many years of
              experience in delivering contracts across health and social care.
            </p>
          </div>

          <div className={css.teamBio}>
            <h3>
              <strong>Business and Development Managers</strong>
            </h3>
            <p>
              Jen Smith and Nicky Evans are our Business and Development Managers. They are both
              highly experienced BSL/English interpreters. Jen takes the lead on tech, finance and
              contracts, whilst Nicky heads up communications and marketing.
            </p>
          </div>
        </section>

        <section className={css.sectionContent}>
          <h2 id="assistance">Need assistance?</h2>
          <p>
            If you need any assistance, please contact us at any time via phone or email. You can
            <NamedLink name="ContactPage"> view all our contact details here</NamedLink>, including
            our contact form, or use our Live Chat function at the bottom right of this website.
          </p>
          <p>
            Please send any feedback to:{' '}
            <a href="mailto:feedback@signalise.coop">feedback@signalise.coop</a>
          </p>
        </section>
      </div>
    </div>
  );
};

ContractsInfoContent.defaultProps = { rootClassName: null, className: null };

ContractsInfoContent.propTypes = {
  rootClassName: string,
  className: string,
};

export default ContractsInfoContent;
