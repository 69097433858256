import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing, ensureCurrentUser } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { EditListingDBSForm } from '../../forms';

import { saveBirthday } from '../../containers/EditListingPage/EditListingPage.duck';

import css from './EditListingDBSPanel.module.css';
import moment from 'moment';
import { useDispatch } from 'react-redux';

export const EditListingDBSPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
    saveBirthdayError,
  } = props;

  const dispatch = useDispatch();

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, privateData } = currentListing.attributes;
  const [initialProps, setInitialProps] = useState({});
  const [updating, setUpdating] = useState(false);

  const user = ensureCurrentUser(currentUser);
  const currentBirthday = user?.attributes?.profile?.privateData?.birthday ?? undefined;

  const dbsCertificateAgreeCheck = { dbsCertificateAgreeCheck: '' } || {
    dbsCertificateAgreeCheck: 'dbsCertificateAgreeCheckConfirmed',
  };
  const DBSUpload = { DBSUpload: '' };
  const dbsCertificateNumber = '';
  const birthday = { birthday: {} };

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDBSPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingDBSPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  let initialValues = {
    title,
    description,
    DBSUpload: privateData.DBSUpload,
    dbsCertificateAgreeCheck: privateData.dbsCertificateAgreeCheck,
    dbsCertificateNumber: privateData?.dbsCertificateNumber,
    birthday: currentBirthday
      ? new Date(`${currentBirthday.year}-${currentBirthday.month}-${currentBirthday.day}`)
      : undefined,
  };

  initialValues = { ...initialValues, ...initialProps };

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDBSForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={async values => {
          const {
            title,
            description,
            DBSUpload,
            dbsCertificateNumber,
            dbsCertificateAgreeCheck,
            birthday,
          } = values;

          const dob =
            typeof birthday == 'undefined'
              ? undefined
              : Array.isArray(birthday)
              ? birthday[0]
              : birthday;

          let updateValues = {
            title: title.trim(),
            description,
            privateData: {
              DBSUpload,
              dbsCertificateNumber: dbsCertificateNumber ?? null,
              dbsCertificateAgreeCheck: dbsCertificateNumber ? dbsCertificateAgreeCheck : null,
            },
          };

          let momentDate = dob ? moment(new Date(dob)) : undefined;
          const birthdayValues = dob
            ? {
                birthday: {
                  day: Number(momentDate.format('DD')),
                  month: Number(momentDate.format('MM')),
                  year: Number(momentDate.format('YYYY')),
                },
              }
            : undefined;

          updateValues = {
            ...updateValues,
            privateData: {
              ...updateValues.privateData,
            },
          };

          setInitialProps({
            ...updateValues,
            ...updateValues.privateData,
            birthday: dob ? momentDate.toISOString() : undefined,
          });

          if (dob) {
            setUpdating(true);
            try {
              await dispatch(
                saveBirthday({
                  privateData: {
                    ...birthdayValues,
                  },
                })
              );
              setUpdating(false);
            } catch (e) {
              setUpdating(false);
            }
          }

          onSubmit(updateValues);
        }}
        DBSUpload={DBSUpload}
        dbsCertificateNumber={dbsCertificateNumber}
        dbsCertificateAgreeCheck={dbsCertificateAgreeCheck}
        birthday={birthday}
        saveBirthdayError={saveBirthdayError}
        currentUser={currentUser}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updating || updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingDBSPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDBSPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDBSPanel;
