import React from 'react';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
} from '../../components';
import { NotFoundPage, TopbarContainer } from '../../containers';
import css from './LoadingPage.module.css';
export const LoadingPage = props => {
  return (
    <Page
      title="Loading ..."
      //scrollingDisabled={scrollingDisabled}
      // author={authorDisplayName}
      contentType="website"
      description="Loading ..."
      // facebookImages={facebookImages}
      // twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ItemPage',
        description: 'Loading...',
        name: 'Loading ...',
        image: 'Loading ...',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.loadingContainer}>
            <IconSpinner className={css.loading} />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};
