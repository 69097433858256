import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  LocationAutocompleteInputField,
  FieldReactSelect,
  FieldSelect,
  Button,
} from '../../components';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import css from './FeedbackForm.module.css';
import { sendFeedback, formatPayload } from '../../util/FeedbackFormAPI';

// The SuccessMessage replaces the form if the API succesfully returns
const SuccessMessage = () => (
  <p className={css.successMessage}>
    Thank you for your message.<br></br>
    One of the team will get back to you as soon as possible.
  </p>
);

// The ErrorMessage is displayed at the top of the form if API returns error
const ErrorMessage = () => (
  <p className={css.errorMessage}>Sorry, we could not process your message right now.</p>
);

class FeedbackForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValue: '',
      emailError: '',
      feedbackValue: '',
      feedbackError: '',
      nameValue: '',
      nameError: '',
      caseStatus: null,
      casePriority: null,
      submitError: false,
      isLoading: false,
      isSubmitted: false,
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleFeedbackChange = this.handleFeedbackChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handlePriorityChange = this.handlePriorityChange.bind(this);
    this.setEmailState = this.setEmailState.bind(this);
  }

  setEmailState = () => {
    if (this.props.userEmail) {
      this.setState({ emailValue: this.props.userEmail });
      console.log(this.state.emailValue);
    }
  };

  handleNameChange(event) {
    this.setState({ nameValue: event.target.value });
  }

  handleEmailChange(event) {
    this.setState({ emailValue: event.target.value });
  }

  handleFeedbackChange(event) {
    this.setState({ feedbackValue: event.target.value });
  }
  handleStatusChange(event) {
    this.setState({ caseStatus: event.target.value });
  }
  handlePriorityChange(event) {
    this.setState({ casePriority: event.target.value });
  }

  componentDidMount() {
    this.setEmailState();
  }
  async handleSubmit(event) {
    event.preventDefault();

    this.setState({ nameError: '', feedbackError: '', emailError: '', submitError: false });

    // Validate the email field
    // Email regex pattern - if an invalid email is entered, Zendesk API will return and error
    const regex = /^(?=(.{1,64}@.{1,255}))([!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9}]{1,64}(\.[!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\[(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\])|([a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{2,63}){1,}))$/; // eslint-disable-line
    if (this.state.emailValue.length > 0 && !regex.test(this.state.emailValue)) {
      this.setState({ emailError: 'Please enter a valid email' });
      return;
    }

    // Validate the feedback field
    if (this.state.feedbackValue.length === 0) {
      this.setState({ feedbackError: 'Please add some feedback' });
      return;
    }

    // If fields are valid, set state to loading and make API POST request
    this.setState({ isLoading: true });
    const payload = {
      publicid: '33687effb6bf3e5c82b6724bb7bf0398',
      urlencodeenable: '1',
      name: 'Web contact form',
      __vtCurrency: '1',
      description: this.state.feedbackValue,
      title: 'Website enquiry',
      casestatus: this.state.caseStatus,
      casepriority: this.state.casePriority,
      contact_id: this.state.nameValue,
    };
    console.log(payload);
    const response = await sendFeedback(payload);

    // If we dont get a success response, set submitError to true to show error message
    if (!response.request) {
      this.setState({ isLoading: false, submitError: true });
      return;
    }

    // If we get a success response, set isSubmitted to true to show the success message
    this.setState({ isLoading: false, isSubmitted: true });
  }
  render() {
    const nameValue =
      this.props.userFirstName && this.props.userLastName
        ? `${this.props.userFirstName} ${this.props.userLastName}`
        : this.state.nameValue;
    const emailValue = this.props.userEmail ? this.props.userEmail : this.state.emailValue;
    console.log(emailValue);
    console.log(this.state.emailValue);

    if (this.state.isSubmitted) {
      return (
        <div role="alert" aria-live="polite" aria-atomic="true">
          <SuccessMessage />
        </div>
      );
    } else {
      return (
        <FinalForm
          {...this.props}
          render={fieldRenderProps => {
            const {
              rootClassName,
              className,
              currentUser,
              disabled,
              formId,
              form,
              handleSubmit,
              inProgress,
              intl,
              invalid,
              values,
            } = fieldRenderProps;
            const classes = classNames(rootClassName || css.root, className);
            return (
              <Form
                className={classes}
                onSubmit={e => {
                  this.submittedValues = values;
                  handleSubmit(e);
                }}
              >
                <FieldTextInput
                  className={css.features}
                  id="name"
                  name="name"
                  label="Name (optional)"
                />
                <FieldTextInput
                  className={css.features}
                  id="email"
                  name="email"
                  label="Email (optional)"
                />
                <FieldTextInput
                  className={css.features}
                  id="description"
                  name="description"
                  label="Provide comments"
                />
                <FieldSelect id="caseStatus" name="caseStatus" label="Case Status">
                  <option disabled value="">
                    Choose from...
                  </option>
                  <option value="New">New</option>
                  <option value="Assigned">Assigned</option>
                  <option value="Open">Open</option>
                  <option value="Wait for customer">Wait for customer</option>
                  <option value="Wait for 3rd party">Wait for 3rd party</option>
                  <option value="Resolved">Resolved</option>
                  <option value="Closed">Closed</option>
                </FieldSelect>{' '}
                <FieldSelect id="casePriority" name="casePriority" label="Case Priority">
                  <option disabled value="">
                    Select Value
                  </option>
                  <option value="Urgent">Urgent</option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </FieldSelect>{' '}
                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  // ready={pristineSinceLastSubmit}
                  // disabled={submitDisabled}
                >
                  <FormattedMessage id="FeedbackForm.sendFeedback" />
                </PrimaryButton>
              </Form>
            );
          }}
        />
      );
    }
  }
}

FeedbackForm.defaultProps = {
  feedbackSubject: 'Platform Contact Form',
  defaultUserName: 'Platform user',
  userFirstName: null,
  userLastName: null,
  userId: null,
  userEmail: null,
};

FeedbackForm.propTypes = {
  subdomain: PropTypes.string,
  feedbackSubject: PropTypes.string,
  defaultUserName: PropTypes.string,
  currentPageURL: PropTypes.string,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  userId: PropTypes.string,
  userEmail: PropTypes.string,
};

export default FeedbackForm;
