import React, { useState, useEffect } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import NamedLink from '../NamedLink/NamedLink';
import NextBookingCard from '../NextBookingCard/NextBookingCard';
import { getBookings } from '../../util/api';
import moment from 'moment';

import css from './SectionNextBooking.module.css';

const SectionNextBooking = props => {
  const { className, isUser } = props;
  const [userFirstBooking, setUserFirstBooking] = useState(null);
  const [bookingPanelLoading, setBookingPanelLoading] = useState(false);
  const checkFirstListing = async () => {
    try {
      setBookingPanelLoading(true);
      const response = await getBookings();
      const bookings = response.bookings;
      const now = moment();
      const filteredBookings = bookings.filter(a => moment(a.date_string).isSameOrAfter(now));

      const last = filteredBookings.slice(-1);
      setBookingPanelLoading(false);
      setUserFirstBooking(last[0]);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    checkFirstListing();
  }, [isUser]);

  const classes = classNames(css.main, className);

  return (
    <div className={classes}>
      <div className={css.contentBox}>
        <div className={css.title}>
          <h2>Your next booking </h2>{' '}
        </div>
        <div>
          <p>
            {bookingPanelLoading
              ? `Loading your bookings, please wait`
              : userFirstBooking
              ? `To see the rest of your bookings, please click here`
              : `You currently have no upcoming bookings. To manage bookings please click
          here`}
          </p>
          <NamedLink name="ManageBookingsPage" className={css.bookingButton}>
            Your Bookings
          </NamedLink>
        </div>
      </div>
      {userFirstBooking ? <NextBookingCard booking={userFirstBooking} /> : null}
    </div>
  );
};

SectionNextBooking.defaultProps = {
  rootClassName: null,
  className: null,
  isUser: null,
};

SectionNextBooking.propTypes = {
  rootClassName: string,
  className: string,
  isUser: bool,
};

export default SectionNextBooking;
