import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import arrayMutators from 'final-form-arrays';
import * as validators from '../../util/validators';
import { required } from '../../util/validators'; // composeValidators removed as not used
import { Form, PrimaryButton, FieldTextInput, FieldCheckboxGroup } from '../../components';

import css from './InvoicePaymentForm.module.css';
const KEY_CODE_ENTER = 13;

class InvoicePaymentFormComponent extends Component {
  constructor(props) {
    super(props);
    this.submittedValues = {};
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const {
      onSubmit,
      //inProgress,
      formId,
      //hasHandledCardPayment,
      //defaultPaymentMethod,
    } = this.props;
    const { initialMessage } = values;
    //const { cardValueValid, paymentMethod } = this.state;
    //const billingDetailsKnown = hasHandledCardPayment || defaultPaymentMethod;
    //const onetimePaymentNeedsAttention = !billingDetailsKnown && !cardValueValid;

    /*if (inProgress || onetimePaymentNeedsAttention) {
      // Already submitting or card value incomplete/invalid
      return;
    }*/

    const params = {
      message: initialMessage ? initialMessage.trim() : null,
      card: this.card,
      formId,
      formValues: values,
      /*paymentMethod: getPaymentMethod(
        paymentMethod,
        ensurePaymentMethodCard(defaultPaymentMethod).id
      ),*/
    };
    onSubmit(params);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            disabled,
            formId,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            saveAddressDetailsError,
            authorDisplayName,
            showInitialMessageInput,
            updateInProgress,
            values,
            openTermsOfService,
          } = fieldRenderProps;

          const optionalText = intl.formatMessage({
            id: 'AddressDetailsForm.optionalText',
          });

          const nameLabel = intl.formatMessage({ id: 'AddressDetailsForm.nameLabel' });

          const namePlaceholder = intl.formatMessage({ id: 'AddressDetailsForm.namePlaceholder' });

          const jobTitleLabel = intl.formatMessage(
            { id: 'AddressDetailsForm.jobTitleLabel' },
            { optionalText: optionalText }
          );

          const jobTitlePlaceholder = intl.formatMessage({
            id: 'AddressDetailsForm.jobTitlePlaceholder',
          });

          const addressDepartmentLabel = intl.formatMessage(
            { id: 'AddressDetailsForm.addressDepartmentLabel' },
            { optionalText: optionalText }
          );

          const addressDepartmentPlaceholder = intl.formatMessage({
            id: 'AddressDetailsForm.addressDepartmentPlaceholder',
          });

          const addressLineOneLabel = intl.formatMessage({
            id: 'AddressDetailsForm.addressLineOneLabel',
          });

          const addressLineOnePlaceholder = intl.formatMessage({
            id: 'AddressDetailsForm.addressLineOnePlaceholder',
          });

          const addressLineTwoLabel = intl.formatMessage(
            { id: 'AddressDetailsForm.addressLineTwoLabel' },
            { optionalText: optionalText }
          );

          const addressLineTwoPlaceholder = intl.formatMessage({
            id: 'AddressDetailsForm.addressLineTwoPlaceholder',
          });

          const addressCityLabel = intl.formatMessage({
            id: 'AddressDetailsForm.addressCityLabel',
          });

          const addressCityPlaceholder = intl.formatMessage({
            id: 'AddressDetailsForm.addressCityPlaceholder',
          });

          const postalCodeLabel = intl.formatMessage({
            id: 'AddressDetailsForm.postalCodeLabel',
          });

          const postalCodePlaceholder = intl.formatMessage({
            id: 'AddressDetailsForm.postalCodePlaceholder',
          });

          const initialMessageLabel = intl.formatMessage(
            { id: 'StripePaymentForm.messageLabel' },
            { messageOptionalText: optionalText }
          );

          const messagePlaceholder = intl.formatMessage(
            { id: 'StripePaymentForm.messagePlaceholder' },
            { name: authorDisplayName }
          );

          // validation messages
          const invoiceNameRequired = intl.formatMessage({
            id: 'InvoicePaymentForm.nameRequired',
          });

          const addressLineOneRequired = intl.formatMessage({
            id: 'InvoicePaymentForm.addressLineOneRequired',
          });

          const addressCityRequired = intl.formatMessage({
            id: 'InvoicePaymentForm.addressCityRequired',
          });

          const postalCodeRequired = intl.formatMessage({
            id: 'InvoicePaymentForm.postalCodeRequired',
          });

          const termsAgreedRequired = intl.formatMessage({
            id: 'InvoicePaymentForm.termsAgreedRequired',
          });
          const checkboxRequired = validators.requiredFieldArrayCheckbox(termsAgreedRequired);

          const termsAgreedOptions = [
            {
              key: 'termsAgreedConfirmed',
              label: 'By clicking the checkbox, you confirm you accept our Terms of Service',
            },
          ];

          const handleTermsKeyUp = e => {
            // Allow click action with keyboard like with normal links
            if (e.keyCode === KEY_CODE_ENTER) {
              openTermsOfService();
            }
          };

          const termsLink = (
            <span
              className={css.termsLink}
              onClick={openTermsOfService}
              role="button"
              onKeyUp={handleTermsKeyUp}
            >
              <FormattedMessage id="InvoicePaymentForm.tos" />
            </span>
          );
          //TODO
          //const isGenericEmailError = saveEmailError && !(emailTakenErrorText || passwordErrorText);
          let genericError = null;

          if (saveAddressDetailsError) {
            genericError = (
              <span className={css.error}>
                <FormattedMessage id="AddressDetailsForm.genericFailure" />
              </span>
            );
          }

          const classes = classNames(rootClassName || css.root, className);
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <h3 className={css.billingHeading}>
                <FormattedMessage id="PaymentMethodsForm.billingDetails" />
              </h3>

              <FieldTextInput
                className={css.features}
                name="name"
                id={formId ? `${formId}.name` : 'name'}
                type="text"
                label={nameLabel}
                placeholder={namePlaceholder}
                validate={required(invoiceNameRequired)}
                disabled={true}
              />

              <FieldTextInput
                className={css.features}
                name="jobTitle"
                id={formId ? `${formId}.jobTitle` : 'jobTitle'}
                type="text"
                label={jobTitleLabel}
                placeholder={jobTitlePlaceholder}
              />
              <FieldTextInput
                className={css.features}
                name="addressDepartment"
                id={formId ? `${formId}.addressDepartment` : 'addressDepartment'}
                type="text"
                label={addressDepartmentLabel}
                placeholder={addressDepartmentPlaceholder}
              />
              <FieldTextInput
                className={css.features}
                name="addressLineOne"
                id={formId ? `${formId}.addressLineOne` : 'addressLineOne'}
                type="text"
                label={addressLineOneLabel}
                placeholder={addressLineOnePlaceholder}
                validate={required(addressLineOneRequired)}
              />
              <FieldTextInput
                className={css.features}
                name="addressLineTwo"
                id={formId ? `${formId}.addressLineTwo` : 'addressLineTwo'}
                type="text"
                label={addressLineTwoLabel}
                placeholder={addressLineTwoPlaceholder}
              />
              <FieldTextInput
                className={css.features}
                name="addressCity"
                id={formId ? `${formId}.addressCity` : 'addressCity'}
                type="text"
                label={addressCityLabel}
                placeholder={addressCityPlaceholder}
                validate={required(addressCityRequired)}
              />
              <FieldTextInput
                className={css.features}
                name="postalCode"
                id={formId ? `${formId}.postalCode` : 'postalCode'}
                type="text"
                label={postalCodeLabel}
                placeholder={postalCodePlaceholder}
                validate={required(postalCodeRequired)}
              />
              <FieldCheckboxGroup
                className={css.features}
                id="termsAgreedCheck"
                name="termsAgreedCheck"
                label={termsLink}
                options={termsAgreedOptions}
                validate={checkboxRequired}
              />

              {showInitialMessageInput ? (
                <div>
                  <h3 className={css.messageHeading}>
                    <FormattedMessage id="StripePaymentForm.messageHeading" />
                  </h3>
                  <FieldTextInput
                    type="textarea"
                    id={`bookingForm-message`}
                    name="initialMessage"
                    label={initialMessageLabel}
                    placeholder={messagePlaceholder}
                    className={css.message}
                  />
                </div>
              ) : null}

              <div className={css.submitContainer}>
                <div className={css.bottomWrapper}>
                  {genericError}
                  <PrimaryButton
                    className={css.submitButton}
                    type="submit"
                    //inProgress={this.state.submitting}
                    inProgress={inProgress}
                    ready={pristineSinceLastSubmit}
                    disabled={submitDisabled}
                  >
                    Send booking request
                  </PrimaryButton>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

InvoicePaymentFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  saveAddressDetailsError: null,
  inProgress: false,
  invoiceName: null,
  addressDepartment: null,
  addressLineOne: null,
  addressLineTwo: null,
  addressCity: null,
  jobTitle: null,
  showInitialMessageInput: true,
  selectedPlace: null,
  fetchErrors: null,
};

const { bool, string, func } = PropTypes;

InvoicePaymentFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  saveAddressDetailsError: propTypes.error,
  showInitialMessageInput: bool,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
  openTermsOfService: func.isRequired,
};

const InvoicePaymentForm = compose(injectIntl)(InvoicePaymentFormComponent);

InvoicePaymentForm.displayName = 'InvoicePaymentForm';

export default InvoicePaymentForm;
