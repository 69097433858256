import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { videoConferenceGetBookings } from '../../util/api';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  NamedRedirect,
  IconCheckmark,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { ensureCurrentUser } from '../../util/data';

import css from './VideoConferencePage.module.css';

function VideoConferenceSuccessPageComponent(props) {
  const { scrollingDisabled, location, user } = props;
  const title = 'Video Service Success';
  const [link, setLink] = useState(null);

  if (!location?.state?.success) {
    return <NamedRedirect name="LandingPage" />;
  }

  const getBookings = async () => {
    setLink(null);
    try {
      const response = await videoConferenceGetBookings();
      // return bookingLink;
      setLink(response.link);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getBookings();
  }, []);

  // console.log(getBookings());
  // const link = async () => await getBookings();
  // console.log(link());
  // const link = getBookings().then(booking => {
  //   console.log('booking' + booking);
  // });
  // console.log(getBookings().then(alert));
  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.successContainer}>
            <div className={css.successHeader}>
              <IconCheckmark style={{ stroke: '#fff' }} />
            </div>
            <div>
              <h2>
                Your Video Interpreting Service Request has been <br />
                submitted successfully.{' '}
              </h2>

              {link && (
                <a className={css.linkButton} href={link}>
                  Your booking link
                </a>
              )}

              <div className={css.infoSection}>
                <p>You will also receive a link by email to access the call.</p>
                <p>
                  At your booking time, please click the link and allow your browser to access the
                  microphone and camera. You can test your audio and video before joining the call.
                </p>
                <p>As soon as an interpreter is free they will join you.</p>
                <p>
                  If you requested a third party to join, they will be sent their link by email at
                  the time of the booking.
                </p>
              </div>
              <p>
                <NamedLink name="LandingPage">Click here</NamedLink> to get back to the main
                homepage
              </p>
              <p>
                <NamedLink name="VideoConferencePage">Click here</NamedLink> to make another video
                interpreting request
              </p>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
}

const { bool, func, shape } = PropTypes;

VideoConferenceSuccessPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { currentUserShowError, currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserShowError,
    user: currentUser,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const VideoConferenceSuccessPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(VideoConferenceSuccessPageComponent);

export default VideoConferenceSuccessPage;
