import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import css from './SectionWhyUs.module.css';

const SectionWhyUs = props => {
  const { mainClassName, className } = props;

  const classes = classNames(mainClassName || css.main, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <h2>
          <FormattedMessage id="SectionWhyUs.titleLineOne" />
        </h2>
      </div>

      <div className={css.contentWrapper}>
        <div className={css.contentSide}>
          <p>
            <FormattedMessage id="SectionWhyUs.description1" />
          </p>
          <strong>
            <FormattedMessage id="SectionWhyUs.description2" />
          </strong>
          <ul className={css.whyUsList}>
            <li>
              <FormattedMessage id="SectionWhyUs.description3" /> <br />
            </li>
            <li>
              <FormattedMessage id="SectionWhyUs.description4" /> <br />
            </li>{' '}
            <li>
              <FormattedMessage id="SectionWhyUs.description5" /> <br />
            </li>{' '}
          </ul>
        </div>
        <div className={css.contentMain}>
          <section id="contact" className={css.intro}>
            <div className={css.videoSection}>
              <div className={css.videoContainer}>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/WFt-48BQEXI?rel=0&origin=https://signalise.coop"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="embedded YouTube clip of a BSL translation"
                ></iframe>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

SectionWhyUs.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionWhyUs.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionWhyUs;
