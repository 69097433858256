import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import arrayMutators from 'final-form-arrays';
import { required, minimumInterpreters, requiredFieldArrayCheckbox } from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldSelect,
  FieldReactSelect,
  FieldCheckboxGroup,
  FieldCheckbox,
} from '../../components';

import hand from './helping-hand-alert-transparent.png';
import css from './EditListingDetailsForm.module.css';
import config from '../../config';

const identity = v => v;

export const EditListingDetailsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        interpreters,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        userTypes,
        fetchErrors,
        values,
        openTermsOfService,
      } = formRenderProps;

      const editBookingMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.editBookingMessage',
      });
      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || !(values?.tos?.length > 0);

      // Labels
      const requiredText = intl.formatMessage({
        id: 'EditListingDetailsForm.requiredText',
      });

      const cpTypeLabel = intl.formatMessage(
        { id: 'EditListingDetailsForm.cpTypeLabel' },
        { requiredText: requiredText }
      );

      // interpreters needed
      const interpretersNeededLabel = intl.formatMessage({
        id: 'EditListingDetailsForm.interpretersNeededLabel',
      });
      const interpretersNeededPlaceholder = intl.formatMessage({
        id: 'EditListingDetailsForm.interpretersNeededPlaceholder',
      });

      //preferred sex
      const preferredSexLabel = intl.formatMessage({
        id: 'VideoConferencingForm.preferredSexLabel',
      });
      const preferredSexPlaceholder = intl.formatMessage({
        id: 'VideoConferencingForm.preferredSexPlaceholder',
      });

      //preferred interpreter
      const preferredInterpreterLabel = intl.formatMessage({
        id: 'EditListingDetailsForm.preferredInterpreterLabel',
      });
      const preferredInterpreterLabel2 = intl.formatMessage({
        id: 'EditListingDetailsForm.preferredInterpreterLabel2',
      });
      const preferredInterpreterPlaceholder = intl.formatMessage({
        id: 'EditListingDetailsForm.preferredInterpreterPlaceholder',
      });

      const termsAgreedOptions = [
        {
          key: 'termsAgreedConfirmed',
          label: 'By clicking the checkbox, you confirm you accept our Terms of Service',
        },
      ];

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          openTermsOfService();
        }
      };

      const interpreterUpdated = options => {
        if (!values.preferredInterpreter) return options;
        else if (
          values.preferredInterpreter.key == 9999 ||
          values.preferredInterpreter.key == 1001
        ) {
          return options;
        } else return options.filter(a => a != values.preferredInterpreter);
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={openTermsOfService}
          role="button"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="InvoicePaymentForm.tos" />
        </span>
      );

      return (
        <Form
          className={classes}
          onSubmit={handleSubmit}
          openTermsOfService={() => this.setState({ tosModalOpen: true })}
        >
          {errorMessage}
          {errorMessageShowListing}
          <div className={css.field}>
            <FieldSelect
              id="cpType"
              name="cpType"
              label={cpTypeLabel}
              autoFocus
              validate={required('This field is required')}
              defaultValue={'BSL / English interpreter'}
            >
              <option disabled value="">
                Choose from...
              </option>
              <option value="BSL / English interpreter">BSL/English interpreter</option>
              <option value="Interpreter for Deafblind people">
                Interpreter for Deafblind people{' '}
              </option>
              <option value="Translator">Translator</option>
              <option value="Lipspeaker">Lipspeaker</option>
              <option value="Speech To Text Reporter">Speech To Text Reporter</option>
              <option value="Manual notetaker">Manual notetaker</option>
              <option value="Electronic notetaker">Electronic notetaker</option>
            </FieldSelect>
          </div>
          <div
            className={css.field}
            style={
              userTypes.includes('contractCustomer') ? { display: 'none' } : { display: 'block' }
            }
          >
            <FieldTextInput
              className={css.building}
              type="number"
              name="interpretersNeeded"
              id="interpretersNeeded"
              min={1}
              max={8}
              defaultValue={1}
              label={interpretersNeededLabel}
              placeholder={interpretersNeededPlaceholder}
              validate={
                (required('Field Communication Professionals is required'),
                minimumInterpreters('A minimum of 1 communication professional is required'))
              }
            />
            <small>
              <FormattedMessage id="EditListingDetailsForm.interpretersNeededExplanation" />
            </small>
          </div>
          <div className={css.field}>
            <FieldSelect
              id="preferredSex"
              name="preferredSex"
              label={preferredSexLabel}
              placeholder={preferredSexPlaceholder}
              defaultValue={'No preference'}
            >
              <option disabled value="">
                Choose either...
              </option>
              {config.custom.prefSexOptions.map(g => (
                <option key={g.key} value={g.label}>
                  {g.label}
                </option>
              ))}
            </FieldSelect>
            {interpreters ? (
              <div className={css.field}>
                <FieldReactSelect
                  id="preferredInterpreter"
                  name="preferredInterpreter"
                  label={preferredInterpreterLabel}
                  placeholder={preferredInterpreterPlaceholder}
                  options={interpreters}
                />{' '}
                {values?.preferredInterpreter?.key == 9999 && (
                  <FieldTextInput
                    id="customInterpreterName"
                    name="customInterpreterName"
                    label="Interpreter Name"
                  />
                )}{' '}
                <div>
                  <FieldReactSelect
                    id="preferredInterpreter2"
                    name="preferredInterpreter2"
                    label={preferredInterpreterLabel2}
                    placeholder={preferredInterpreterPlaceholder}
                    options={interpreterUpdated(interpreters)}
                  />
                  {values?.preferredInterpreter2?.key == 9999 && (
                    <FieldTextInput
                      id="customInterpreterName2"
                      name="customInterpreterName2"
                      label="Interpreter Name"
                    />
                  )}
                </div>
              </div>
            ) : (
              <span> Loading interpreters </span>
            )}
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '2rem',
            }}
            className={css.field}
          ></div>
          <FieldCheckboxGroup
            id="tos"
            name="tos"
            validate={requiredFieldArrayCheckbox('Please accept our terms of service')}
            label={termsLink}
            options={termsAgreedOptions}
          />
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
          {!userTypes.includes('contractCustomer') && (
            <div className={css.bookingInfoHelp}>
              <img src={hand} alt="Signalise helping hand alert" className={css.hand}></img>
              <p>{editBookingMessage}</p>
            </div>
          )}
        </Form>
      );
    }}
  />
);

EditListingDetailsFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingDetailsFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  openTermsOfService: func.isRequired,
};

export default compose(injectIntl)(EditListingDetailsFormComponent);
