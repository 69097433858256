import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import find from './image/Find.png';
import work from './image/Work.png';
import help from './image/Help.png';

import css from './SectionInfo.module.css';

const SectionInfo = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.infoContentSection}>
        <div className={css.infoContent}>
          <img src={find} alt="Signalise find" className={css.infoHand} />
          <h1 className={css.infoChoice}>
            <FormattedMessage id="SectionInfo.choice1" />
          </h1>
          <NamedLink name="AboutPage" className={css.infoButton}>
            {' '}
            <FormattedMessage id="SectionInfo.aboutButton" />
          </NamedLink>
        </div>

        <div className={css.infoContent}>
          <img src={work} alt="Signalise work" className={css.infoHand} />
          <h1 className={css.infoChoice}>
            <FormattedMessage id="SectionInfo.choice2" />
          </h1>
          <NamedLink name="JoinUsPage" className={css.infoButton}>
            <FormattedMessage id="Footer.toJoinUsPage" />
          </NamedLink>
        </div>

        <div className={css.infoContent}>
          <img src={help} alt="Signalise help" className={css.infoHand} />
          <h1 className={css.infoChoice}>
            <FormattedMessage id="SectionInfo.choice3" />
          </h1>
          <NamedLink name="HelpPage" className={css.infoButton}>
            <FormattedMessage id="Footer.toHelpPage" />
          </NamedLink>
        </div>
      </div>
    </div>
  );
};

SectionInfo.defaultProps = { rootClassName: null, className: null };

SectionInfo.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionInfo;
