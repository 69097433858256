import React, { Component } from 'react';
import moment from 'moment';
import { array, arrayOf, bool, func, number, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  //txIsPaymentExpired,
  //txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
  TRANSITION_OFFER_ACCEPTED,
  TRANSITION_EXPIRE,
  TRANSITION_CUSTOMER_CANCEL,
  TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPT,
  TRANSITION_DECLINE,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  AvatarLarge,
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  //HEADING_PAYMENT_PENDING,
  //HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
} from './PanelHeading';

import hand from './helping-hand-wink-transparent.png';
import css from './TransactionPanel.module.css';
import { SecondaryButton } from '../Button/Button';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { types as sdkTypes } from '../../util/sdkLoader';
import LineItemsDisplay from './LineItemsDisplay';
const { Money } = sdkTypes;

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      accepted: false,
      hideCustomerCancel: true,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent);
    {
      this.setState({ isReviewModalOpen: false, reviewSubmitted: true });
    }
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onFetchTimeSlots,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      monthlyTimeSlots,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      onHandleAccept,
      handleReverseMarketplaceRequestInProgress,
      reverseMarketplaceRequestInProgressError,
      handleCustomCancel,
      customerCancelInProgress,
      customerCancelError,
      history,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';
    console.log(currentTransaction);

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    // get the booking start date and time to add to header
    const bookingTransactionSummary =
      currentTransaction && currentTransaction.attributes && currentTransaction.attributes.metadata;
    const bookingStart = bookingTransactionSummary && bookingTransactionSummary.requirementStart;
    const bookingEnd = bookingTransactionSummary && bookingTransactionSummary.requirementEnd;
    const bookingDateAndStartTime = moment(bookingStart).format('Do MMMM YYYY, h:mm a');
    const bookingDateAndEndTime = moment(bookingEnd).format('Do MMMM YYYY, h:mm a');
    const lineItemsArePresent = currentTransaction?.attributes?.lineItems?.length > 0;

    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
              return transition.attributes.name;
            })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: isCustomer && !isProviderBanned && hasCorrectNextTransition,
        };
        /*
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        }; */
        /* TODO } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };*/
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => onAcceptSale(currentTransaction.id)}
        onDeclineSale={() => onDeclineSale(currentTransaction.id)}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);

    const { metadata } = currentTransaction.attributes;

    const isRequirementType = metadata?.requirementStart && metadata?.requirementEnd;
    const metadataContains = name => !!currentTransaction?.attributes?.metadata?.[name];
    const transitionsContain = t =>
      !!currentTransaction?.attributes?.transitions?.find(c => c.transition === t);

    const locked = currentTransaction?.listing?.attributes?.metadata?.locked;
    const required = currentTransaction?.listing?.attributes?.publicData?.interpretersNeeded;

    const cancelButton = (
      <SecondaryButton
        onClick={e => {
          e.preventDefault();
          handleCustomCancel(currentTransaction)
            .then(res => {
              this.setState({ hideCustomerCancel: true });
            })
            .catch(e => {
              this.setState({ hideCustomerCancel: false });
            });
        }}
        inProgress={customerCancelInProgress}
      >
        Cancel
      </SecondaryButton>
    );

    const hideCancelButtonTransitions = [
      TRANSITION_EXPIRE,
      TRANSITION_CUSTOMER_CANCEL,
      TRANSITION_DECLINE,
    ];
    const customerCancelButtonShowMaybe = !currentTransaction.attributes.transitions.some(t =>
      hideCancelButtonTransitions.includes(t.transition)
    );

    // const totalLineItem = metadata?.lineItems?.reduce(
    //   (acc, i) => {
    //     if (i.percentage) {
    //       const totalAmount = (metadata?.price * i.percentage) / 100;
    //       // console.log({ totalAmount });
    //       acc = { ...acc, amount: acc.amount + totalAmount };
    //     }
    //     if (i.amount) {
    //       // console.log({ price: i.amount });
    //       acc = { ...acc, amount: acc.amount + i.amount };
    //     }
    //     return acc;
    //   },
    //   { code: 'line-item/total', amount: 0, currency: 'GBP' }
    // );

    const customerBreakdownDetailsMaybe = isCustomer &&
      metadata?.relatedTo &&
      metadata?.lineItems &&
      metadata?.price &&
      metadata?.currency && (
        <p style={{ padding: '0 2.5rem' }}>
          {[
            ...metadata?.lineItems,
            // , totalLineItem
          ]?.map(l => (
            <div key={l.code} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>
                {l.code
                  ?.replace('line-item/', '')
                  ?.split('-')
                  ?.map(p => p.charAt(0).toUpperCase() + p.slice(1))
                  ?.join(' ')}
              </span>
              {l.amount && <span>{formatMoney(intl, new Money(l.amount, l.currency))}</span>}
              {l.percentage && (
                <span>
                  {formatMoney(
                    intl,
                    new Money((metadata.price * l.percentage) / 100, metadata.currency)
                  )}
                </span>
              )}
            </div>
          ))}
        </p>
      );

    const acceptButton = (
      <>
        {/* {isProvider &&
          !metadata?.relatedTo &&
          metadata?.lineItems &&
          metadata?.price &&
          metadata?.currency && (
            <p style={{ padding: '0 2.5rem' }}>
              {[
                ...metadata?.lineItems,
                //  totalLineItem
              ]?.map(l => (
                <div key={l.code} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>
                    {l.code
                      ?.replace('line-item/', '')
                      ?.split('-')
                      ?.map(p => p.charAt(0).toUpperCase() + p.slice(1))
                      ?.join(' ')}
                  </span>
                  {l.amount && <span>{formatMoney(intl, new Money(l.amount, l.currency))}</span>}
                  {l.percentage && (
                    <span>
                      {formatMoney(
                        intl,
                        new Money((metadata.price * l.percentage) / 100, metadata.currency)
                      )}
                    </span>
                  )}
                </div>
              ))}
            </p>
          )} */}

        {transitionsContain(TRANSITION_OFFER_ACCEPTED) || this.state.accepted ? null : locked ===
          required ? null : (
          <SecondaryButton
            onClick={e => {
              e.preventDefault();
              onHandleAccept(currentTransaction)
                .then(newTransactionId => {
                  this.setState({ accepted: true });
                  return history.push(`/order/${newTransactionId}/details`);
                })
                .catch(e => this.setState({ accepted: false }));
            }}
            inProgress={handleReverseMarketplaceRequestInProgress}
          >
            Accept
          </SecondaryButton>
        )}
      </>
    );

    const providerBreakdownDetailsMaybe = isProvider &&
      metadata.relatedTo &&
      metadata.price &&
      metadata.currency && (
        <h3 style={{ padding: '0 2.5rem' }}>
          Price: {formatMoney(intl, new Money(metadata.price, metadata.currency))}
        </h3>
      );

    const cancelButtonMaybe = (
      <>
        {/* {providerBreakdownDetailsMaybe} */}
        {/* {customerBreakdownDetailsMaybe} */}

        {currentTransaction.attributes.processName == 'preauth-unit-time-booking' &&
          isCustomer &&
          customerCancelButtonShowMaybe &&
          !transitionsContain('transition/accept') &&
          metadataContains('relatedTo') &&
          !this.state.hideCustomerCancel && (
            <div style={{ padding: '0 1rem', paddingBottom: '1rem' }}>
              {customerCancelError && <small style={{ color: 'red' }}>{customerCancelError}</small>}
              {cancelButton}
            </div>
          )}
      </>
    );

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              provider={currentProvider}
              isCustomer={isCustomer}
              listingId={currentListing.id && currentListing.id.uuid}
              listingDeleted={listingDeleted}
            />
            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null}

            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
            />
            {isRequirementType && (
              <div className={css.transactionInfoMessage}>
                {/* display booking date and time */}
                <p>{`Booking starts: ${bookingDateAndStartTime}`}</p>
                <p>{`Booking ends: ${bookingDateAndEndTime}`}</p>
              </div>
            )}

            <div className={css.bookingDetailsMobile}>
              <AddressLinkMaybe
                rootClassName={css.addressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />

              {lineItemsArePresent && (
                <BreakdownMaybe
                  transaction={currentTransaction}
                  transactionRole={transactionRole}
                  hideExtraInfo={metadata?.relatedTo}
                />
              )}
            </div>

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}
            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
            />
            {showSendMessageForm ? (
              <SendMessageForm
                formId={this.sendMessageFormName}
                rootClassName={css.sendMessageForm}
                messagePlaceholder={sendMessagePlaceholder}
                inProgress={sendMessageInProgress}
                sendMessageError={sendMessageError}
                onFocus={this.onSendMessageFormFocus}
                onBlur={this.onSendMessageFormBlur}
                onSubmit={this.onMessageSubmit}
              />
            ) : (
              <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
            )}

            {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}

            <div className={css.cancellationAlert}>
              <img src={hand} alt="Signalise hand winking" className={css.hand}></img>
              <p>
                If you need to cancel a booking, please email us via{' '}
                <a href="mailto:bookings@signalise.coop">bookings@signalise.coop</a>. <br />
                <strong>Note: </strong>Customers may incur a charge for cancelling a booking that
                has already been booked as per our{' '}
                <NamedLink name="TermsOfServicePage">Terms of Service</NamedLink> which are industry
                standard.
              </p>
            </div>
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
                listingId={currentListing.id && currentListing.id.uuid}
                listingDeleted={listingDeleted}
              />
              <DetailCardHeadingsMaybe
                showDetailCardHeadings={stateData.showDetailCardHeadings}
                listingTitle={listingTitle}
                subTitle={bookingSubTitle}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
                showSubtitle={!(isRequirementType && metadataContains('relatedTo'))}
              />
              <LineItemsDisplay
                metadata={metadata}
                isProvider={isProvider}
                isCustomer={isCustomer}
                isRequirementType={isRequirementType}
              />
              {stateData.showBookingPanel ? (
                isRequirementType ? null : (
                  <BookingPanel
                    className={css.bookingPanel}
                    titleClassName={css.bookingTitle}
                    isOwnListing={false}
                    listing={currentListing}
                    title={listingTitle}
                    subTitle={bookingSubTitle}
                    authorDisplayName={authorDisplayName}
                    onSubmit={onSubmitBookingRequest}
                    onManageDisableScrolling={onManageDisableScrolling}
                    monthlyTimeSlots={monthlyTimeSlots}
                    onFetchTimeSlots={onFetchTimeSlots}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                  />
                )
              ) : null}
              {isRequirementType && isProvider && !metadataContains('relatedTo') && (
                <div style={{ padding: '1rem' }}>
                  {reverseMarketplaceRequestInProgressError && (
                    <small style={{ color: 'red' }}>
                      {reverseMarketplaceRequestInProgressError}
                    </small>
                  )}
                  {acceptButton}
                </div>
              )}
              {isRequirementType && (
                <div style={{ padding: '1rem' }}>
                  <p>
                    <FormattedMessage id="TransactionPanel.bookingConfirmationMessage" />
                  </p>
                </div>
              )}
              {cancelButtonMaybe}

              {lineItemsArePresent && (
                <BreakdownMaybe
                  className={css.breakdownContainer}
                  transaction={currentTransaction}
                  transactionRole={transactionRole}
                  hideExtraInfo={metadata?.relatedTo}
                />
              )}
              {stateData.showSaleButtons ? (
                <>
                  <div
                    className={css.desktopActionButtons}
                    style={
                      isRequirementType && isProvider && metadataContains('relatedTo')
                        ? { marginTop: '1rem' }
                        : { boxSizing: 'border-box' }
                    }
                  >
                    {saleButtons}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  monthlyTimeSlots: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  monthlyTimeSlots: object,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = compose(withRouter, injectIntl)(TransactionPanelComponent);

export default TransactionPanel;
