import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  PrimaryButton,
} from '../../components';
import css from './FeedbackForm.module.css';
import ReCAPTCHA from "react-google-recaptcha";

// The SuccessMessage replaces the form if the API succesfully returns
const SuccessMessage = () => (
  <p className={css.successMessage}>
    Thank you for your message.<br></br>
    One of the team will get back to you as soon as possible.
  </p>
);

// The ErrorMessage is displayed at the top of the form if API returns error
const ErrorMessage = () => (
  <p className={css.errorMessage}>Sorry, we could not process your message right now.</p>
);

class FeedbackForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValue: '',
      emailError: '',
      feedbackValue: '',
      feedbackError: '',
      nameValue: '',
      nameError: '',
      caseStatus: null,
      casePriority: null,
      submitError: false,
      isLoading: false,
      isSubmitted: props.submitted,
    };

  }

  render() {

    if (this.state.isSubmitted) {
      return (
        <div role="alert" aria-live="polite" aria-atomic="true">
          <SuccessMessage />
        </div>
      );
    } else {
      return (
        <form id="__vtigerWebForm_3" name="Contact form 3"
              action="https://signaliseco-op1.od2.vtiger.com/modules/Webforms/capture.php"
              method="post" acceptCharset="utf-8" encType="multipart/form-data">
          <input type="hidden" name="publicid" value="498d2f533ea5794ba1f033d56d09f68f"/>
          <input type="hidden" name="urlencodeenable" value="1"/>
          <input type="hidden" name="name" value="Contact form 3"/>
          <input type="hidden" name="__vtCurrency" value="1"/>

          <label htmlFor="firstname">First Name</label>
          <input id="firstname" name="firstname" defaultValue={this.props.userFirstName}/>

          <label htmlFor="lastname">Last Name *</label>
          <input id="lastname" name="lastname" defaultValue={this.props.userLastName}/>

          <label htmlFor="email">Email</label>
          <input id="email" name="email" defaultValue={this.props.userEmail}/>

          <input type="hidden" name="contacttype" value="Customer"/>
          <input type="hidden" name="emailoptin" data-label="" value="0"/>

          <label htmlFor="emailoptin">Email Opt In</label>
          <input type="checkbox" value="1"
                 name="emailoptin" id="emailoptin"/>

          <label htmlFor="Cases_%23%23_description">Summary *</label>
          <textarea name="Cases_%23%23_description" id="Cases_%23%23_description"></textarea>
          <input type="hidden" name="Cases_##_title" value="Web enquiry form"/>
          <input type="hidden" name="Cases_##_casestatus" value="New"/>
          <input type="hidden" name="Cases_##_casepriority" value="High"/>
          <input type="hidden" name="Cases_%23%23_contact_id" value=""/>

          <ReCAPTCHA
            sitekey="6LcmdSATAAAAAGWw734vGo0AXQwuxJS7RmDZA_Fe"
          />
          <PrimaryButton
            type="submit"
          >
            <FormattedMessage id="FeedbackForm.sendFeedback" />
          </PrimaryButton>
        </form>
      );
    }
  }
}

FeedbackForm.defaultProps = {
  feedbackSubject: 'Platform Contact Form',
  defaultUserName: 'Platform user',
  userFirstName: null,
  userLastName: null,
  userId: null,
  userEmail: null,
  submitted: false,
};

FeedbackForm.propTypes = {
  subdomain: PropTypes.string,
  feedbackSubject: PropTypes.string,
  defaultUserName: PropTypes.string,
  currentPageURL: PropTypes.string,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  userId: PropTypes.string,
  userEmail: PropTypes.string,
  submitted: PropTypes.bool,
};

export default FeedbackForm;
