import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing, ensureCurrentUser } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingLocationForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';
const { LatLng } = sdkTypes;
import moment from 'moment';
import data from '../../data/addresses.json';
import departments from '../../data/signalise.json';
import css from './EditListingLocationPanel.module.css';

class EditListingLocationPanel extends Component {
  constructor(props) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);
    this.getLocations = this.getLocations.bind(this);
    this.getDepartments = this.getDepartments.bind(this);

    this.state = {
      initialValues: this.getInitialValues(),
      locations: this.getLocations(),
      departments: this.getDepartments(),
    };
  }

  getInitialValues() {
    const { currentUser, listing } = this.props;
    const currentListing = ensureOwnListing(listing);
    const user = ensureCurrentUser(currentUser);
    const locationName = user?.attributes?.profile?.privateData?.addressDepartment;
    const { geolocation, publicData, privateData } = currentListing.attributes;
    const category = publicData?.listingtype;
    const listingCheck = category === 'requirement';

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places

    const locationFieldsPresent =
      (privateData?.location?.address && privateData?.origin) || privateData?.invoicingAddress;
    // && geolocation;
    const location = privateData?.location ?? privateData?.invoicingAddress ?? {};
    const { address, building } = location;
    const proxyLocation = publicData && publicData.location ? publicData.location : {};

    const { address: proxyAddress } = proxyLocation;
    let initVal = {
      building,
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: {
              address,
              origin: new LatLng(
                privateData.origin
                  ? (privateData.origin[0], privateData?.origin[1])
                  : (privateData.invoicingOrigin[0], privateData?.invoicingOrigin[1])
              ),
            },
            proxySearch: proxyAddress,
            proxySelectedPlace: {
              address: proxyAddress,
              origin: geolocation,
            },
          }
        : null,
    };
    if (listingCheck) {
      initVal.locationName = locationName;
      initVal.personOrRemote = publicData.personOrRemote;
      initVal.isRecorded = publicData.isRecorded;
      initVal.requirementStart = publicData.requirementStart;
      initVal.requirementEnd = publicData.requirementEnd;
      initVal.bookingDepartment = publicData.bookingDepartment;
      initVal.locationShort = publicData.locationShort;
      initVal.remoteDetails = publicData.remoteDetails;
      (initVal.subLocation = publicData.subLocationCustom
        ? {
            key: `I can't find my subdepartment`,
            value: `I can't find my subdepartment`,
            label: `I can't find my subdepartment`,
          }
        : publicData.subLocation),
        (initVal.subLocationName = publicData.subLocationCustom
          ? publicData.subLocation.value
          : null),
        (initVal.contact = privateData.contact);
    }

    return initVal;
  }
  getLocations() {
    const { currentUser, listing } = this.props;
    const currentListing = ensureOwnListing(listing);
    const { publicData } = currentListing.attributes;
    const category = publicData?.listingtype;
    const listingCheck = category === 'requirement';
    if (!listingCheck) return null;
    const user = ensureCurrentUser(currentUser);
    const userTypes = user?.attributes?.profile?.privateData?.userTypes;
    if (!userTypes.includes('contractCustomer')) return null;
    const found = userTypes.find(a => {
      return Object.keys(data).includes(a);
    });
    const locs = data[found];
    if (locs && locs[0].name != "I can't find the location") {
      locs.unshift({
        name: "I can't find the location",
        address: 'address',
      });
    }
    return locs || null;
  }

  getDepartments() {
    const { currentUser, listing } = this.props;
    const currentListing = ensureOwnListing(listing);
    const { publicData } = currentListing.attributes;
    const category = publicData?.listingtype;
    const listingCheck = category === 'requirement';
    if (!listingCheck) return null;
    const user = ensureCurrentUser(currentUser);
    const userTypes = user?.attributes?.profile?.privateData?.userTypes;
    if (!userTypes.includes('contractCustomer')) return null;
    const placeCCG = {
      liverpoolCCG: 'liverpoolPlace',
      knowsleyCCG: 'knowsleyPlace',
      seftonSFCCG: 'seftonPlace',
    };
    const userFix = userTypes.map(a => {
      if (!Object.keys(placeCCG).includes(a)) return a;
      else return Object.values(placeCCG)[Object.keys(placeCCG).indexOf(a)];
    });
    const codeDepartments = departments.filter(a => {
      return userFix.includes(a.contractCode);
    });
    return (
      codeDepartments.map(item => {
        return {
          key: item.code ? item.code.trim() : null,
          label: item.code ? `${item.code.trim()} - ${item.department.trim()}` : null,
          email: item.email ? item.email.trim() : null,
          department: item.department ? item.department.trim() : null,
          address: item.address ? item.address : null,
          contractCode: item.contractCode ? item.contractCode : null,
        };
      }) || null
    );
  }
  componentDidMount() {
    this.getLocations();
    this.getDepartments();
  }
  render() {
    const {
      className,
      rootClassName,
      listing,
      currentUser,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
    } = this.props;

    const user = ensureCurrentUser(currentUser);
    const userTypes = user?.attributes?.profile?.privateData?.userTypes;
    if (userTypes.includes(''));
    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const category = currentListing.attributes.publicData?.listingtype;
    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingLocationPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              <FormattedMessage id="EditListingLocationPanel.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditListingLocationPanel.createListingTitle" />
    );

    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <EditListingLocationForm
          className={css.form}
          category={category}
          userTypes={userTypes}
          initialValues={this.state.initialValues}
          locations={this.state.locations}
          departments={this.state.departments}
          onSubmit={values => {
            let {
              building,
              contact,
              location,
              locationShort,
              subLocation,
              subLocationName,
              requirementStart,
              requirementEnd,
              personOrRemote,
              bookingDepartment,
              locationName,
              isRecorded,
              remoteDetails,
            } = values;
            let updatedValues;
            if (locationShort && locationShort.value != 'address') {
              location = this.state.locations.find(a => a.address == locationShort.value).data;
            }
            if (category !== 'requirement') {
              const {
                selectedPlace: { address, origin },
                proxySelectedPlace: { address: proxyAddress, origin: proxyOrigin },
              } = location;
              updatedValues = {
                geolocation: proxyOrigin,
                publicData: {
                  location: { address: proxyAddress, building: '' },
                },
                privateData: {
                  location: { address, building },
                  origin: [origin.lat, origin.lng],
                },
              };
              this.setState({
                initialValues: {
                  building,
                  location: {
                    search: address,
                    selectedPlace: { address, origin },
                    proxySearch: proxyAddress,
                    proxySelectedPlace: { address: proxyAddress, origin: proxyOrigin },
                  },
                },
              });
            } else if (personOrRemote === 'In person') {
              const {
                selectedPlace: { address, origin },
                proxySelectedPlace: { address: proxyAddress, origin: proxyOrigin },
              } = location;
              // extract the postcode from the address and convert to string
              const postcodeRegex = /[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}/g;
              const getPostcode = JSON.stringify(address.match(postcodeRegex));
              //format postcode - remove brackets and quotation marks
              const removeBrackets = getPostcode.replace(/[\[\]']+/g, '');

              const postcodeFormatted = removeBrackets.replace(/['"]+/g, '');

              // use only the first part of the postcode string
              const firstPartPostcode = postcodeFormatted.match(/^([\S]+)/)[0];

              requirementStart = moment(
                Array.isArray(requirementStart) ? requirementStart[0] : requirementStart
              ).valueOf();
              requirementEnd = moment(
                Array.isArray(requirementEnd) ? requirementEnd[0] : requirementEnd
              ).valueOf();
              updatedValues = {
                geolocation: proxyOrigin,
                publicData: {
                  requirementStart,
                  requirementEnd,
                  readableRequirementStart: requirementStart
                    ? moment(requirementStart).format('DD/MM/YYYY HH:mm')
                    : null,
                  readableRequirementEnd: requirementEnd
                    ? moment(requirementEnd).format('DD/MM/YYYY HH:mm')
                    : null,
                  location: { address: proxyAddress, building },
                  firstPartPostcode,
                  personOrRemote,
                  bookingDepartment,
                  locationShort,
                  subLocation: subLocationName
                    ? { key: subLocationName, value: subLocationName, label: subLocationName }
                    : subLocation ?? null,
                  subLocationCustom: subLocationName ? true : false,
                  locationName,
                },
                privateData: {
                  location: { address, building },
                  origin: [origin.lat, origin.lng],
                },
              };
              if (contact) updatedValues.privateData.contact = contact;
              this.setState({
                initialValues: {
                  building,
                  locationShort,
                  subLocation: subLocationName
                    ? { key: subLocationName, value: subLocationName, label: subLocationName }
                    : subLocation ?? null,
                  subLocationCustom: subLocationName ? true : false,
                  locationName,
                  location: {
                    search: address,
                    selectedPlace: { address, origin },
                    proxySearch: proxyAddress,
                    proxySelectedPlace: { address: proxyAddress, origin: proxyOrigin },
                  },
                  requirementStart,
                  requirementEnd,
                  personOrRemote,
                  bookingDepartment,
                  contact,
                },
              });
            } else {
              requirementStart = moment(
                Array.isArray(requirementStart) ? requirementStart[0] : requirementStart
              ).valueOf();
              requirementEnd = moment(
                Array.isArray(requirementEnd) ? requirementEnd[0] : requirementEnd
              ).valueOf();
              updatedValues = {
                publicData: {
                  requirementStart,
                  requirementEnd,
                  readableRequirementStart: requirementStart
                    ? moment(requirementStart).format('DD/MM/YYYY HH:mm')
                    : null,
                  readableRequirementEnd: requirementEnd
                    ? moment(requirementEnd).format('DD/MM/YYYY HH:mm')
                    : null,
                  personOrRemote,
                  isRecorded,
                  remoteDetails,
                },
              };
              this.setState({
                initialValues: {
                  requirementStart,
                  requirementEnd,
                  personOrRemote,
                  isRecorded,
                  remoteDetails,
                },
              });
            }
            onSubmit(updatedValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
        />
      </div>
    );
  }
}

const { func, object, string, bool } = PropTypes;

EditListingLocationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingLocationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingLocationPanel;
