/**
 * POST feedback data to Zendesk
 * https://developer.zendesk.com/rest_api/docs/core/requests#create-request
 *
 * @param {Object} data      - Feedback data payload
 * @param {String} subdomain - Subdomain of zendesk account eg https://${subdomain}.zendesk.com
 *
 * @return {Object}          - Response from Zendesk
 *
 */
export const sendFeedback = async data => {
  try {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      type: 'formData',
    };
    const response = await fetch(
      `https://signaliseco-op1.od2.vtiger.com/modules/Webforms/capture.php`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers,
      }
    );
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    return error;
  }
};

/**
 * Format a payload of form data to the format required by Zendesk
 *
 * @param {String} feedbackSubject  - Zendesk ticket subject
 *
 * @param {String} feedback - Feedback provided by user
 * @param {String} pageURL  - URL of page user submitted feedback from
 * @param {String} name     - Name provided by user or profile name
 * @param {String} userFirstName     - First name in user profile
 * @param {String} userLastName     - Last name in user profile
 * @param {String} userId    - userId from the system
 * @param {String} userEmail - userEmail from the system
 *
 * @return {Object}         - Payload for Zendesk Requests API
 *
 */

//@param {String} email    - Email provided by user giving feedback

export const formatPayload = (
  feedbackSubject,
  email,
  defaultName,
  feedback,
  pageURL,
  name,
  userFirstName,
  userLastName,
  userId,
  userEmail,
  casePriority
) => {
  const requester = email ? { name: defaultName, email } : { name: defaultName };

  const comment = pageURL
    ? {
        body: `Anonymous user data: 
          Name: ${name}
          Email: ${email}
          
          Logged in user data:
          User's System Name: ${userFirstName} ${userLastName}
          User's System Email: ${userEmail}
          User's ID: ${userId}

          Message:
          Message: ${feedback}
          
          Location:
          URL: ${pageURL}`,
      }
    : { body: feedback };

  return {
    request: {
      requester,
      feedbackSubject,
      comment,
    },
  };
};
