import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  LocationAutocompleteInputField,
  FieldReactSelect,
} from '../../components';

import css from './AddressDetailsForm.module.css';
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
} from '../../util/validators';

const identity = v => v;

class AddressDetailsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.submittedValues = {};
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            currentUser,
            disabled,
            formId,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            saveAddressDetailsError,
            updateInProgress,
            values,
            types,
            signaliseLocations,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          const optionalText = intl.formatMessage({
            id: 'EditListingLocationForm.optionalText',
          });

          const jobTitleLabel = intl.formatMessage(
            { id: 'AddressDetailsForm.jobTitleLabel' },
            { optionalText: optionalText }
          );

          const jobTitlePlaceholder = intl.formatMessage({
            id: 'AddressDetailsForm.jobTitlePlaceholder',
          });

          const addressDepartmentLabel = intl.formatMessage(
            { id: 'AddressDetailsForm.addressDepartmentLabel' },
            { optionalText: optionalText }
          );

          const addressDepartmentPlaceholder = intl.formatMessage({
            id: 'AddressDetailsForm.addressDepartmentPlaceholder',
          });

          const addressLineOneLabel = intl.formatMessage({
            id: 'AddressDetailsForm.addressLineOneLabel',
          });

          const addressLineOnePlaceholder = intl.formatMessage({
            id: 'AddressDetailsForm.addressLineOnePlaceholder',
          });

          const addressLineTwoLabel = intl.formatMessage(
            { id: 'AddressDetailsForm.addressLineTwoLabel' },
            { optionalText: optionalText }
          );

          const addressLineTwoPlaceholder = intl.formatMessage({
            id: 'AddressDetailsForm.addressLineTwoPlaceholder',
          });

          const addressCityLabel = intl.formatMessage({
            id: 'AddressDetailsForm.addressCityLabel',
          });

          const addressCityPlaceholder = intl.formatMessage({
            id: 'AddressDetailsForm.addressCityPlaceholder',
          });

          const postalCodeLabel = intl.formatMessage({
            id: 'AddressDetailsForm.postalCodeLabel',
          });

          const postalCodePlaceholder = intl.formatMessage({
            id: 'AddressDetailsForm.postalCodePlaceholder',
          });

          const addressPlaceholderMessage = intl.formatMessage({
            id: 'AddressDetailsForm.addressFieldPlaceholder',
          });
          const addressRequiredMessage = intl.formatMessage({
            id: 'AddressDetailsForm.addressRequired',
          });
          const addressNotRecognisedMessage = intl.formatMessage({
            id: 'AddressDetailsForm.addressNotRecognised',
          });
          const addressFieldLabel = intl.formatMessage({
            id: 'AddressDetailsForm.addressFieldLabel',
          });

          //TODO
          let genericError = null;

          if (saveAddressDetailsError) {
            genericError = (
              <span className={css.error}>
                <FormattedMessage id="AddressDetailsForm.genericFailure" />
              </span>
            );
          }

          const classes = classNames(rootClassName || css.root, className);
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.contactDetailsSection}>
                <FieldTextInput
                  className={css.features}
                  name="jobTitle"
                  id={formId ? `${formId}.jobTitle` : 'jobTitle'}
                  type="text"
                  label={jobTitleLabel}
                  placeholder={jobTitlePlaceholder}
                />

                <FieldTextInput
                  className={css.features}
                  name="addressDepartment"
                  id={formId ? `${formId}.addressDepartment` : 'addressDepartment'}
                  type="text"
                  label={addressDepartmentLabel}
                  placeholder={addressDepartmentPlaceholder}
                />

                <div style={{ marginBottom: '1rem' }}>
                  <LocationAutocompleteInputField
                    name="location"
                    label={addressFieldLabel}
                    placeholder={addressPlaceholderMessage}
                    id={formId ? `${formId}.addressLineOne` : 'addressLineOne'}
                    useDefaultPredictions={false}
                    format={identity}
                    valueFromForm={values.location}
                    isTopBarSearchForm={true}
                    validate={composeValidators(
                      autocompleteSearchRequired(addressRequiredMessage),
                      autocompletePlaceSelected(addressNotRecognisedMessage)
                    )}
                  />
                </div>
                {/*}
                <FieldTextInput
                  className={css.features}
                  name="addressLineTwo"
                  id={formId ? `${formId}.addressLineTwo` : 'addressLineTwo'}
                  type="text"
                  label={addressLineTwoLabel}
                  placeholder={addressLineTwoPlaceholder}
                />

                <FieldTextInput
                  className={css.features}
                  name="addressCity"
                  id={formId ? `${formId}.addressCity` : 'addressCity'}
                  type="text"
                  label={addressCityLabel}
                  placeholder={addressCityPlaceholder}
                />

                <FieldTextInput
                  className={css.features}
                  name="postalCode"
                  id={formId ? `${formId}.postalCode` : 'postalCode'}
                  type="text"
                  label={postalCodeLabel}
                  placeholder={postalCodePlaceholder}
                />
                    */}
              </div>

              <div className={css.bottomWrapper}>
                {genericError}
                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={pristineSinceLastSubmit}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="AddressDetailsForm.saveChanges" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

AddressDetailsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  saveAddressDetailsError: null,
  inProgress: false,
  jobTitle: null,
  addressLocation: null,
  addressBuilding: null,
  invoiceName: null,
  selectedPlace: null,
  fetchErrors: null,
};

const { bool, string } = PropTypes;

AddressDetailsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  saveAddressDetailsError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
};

const AddressDetailsForm = compose(injectIntl)(AddressDetailsFormComponent);

AddressDetailsForm.displayName = 'AddressDetailsForm';

export default AddressDetailsForm;
