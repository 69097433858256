import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import hoppingHand from './hoppingHand.png';
import css from './InvestorJoinContent.module.css';

const InvestorJoinContent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>

      <h2>Our third category is Investor Members.</h2>
        
      <p>We opened a Community Share Offer in March 2021 and this closed in June 2021.
      We raised a total of £328k which is now helping to fund the growth of the infrastructure of the business and investment in technology.</p>

      <p>Anyone who bought shares was admitted into membership of the co-op, if they were not already a member!
</p>

      <p>We welcomed a total of 194 investor members into the co-op and we’ve seen this as a unique opportunity to increase community ownership of a business seeking to radically change how interpreting services are run, owned and delivered. </p>

      <p>We recognise that investor members can offer value to the operation and development 
        of the business and welcome this opportunity to increase our growth.</p>

        <img src={ hoppingHand } alt="Hopping Signalise hand" className={ css.hoppingHand }/>

      <h2>Benefits of Investor membership:</h2>
      
      <ul className={css.listContainer}>
        <li>Ownership of a local community interpreting business</li>
        <li>Being able to feedback directly and change how the business works</li>
        <li>Access to training, workshops, support and meetings</li>
        <li>Support and invest in an ethical, local, community-run business</li>
        <li>Be a part of supporting the new regenerative economy</li>
        <li>Opportunity to earn interest in future</li>
      </ul>
      
      <p>We do not currently have plans to launch another community share offer but if you would like to
         become a member or would like to know more please get in touch at 
        <a href="mailto:hello@signalise.coop"> hello@signalise.coop</a>.</p>

      <p>You can find <a href="https://communityshares.org.uk/find-out-more/guidance-supporters">more information about Community Shares here</a>.</p>

    </div>
  );
};

InvestorJoinContent.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

InvestorJoinContent.propTypes = {
  rootClassName: string,
  className: string,
};

export default InvestorJoinContent;
