import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '..';

import teenager from './teenager.jpeg';
import customerservice from './customerservice.jpeg';
import laptopwoman from './laptopwoman.jpeg';
import runningLeftHand from './runningLeftHand.png';

import css from './VRSSalesContent.module.css';

const VRSSalesContent = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={`${classes} ${css.staticPageWrapper}`}>
      <div className={css.contentWrapper}>
        <div className={css.intro}>
          <h1 className={css.pageTitle}>Our Video Interpreting Service</h1>
        </div>

        <section className={css.contentSection}>
          <p>
            Our on demand video interpreting service is the only service to be owned and developed
            by the community.
          </p>
          <p>
            Signalise is run and owned by Deaf users and Interpreters and other communication
            professionals. As a cooperative, we support the community directly and our aim is to
            increase access for users to all areas of life.
          </p>
          <img
            src={runningLeftHand}
            alt="Signalise Hand running left"
            className={css.runningLeftHand}
          />
          <section id="whyUs" className={css.sectionContent}>
            <h2>Why Use Us?</h2>
            <ul>
              <li>
                <strong>NEW: </strong> Join the link and see all 3 people on the call
              </li>
              <li>Only service in the UK owned by Deaf people and interpreters</li>
              <li>Highly qualified interpreters who are fully trained in video interpreting</li>
              <li>
                Profits are put back into the business to increase access for Deaf users and raise
                standards of interpreting
              </li>
              <li>
                Local and national - we balance our use of professionals to support local economies
              </li>
              <li>
                Support our high social impact model and a business which is owned and run by the
                community
              </li>
            </ul>
          </section>
          <br />
          <img
            src={teenager}
            alt="Teenager signing during e-learning session"
            className={css.image}
          />
          <section className={css.sectionContent}>
            <h2 id="deafUsers">Deaf users:</h2>
            <p>
              Use us to support the Deaf community. Our service is owned by Deaf people, it
              increases access for Deaf users to phone calls, NHS, events and we want to grow our
              business to support more Deaf people around the UK.
            </p>
            <p>The video service can be paid for with:</p>
            <ul>
              <li>Access to Work budgets</li>
              <li>Personal direct budgets via social services</li>
              <li>Paying yourself</li>
            </ul>
            <a href="#whyUs">Why use us?</a>
            <br />

            <NamedLink name="ContactPage" className={css.enquiryButton}>
              Make an enquiry
            </NamedLink>
          </section>
          <img
            src={laptopwoman}
            alt="A woman communicates using sign language while on a video call"
            className={css.image}
          />
          <section>
            <h2 id="Organisations">Organisations:</h2>
            <p>
              We have enabled organisations such as NHS trusts to become accessible to Deaf users by
              allowing users to access calls via our Video Directory so users can call you direct
              via our on-demand interpreting service. Our video service can help you:
            </p>
            <ul>
              <li>
                Communicate with Deaf customers (150k in the UK who use sign language as their
                preferred language)
              </li>
              <li>Meet your Corporate and Social Responsibility requirements</li>
              <li>Support your PR and marketing</li>
            </ul>
            <p>
              <a href="#whyUs">Why use us?</a>
              <br />
            </p>

            <NamedLink name="ContactPage" className={css.enquiryButton}>
              Make an enquiry
            </NamedLink>
          </section>
          <img
            src={customerservice}
            alt="Staff in customer service department"
            className={css.image}
          />
        </section>
      </div>
    </div>
  );
};

VRSSalesContent.defaultProps = { rootClassName: null, className: null };

VRSSalesContent.propTypes = {
  rootClassName: string,
  className: string,
};

export default VRSSalesContent;
