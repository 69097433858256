import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconFavourites.module.css';

const IconFavourites = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.597 220.057">
      <path
        d="M68.13 12.502c-26.934.231-51.58 21.477-55.082 48.294-3.113 23.844 7.33 47.4 23.969 64.214 27.514 27.806 61.226 49.794 83.446 82.547 4.391-4.688 9.279-12.065 14.228-17.529 25.23-27.85 58.165-48.013 80.864-78.154 17.176-22.807 19.103-58.114-.538-80.405-18.25-20.713-51.76-25.171-74.37-9.254-8.225 5.79-15.274 13.37-19.932 22.312-10.053-19.32-30.534-32.214-52.585-32.025z"
        opacity={0.98}
        fill="none"
        stroke="#000"
        strokeWidth={5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconFavourites.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconFavourites.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconFavourites;
