/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const prefSexOptions = [
  { key: 'no-preference', label: 'No preference' },
  { key: 'female', label: 'Female' },
  { key: 'male', label: 'Male' },
];

export const filters = [
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/GMT',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'workDomains',
    label: 'Work Domains',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_workDomains'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'accessToWork', label: 'Access to Work' },
        { key: 'artCulture', label: 'Art & Culture' },
        { key: 'cpSocialServices', label: 'Child Protection & Social Services', qualified: true },
        { key: 'computingTechnology', label: 'Computing & Technology' },
        { key: 'conferences', label: 'Conferences' },
        { key: 'corporateFinance', label: 'Corporate & Finance' },
        { key: 'deafCommunity', label: 'Deaf Community Events' },
        { key: 'higherEducation', label: 'Higher Education' },
        { key: 'interpretersOfColour', label: 'Interpreters of Colour' },
        { key: 'inVision', label: 'In-Vision' },
        { key: 'lgbtqi', label: 'LGBTQI' },
        { key: 'medical', label: 'Medical' },
        { key: 'mentalHealth', label: 'Mental Health', qualified: true },
        { key: 'policeLegal', label: 'Police & Legal', qualified: true },
        { key: 'political', label: 'Political' },
        { key: 'religious', label: 'Religious' },
        { key: 'theatre', label: 'Theatre' },
        { key: 'vrsVri', label: 'Video Relay Services/Interpreting' },
      ],
    },
  },
  {
    id: 'registration',
    label: 'Registration',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_registration'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'registeredInterpreter', label: 'BSL / English Interpreter' },
        { key: 'traineeInterpreter', label: 'BSL / English Interpreter (Trainee)' },

        { key: 'videoInterpreter', label: 'Video Service Interpreter' },
        { key: 'lipspeaker', label: 'Lipspeaker' },
        { key: 'traineeLipspeaker', label: 'Lipspeaker (Trainee)' },
        { key: 'traineeTranslator', label: 'BSL Translator' },
        { key: 'registeredTranslator', label: 'BSL Translator (Trainee)' },
        { key: 'deafBlindInterpreter', label: 'Interpreter for Deafblind people' },
        { key: 'sttr', label: 'Speech To Text Reporter' },
        { key: 'ssphi', label: 'Specialist Support Professional - Hearing Impaired (SSP-HI)' },
        { key: 'manualNotetaker', label: 'Manual Notetaker' },
        { key: 'electronicNotetaker', label: 'Electronic Notetaker' },

        {
          key: 'drii',
          label: 'Deaf Relay-Intralingual Interpreter',
        },
        {
          key: 'traineeDRII',
          label: 'Deaf Relay-Intralingual Interpreter (Trainee)',
        },
      ],
    },
  },
  {
    id: 'bookingtype',
    label: 'Booking Type',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_personOrRemote'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'In person', label: 'In person' },
        { key: 'Remote', label: 'Remote' },
      ],
    },
  },
  // {
  //   id: 'listingtype',
  //   label: 'Type',
  //   type: 'SelectSingleFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_listingtype'],
  //   config: {
  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'requirement', label: 'Booking' },
  //       { key: 'listing', label: 'Listing' },
  //     ],
  //   },
  // },
  {
    id: 'registrationBody',
    label: 'Filter by registration body',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_registrationBody'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'nrcpd', label: 'NRCPD' },
        { key: 'rbsli', label: 'RBSLI' },
        { key: 'srlpdc', label: 'SRLPDC' },
        { key: 'iti', label: 'ITI' },
        { key: 'nadp', label: 'NADP' },
        { key: 'anp', label: 'ANP' },
        { key: 'avsttr', label: 'AVSTTR' },
        //{ key: 'traineeInterpreter', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
      ],
    },
  },
  {
    id: 'keyword',
    label: 'Name',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'myGender',
    label: 'Do you require a specific sex of interpreter for this appointment?',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_myGender'],
    config: {
      options: prefSexOptions,
    },
  },

  {
    id: 'myEthnicity',
    label: 'Do you require an interpreter of a specific ethnicity for this appointment?',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_myEthnicity'],
    config: {
      options: [
        { key: 'asian', label: 'Asian' },
        { key: 'blackafricancaribbean', label: 'Black | African | Caribbean' },
        { key: 'hispanic', label: 'Hispanic' },
        {
          key: 'othermixedmultipleethnicgroups',
          label: 'Other | Mixed | Multiple ethnic groups',
        },
        { key: 'whitecaucasion', label: 'White/Caucasian' },
      ],
    },
  },
  {
    id: 'registrationLength',
    label: 'Filter by length of registration',

    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_registration_length'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '0to3', label: '0 - 3 years' },
        { key: '4to7', label: '4 - 7 years' },
        { key: '8to10', label: '8 - 10 years' },
        { key: 'over10', label: 'over 10 years' },
      ],
    },
  },
  {
    id: 'additionalQualifications',
    label: 'Filter by additional qualifications',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_additionalQualifications'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'supervisor', label: 'Supervisor' },
        { key: 'trainer', label: 'Interpreter Trainer' },
        { key: 'assessor', label: 'Assessor' },
      ],
    },
  },
];

export const contractPincodes = [
  { key: 20145, label: 'liverpoolPlace', value: 'Liverpool Place' },
  { key: 20146, label: 'knowsleyPlace', value: 'Knowsley Place' },
  { key: 20147, label: 'seftonPlace', value: 'South Sefton Place' },
  { key: 20148, label: 'wirralCHC', value: 'Wirral Community Health and Care' },
  { key: 20149, label: 'alderHey', value: "Alder Hey Children's NHS Foundation Trust" },
  { key: 20150, label: 'luhft', value: 'Liverpool University Hospitals NHS Foundation Trust' },
  { key: 20151, label: 'lhch', value: 'Liverpool Heart and Chest Hospital' },
  { key: 20152, label: 'southportFormbyPlace', value: 'Southport and Formby Place' },
  { key: 20153, label: 'liverpoolWomens', value: 'Liverpool Womens NHS Foundation Trust ' },
  {
    key: 20154,
    label: 'bridgewaterCommunity',
    value: 'Bridgewater Community Healthcare NHS Foundation Trust ',
  },
  { key: 20155, label: 'merseyCare', value: 'Mersey Care NHS Foundation Trust ' },
  { key: 20156, label: 'waltonCentre', value: 'The Walton Centre' },
  { key: 20157, label: 'clatterbridge', value: 'The Clatterbridge Centre NHS Foundation Trust' },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
