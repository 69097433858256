import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingLegalForm } from '../../forms';

import css from './EditListingLegalPanel.module.css';

const EditListingLegalPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, privateData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingLegalPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingLegalPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingLegalPanel.createListingTitle" />
  );

  const insuranceCheck = { insuranceCheck: '' } || { insuranceCheck: 'insuranceConfirmed' };
  const eligibleUKCheck = { eligibleUKCheck: '' } || { eligibleUKCheck: 'eligibleUKConfirmed' }; 
  const agreedTermsCheck = { agreedTermsCheck: ''} || { agreedTermsCheck: 'agreedTermsConfirmed' }
  const passportUpload = { passportUpload: '' }; 
  const insuranceUpload = { insuranceUpload: '' };
  
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingLegalForm
        className={css.form}
        initialValues={{ 
          title, 
          description, 
          insuranceCheck: privateData.insuranceCheck,
          eligibleUKCheck: privateData.eligibleUKCheck,
          passportUpload: privateData.passportUpload,
          insuranceUpload: privateData.insuranceUpload,
          agreedTermsCheck: privateData.agreedTermsCheck,
        }} 
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { title, description, insuranceCheck, eligibleUKCheck, passportUpload, insuranceUpload, agreedTermsCheck } = values;
          const updateValues = {
            title: title.trim(),
            description,
            privateData: { insuranceCheck, eligibleUKCheck, passportUpload, insuranceUpload, agreedTermsCheck },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        insuranceCheck={insuranceCheck}
        eligibleUKCheck={eligibleUKCheck}
        passportUpload={passportUpload}
        insuranceUpload={insuranceUpload}
        agreedTermsCheck={agreedTermsCheck}
        currentUser={currentUser}
      />
    </div>
  );
};

EditListingLegalPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingLegalPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingLegalPanel;
