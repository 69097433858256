import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: 20th September 2021</p>
      <h2>What is this?</h2>
      <p>At Signalise Co-op we collect and use information about our members, clients, service users, and communication professionals. That information is known as ‘personal data’. We have a legal responsibility to handle that information with respect, and to tell you how and why we use your data. 
      This privacy notice explains what we do with your data, and also explains the rights you have over your data. These include amongst others, the right to ask for copies of your data, and to object to the way your data is being used. 
      If you have any questions about this privacy notice or anything related to personal data, you can contact our Business and Development Manager at <a href='mailto:jen@signalise.coop'>jen@signalise.coop</a> or on <a href="tel:01518080373">0151 808 0373</a>. </p>
      <h2>Personal data</h2>
      <p>When we say ‘personal data’ this means any information that relates to a living person who can be identified from that information. This will usually include your name, address, contact details, and other information we collect as part of our relationship with you, which includes anyone we come into contact with through our work.
      Some especially sensitive information is known as ‘special categories’ of data, and this includes information about a person’s physical or mental health, religious beliefs, sex life or sexual orientation. There are very strict legal rules around using these types of data, or information about criminal offences.
      If we collect any special categories data about you we will always be clear about why we are collecting it. We only use your personal data if we need to for a specific purpose, as explained below. Most often, we collect your personal data directly from you, through our contact with you.</p>
      <h2>Your data and how and why we process it</h2>
      <p>In general terms, we use your data so we can manage and support our relationship with you, comply with legal obligations, improve our services and achieve our legitimate business aims. The information below gives more details about our purposes for processing data, and the legal bases for our processing.</p>
      <h3>Clients:</h3>
      <p>We collect and process information including your name, contact details and payments details so that we can enter into an agreement for service provision and manage the relationship between us. We do this on the legal basis that it is necessary for contract purposes.</p>
      <h3>Service Users:</h3>
      <p>We process your name, contact details including address, mobile telephone number and email address, your GP’s contact details if you choose to provide them to help communication with your GP, and your preferred communication professionals if you have them. We will collect other information through our ongoing contact with you as we work together.</p>
      <p>If we have an agreement directly with you, we process this data about you on the legal basis of it being necessary for our contract or agreement.
      If we provide services to you through an agreement with another party, such as your GP or hospital, this is on the legal basis of the processing of your data being necessary for our legitimate interests of being able to provide and improve our communication professional services as appropriate.</p>
      <p>We collect information related to your health and any criminal information related to you or anyone closely connected to you if applicable, so we can assess the risk and identify anything that could affect the safety of our communication professionals, or the way we are able to provide you with the service.
      We have a legal responsibility to protect the safety of everyone involved in our work and have a lawful right to process data as explained, when it is for the purposes of safety and wellbeing.</p>
      <p>We do not share any health, criminal or other sensitive information outside of Signalise Co-op without your consent. The only exception would be if we need to report something to an external agency in order to protect the wellbeing of yourself or someone else.
      This includes social services referrals or contacting the emergency services if the situation required us to.</p>
      <h3>Communication Professionals:</h3>
      <p>We process your name, gender, pronouns, date of birth, contact details, your professional experience, registration details and qualifications, professional insurance, your photo for your profile on the platform if you are happy to provide it, and we check your ID and right to work in the UK.
      We also process other information that we collect through our ongoing contact with you as we work together. </p>
      <p>We use this data on the legal basis that it is necessary for us to be able to provide and improve our services as appropriate, for entering into and managing our agreement with you to provide services.</p>
      <p>We collect information related to your health and any criminal information, including carrying out a DBS check, so we can manage any risks and identify anything that could affect your safety or of our clients.
      We have a legal responsibility to protect the safety of everyone involved in our work and have a lawful right to process data as explained, when it is for the purposes of safety and wellbeing.</p>
      <p>We collect ethnicity based only on your explicit consent, if you choose to provide that information, so we can allow clients and service users to select communication professionals by ethnicity if it is appropriate to do so, for example if it is a culturally sensitive event.
      This is optional and there is no negative impact if you choose not to provide this information. </p>
      <p>We do not share any health, criminal or other sensitive information outside of Signalise without your consent. The only exception would be if we need to report something to an external agency in order to protect the wellbeing of yourself or someone else.
      This includes social services referrals or contacting the emergency services if the situation required us to.</p>
      <p>We will ask you for referees, so please ensure your referees have consented to you sharing their name and contact details with us for our purposes of taking up references.</p>
      <h3>Members:</h3>
      <p>We collect your name, contact details, and payment transaction details when you apply for membership, on the legal basis that it is necessary for us to manage your membership. 
      We will ask you to declare any conflicts of interest if applicable.</p>
      <h3>People contacting us via our website:</h3>
      <p>If you fill out our online contact form or use our live chat function, we will collect and process your name and contact details and nature of your enquiry on the legal basis that it is necessary for our legitimate interests of managing enquiries that come in through those channels.</p>
      <p>Please note that if you choose to log in through an existing social media account, this processing is subject to the relevant social media company’s own Privacy Notice.</p>
      <h3>Other contacts:</h3>
      <p>We process names, contact details, and other information produced through our interactions, of other external contacts, such as our suppliers, to enable us to manage our working relationship with you, on the basis of our legitimate interests to be able to provide our services to those who need them, in the most effective way. 
      You have the right to object to any of this processing and we will assess any objection sensitively.</p>
      <h3>Marketing messages:</h3>
      <p>If we send electronic information about our services, fundraising or similar to you as an individual (rather than a business representative), we will do so only on the basis of your freely-given consent (or where you are an existing client and have not opted out of receiving these messages) and you can opt-out at any time. </p>
      <p>If we send electronic marketing messages to you as a business or external agency representative, we will do so for the purposes of our legitimate interests to increase awareness of our services, and you can opt-out at any time. </p>
      <p>If we send hard copy marketing messages to you, we will do so for the purposes of our legitimate interests to increase awareness of our services, and you can opt-out at any time.
      </p>
      <h2>Why we share your data</h2>
      <p>We share the data we process with other organisations, only when we have a lawful basis to do so, or when we are using a supplier who will act as a ‘Data Processor’ on our behalf. ‘Processors’ are businesses who handle, or could potentially handle, personal data as part of providing a service to us, and include our IT system providers, our email providers, and our website hosts.</p>
      <p>Other organisations we share data with include the HMRC and banks for processing tax and payments, and we will co-operate with police and other authorities if we are asked to, in order to investigate or prevent crime, including fraud, and other unwanted behaviours such as incompetence in public roles.</p>
      <p>We would also report something to an external agency in order to protect the wellbeing of yourself or someone else. This includes social services referrals or contacting the emergency services if the situation required us to.</p>
      <h2>How long we keep your data </h2>
      <p>Your data is only kept for as long as there is a lawful reason to retain it. Some of our retention periods are based on legal requirements, and others are based on the practical reasons we need to keep the data for a certain period of time. </p>
      <p>Once we reach the retention period, we will securely delete the relevant data, unless we are legally required to keep it longer, or there are legal reasons why we should keep it longer.</p>
      <h2>Your rights as a data subject </h2>
      <p>As a data subject, you have the following rights in relation to your personal data: </p>
      <ul className={css.listContainer}>
        <li>To be informed about how and why your data is handled, which we do so through this Privacy Notice;</li>
        <li>To gain access to copies of your personal data; </li>
        <li>To have errors or inaccuracies in your data changed;</li>
        <li>To have your personal data erased, in limited circumstances (sometimes known as the ‘right to be forgotten’);</li>
        <li>To object to the processing of your personal data for marketing purposes or when the processing is based on the public interest or other legitimate interests; </li>
        <li>To restrict the processing of your personal data, in limited circumstances; </li>
        <li>To obtain a copy of some of your data in a commonly used electronic form, in limited circumstances;</li>
        <li>Rights around how you are affected by any profiling or automated decisions – we do not currently make any automated decisions about our clients, volunteers or partners.</li>
      </ul>
      <p>If you wish to exercise any of these rights, please contact us. </p>
      <p>For more information about these rights, please see the<a href="https://ico.org.uk/"> ICO’s website</a> or contact us.</p>
      <h2>Withdrawing consent </h2>
      <p>If we are relying on your consent to process your data, you may withdraw your consent at any time by contacting us.</p>
      <h2>Complaints to the Information Commissioner </h2>
      <p>You have a right to complain to the Information Commissioner's Office (ICO) about the way in which we process your personal data.
      You can make a complaint on the <a href="https://ico.org.uk/"> ICO’s website</a>.</p>
      <h2>Website Cookies </h2>
      <p>Our website uses essential cookies which are necessary for the proper operation of the website, as well as non-essential cookies, namely:</p>
      <p>We use Google Analytics for aggregated, anonymized website traffic analysis. In order to track your session usage, Google drops a cookie (_ga) with a randomly-generated ClientID
         in your browser. This ID is anonymized and contains no identifiable information like email, phone number, name, etc.
         We also send Google your IP Address. We use GA to track aggregated website behavior, such as what pages you looked at,
         for how long, and so on. This information is important to us for improving the user experience and determining site effectiveness.
         If you would like to limit the amount of data shared with us please delete your _ga cookies, and/or install the Google Analytics Opt-Out Browser Add-On.</p>
      <p> Youtube requires cookies to function, and playing any of the youtube videos on this site will allow Google to drop a cookie tracking your session.
        If you would rather not allow Google to track your activity in this way, we suggest not playing any of the videos embedded on this site </p>
        <p> In order to save your login details, this site uses a cookie to remember you in between sessions.</p>
        <p> Our chat client, Zendesk, requires the use of a cookie for functionality. It is possible to remove this cookie (__zlcmid ) but it may prevent you from using the chat function. </p>
      {/* <p> Our Cookies settings area on the website allows you to choose whether to accept the non-essential cookies. 
      If you prefer to turn off essential cookies as well as non-essential cookies, you can turn them off in your browser, but please be aware that the website will not operate as intended.</p> */}
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
