import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import book from './image/Book.png';
import attend from './image/Attend.png';
import review from './image/Review.png';
import NamedLink from '../NamedLink/NamedLink';

import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const contactLink = (
    <NamedLink name="ContactPage" className={css.contactLink}>
      <span className={css.contact}>
        <FormattedMessage id="SectionHowItWorks.contactLink" />
      </span>
    </NamedLink>
  );
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <h2>
          <FormattedMessage id="SectionHowItWorks.title" />
        </h2>
        <div className={css.stepText}>
          <p>
            <FormattedMessage id="SectionHowItWorks.part1TextA" />
          </p>
          <p>
            <FormattedMessage id="SectionHowItWorks.part1TextAA" />
          </p>
          <p>
            <FormattedMessage id="SectionHowItWorks.part1TextAAA" />
          </p>
        </div>
      </div>

      <section className={css.steps}>
        <div className={css.stepGroup}>
          <div className={css.stepA}>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.part1Title" />
            </h2>
            <div className={css.stepText}>
              <p>
                <FormattedMessage id="SectionHowItWorks.part1TextB" />
              </p>
              <NamedLink name="CreateRequirementPage" className={css.ctaLink}>Create a booking</NamedLink>
            </div>
          </div>
          <img src={book} alt="Signalise book" className={css.howImage} />
        </div>

        <div className={css.stepGroup}>
          <div className={css.stepB}>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.part2Title" />
            </h2>
            <div className={css.stepText}>
              <p>
                <FormattedMessage id="SectionHowItWorks.part2TextA" />
              </p>
              <NamedLink name="CreateRequirementPage" className={css.ctaLink}>Create a booking</NamedLink>
            </div>
          </div>
          <img src={attend} alt="Signalise attend" className={css.howImage} />
        </div>

        <div className={css.stepGroup}>
          <div className={css.stepC}>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.part3Title" />
            </h2>
            <div className={css.stepText}>
              <p>
                <FormattedMessage id="SectionHowItWorks.part3TextA" values={{ contactLink }} />
              </p>
              <p>
                <FormattedMessage id="SectionHowItWorks.part3TextB" />
              </p>
              <NamedLink name="VRSSalesPage" className={css.ctaLink}>Find out more</NamedLink>
            </div>
          </div>
          <img src={review} alt="Signalise review" className={css.howImage} />
        </div>
      </section>
    </div>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionHowItWorks;
