import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  IconSpinner,
  Form,
  PrimaryButton,
  FieldTextInput,
  LocationAutocompleteInputField,
  FieldSelect,
} from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';

import css from './BookingTimeForm.module.css';

const identity = v => v;

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const {
      bookingStartTime,
      bookingEndTime,
      deafUserName,
      bookingBuilding,
      bookingLocation,
      bookingInfo,
      buildingOrRemote,
    } = formValues.values;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    // We expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    const isSameTime = bookingStartTime === bookingEndTime;

    if (bookingStartTime && bookingEndTime && !isSameTime && !this.props.fetchLineItemsInProgress) {
      this.props.onFetchTransactionLineItems({
        bookingData: {
          startDate,
          endDate,
          deafUserName,
          bookingLocation,
          bookingBuilding,
          bookingInfo,
          buildingOrRemote,
        },
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
          } = fieldRenderProps;

          if (
            values &&
            values.buildingOrRemote === 'In person' &&
            values.bookingStartDate &&
            values.bookingEndTime &&
            values.bookingLocation.selectedPlace.address === 'United Kingdom'
          ) {
            delete values.bookingInfo;
            delete values.bookingLocation;
            delete values.bookingBuilding;
            delete values.deafUserName;
            values.bookingStartDate = undefined;
            values.bookingStartTime = undefined;
            values.bookingEndDate = undefined;
            values.bookingEndTime = undefined;
          }

          const deafUserName = values && values.deafUserName ? values.deafUserName : null;

          const bookingBuilding = values && values.bookingBuilding ? values.bookingBuilding : null;
          const bookingInfo = values && values.bookingInfo ? values.bookingInfo : null;
          const bookingLocation = values && values.bookingLocation ? values.bookingLocation : null;
          const buildingOrRemote =
            values && values.buildingOrRemote ? values.buildingOrRemote : null;

          let startTime = null;
          let endTime = null;
          let selectedPlace = null;

          if (values && values.buildingOrRemote === 'Remote') {
            values.bookingBuilding = '1';
            values.bookingLocation = {
              predictions: [],
              search: 'United Kingdom',
              selectedPlace: {
                address: 'United Kingdom',
              },
            };
          }

          if (bookingLocation && bookingLocation.selectedPlace) {
            selectedPlace = bookingLocation.selectedPlace ? bookingLocation.selectedPlace : null;
            startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
            endTime = values && values.bookingEndTime ? values.bookingEndTime : null;
          } else {
            values.date = null;
            values.bookingStartDate = undefined;
            values.bookingStartTime = undefined;
            values.bookingEndDate = undefined;
            values.bookingEndTime = 'undefined';
          }

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });
          const deafUserNameLabel = intl.formatMessage({
            id: 'BookingTimeForm.deafUserLabel',
          });
          const deafUserPlaceholder = intl.formatMessage({
            id: 'BookingTimeForm.deafUserPlaceholder',
          });
          const bookingLocationLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingLocationLabel',
          });
          const bookingLocationPlaceholder = intl.formatMessage({
            id: 'BookingTimeForm.bookingLocationPlaceholder',
          });
          const addressRequiredMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressRequired',
          });
          const addressNotRecognisedMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressNotRecognised',
          });
          const optionalText = intl.formatMessage({
            id: 'EditListingLocationForm.optionalText',
          });
          //Building not required for location
          const buildingMessage = intl.formatMessage(
            { id: 'EditListingLocationForm.building' },
            { optionalText: optionalText }
          );
          const buildingPlaceholderMessage = intl.formatMessage({
            id: 'BookingTimeForm.bookingBuildingPlaceholder',
          });
          const bookingInfoLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingInfoLabel',
          });
          const bookingInfoPlaceholder = intl.formatMessage({
            id: 'BookingTimeForm.bookingInfoPlaceholder',
          });

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                  timeZone,
                  deafUserName,
                  bookingLocation,
                  bookingBuilding,
                  bookingInfo,
                  buildingOrRemote,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingTimeForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const buildingOrRemoteValues = values.buildingOrRemote;
          const deafUserNameValues = values.deafUserName;
          const bookingInfoValues = values.bookingInfo;
          const showPerson = buildingOrRemoteValues === 'In person';
          const showRemote = buildingOrRemoteValues === 'Remote';

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />

              <div className={classNames(css.bookingRemoteInfo)}>
                <FieldSelect
                  id="buildingOrRemote"
                  name="buildingOrRemote"
                  label="Is this booking in person or remote?"
                >
                  <option disabled value="">
                    Choose from...
                  </option>
                  <option value="In person">In person</option>
                  <option value="Remote">Remote</option>
                </FieldSelect>
                <p className={css.bioInfo}>
                  <FormattedMessage id="BookingTimeForm.buildingOrRemoteInfo" />
                </p>
              </div>

              {showPerson ? (
                <FieldTextInput
                  className={css.building}
                  type="text"
                  name="bookingBuilding"
                  id="bookingBuilding"
                  label={buildingMessage}
                  placeholder={buildingPlaceholderMessage}
                />
              ) : null}
              {showPerson ? (
                <>
                  <LocationAutocompleteInputField
                    name="bookingLocation"
                    label={bookingLocationLabel}
                    placeholder={bookingLocationPlaceholder}
                    useDefaultPredictions={false}
                    format={identity}
                    valueFromForm={values.bookingLocation}
                    validate={composeValidators(
                      autocompleteSearchRequired(addressRequiredMessage),
                      autocompletePlaceSelected(addressNotRecognisedMessage)
                    )}
                  />
                </>
              ) : null}
              {showPerson || showRemote ? (
                <FieldTextInput
                  className={css.bookingInfo}
                  values={values}
                  type="text"
                  id="deafUserName"
                  name="deafUserName"
                  label={deafUserNameLabel}
                  placeholder={deafUserPlaceholder}
                />
              ) : null}
              {showPerson || showRemote ? (
                <FieldTextInput
                  className={css.bookingInfo}
                  values={values}
                  type="text"
                  id="bookingInfo"
                  name="bookingInfo"
                  label={bookingInfoLabel}
                  placeholder={bookingInfoPlaceholder}
                />
              ) : null}

              {(showPerson && selectedPlace) ||
              (showRemote && deafUserNameValues && bookingInfoValues) ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                />
              ) : null}

              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              {(showPerson && selectedPlace) ||
              (showRemote && deafUserNameValues && bookingInfoValues) ? (
                <p className={css.smallPrint}>
                  <FormattedMessage id={'BookingTimeForm.bookingDurationMessage'} />
                </p>
              ) : null}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingTimeForm.ownListing'
                      : 'BookingTimeForm.youWontBeChargedInfo'
                  }
                />
              </p>
              {values.bookingStartTime &&
              values.bookingEndTime &&
              ((showPerson && selectedPlace && monthlyTimeSlots && timeZone) ||
                (showRemote && deafUserNameValues && bookingInfoValues)) ? (
                <div className={submitButtonClasses}>
                  <PrimaryButton type="submit">
                    <FormattedMessage id="BookingTimeForm.requestToBook" />
                  </PrimaryButton>
                </div>
              ) : null}
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
