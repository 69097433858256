import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldCheckboxGroup,
  NamedLink,
  PropertyGroup,
} from '../../components';
import config from '../../config';
import arrayMutators from 'final-form-arrays';

import css from './SignupForm.module.css';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        openTermsOfService,
        defaultCheckboxValue,
      } = fieldRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );

      const passwordUpperCaseMessage = intl.formatMessage({
        id: 'PasswordChangeForm.passwordUpperCase',
      });
      const passwordLowerCaseMessage = intl.formatMessage({
        id: 'PasswordChangeForm.passwordLowerCase',
      });
      const passwordNumberMessage = intl.formatMessage({
        id: 'PasswordChangeForm.passwordNumber',
      });
      const passwordSpecialMessage = intl.formatMessage({
        id: 'PasswordChangeForm.passwordSpecial',
      });

      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordUpperCase = validators.complexityTest(
        passwordUpperCaseMessage,
        validators.PASSWORD_UPPERCASE
      );

      const passwordLowerCase = validators.complexityTest(
        passwordLowerCaseMessage,
        validators.PASSWORD_LOWERCASE
      );

      const passwordNumber = validators.complexityTest(
        passwordNumberMessage,
        validators.PASSWORD_NUMBER
      );

      const passwordSpecial = validators.complexityTest(
        passwordSpecialMessage,
        validators.PASSWORD_SPECIAL
      );

      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength,
        passwordUpperCase,
        passwordLowerCase,
        passwordNumber,
        passwordSpecial
      );

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      // user types

      const userTypesLabel = intl.formatMessage({
        id: 'SignupForm.userTypesLabel',
      });
      const userTypesRequiredMessage = intl.formatMessage({
        id: 'SignupForm.userTypesRequired',
      });

      const userTypesRequired = validators.requiredFieldArrayCheckbox(userTypesRequiredMessage);

      const userTypesOptions = [
        {
          key: 'communicationProfessional',
          label: 'I am a communication professional',
        },
        { key: 'deafUser', label: 'I am Deaf and use communication professionals' },
        { key: 'customer', label: 'I want to book communication professionals' },
      ];

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          openTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={openTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
              />
            </div>
            <div className={css.contractsInfo}>
              Note: If you are booking via a contract (e.g. NHS) please use the{' '}
              <NamedLink name="ContractsPage" className={css.contractsLink}>
                contract sign up page
              </NamedLink>
              .
            </div>
            <FieldCheckboxGroup
              className={css.userTypes}
              id="userTypes"
              name="userTypes"
              label={userTypesLabel}
              options={userTypesOptions}
              validate={userTypesRequired}
              defaultValue={defaultCheckboxValue ?? null}
            />
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordValidators}
            />
          </div>
          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false, filterConfig: config.custom.filters };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  openTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
