import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import CustomLinksMenu from './CustomLinksMenu/CustomLinksMenu';
import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    config,
    customLinks,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const test = true;

  // userTypes for UserNav options
  const user = ensureCurrentUser(currentUser);
  const privateData = user.attributes.profile.privateData || {};
  const userTypes = privateData.userTypes || [];
  const isUserProvider = userTypes.includes('communicationProfessional');
  const isUserCustomer = userTypes.includes('customer');
  const isAllowedUser = isUserProvider || userTypes.includes('deafUser') || isUserCustomer;
  const isUserContractCustomer = userTypes.includes('contractCustomer');
  const isUserDeaf = userTypes.includes('deafUser');
  const notCustomer = ['deafUser', 'communicationProfessional'];
  const viewBookingsOnly =
    notCustomer.some(a => userTypes.includes(a)) && !userTypes.includes('customer');

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const videoConferenceLink = !isAuthenticated ? null : isUserDeaf ? (
    <NamedLink
      className={classNames(
        css.createListingLink,
        css.requirement,
        currentPageClass('DeafUserVideoAccessPage')
      )}
      name="DeafUserVideoAccessPage"
    >
      <span className={css.icon}>videocam</span>

      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.videoConference" />
      </span>
    </NamedLink>
  ) : isUserContractCustomer ? (
    <NamedLink
      className={classNames(
        css.createListingLink,
        css.requirement,
        currentPageClass('VideoConferencePage')
      )}
      name="VideoConferencePage"
    >
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.videoConference" />
      </span>
    </NamedLink>
  ) : null;

  const profileMenu =
    authenticatedOnClientSide && isUserProvider ? (
      <Menu>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="ManageBookingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageBookingsPage'))}
              name="ManageBookingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.manageBookings" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="FavouritesPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('FavouritesPage'))}
              name="FavouritesPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.FavouritesLink" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
    ) : authenticatedOnClientSide ? (
      <Menu>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="FavouritesPage">
            <NamedLink
              className={classNames(css.FavouritesLink, currentPageClass('FavouritesPage'))}
              name="FavouritesPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.FavouritesLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
    ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
  const testBar = (
    <CustomLinksMenu
      currentPage={currentPage}
      customLinks={customLinks}
      intl={intl}
      hasClientSideContentReady={authenticatedOnClientSide || !isAuthenticatedOrJustHydrated}
    />
  );

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          title="Signalise Home Page"
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {search}
      {testBar}

      {isAllowedUser && authenticatedOnClientSide && !viewBookingsOnly ? (
        <NamedLink
          className={classNames(css.createListingLink, css.requirement)}
          name="CreateRequirementPage"
        >
          <span className={css.icon}>person</span>

          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.createRequirementListing" />
          </span>
        </NamedLink>
      ) : null}

      {videoConferenceLink}
      {isAllowedUser && authenticatedOnClientSide && (
        <NamedLink
          className={classNames(css.createListingLink, css.requirement)}
          name="ManageBookingsPage"
        >
          {' '}
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.manageBookings" />
          </span>
        </NamedLink>
      )}
      {/* {// isUserProvider && authenticatedOnClientSide ? (
        //   <NamedLink
        //     className={css.createListingLink}
        //     name="SearchPage"
        //     to={{
        //       search: 'pub_listingtype=requirement',
        //     }}
        //   >
        //     <span className={css.createListing}>
        //       <FormattedMessage id="SectionHero.browseBookingsButton" />
        //     </span>
        //   </NamedLink>
        // ) :
        isUserProvider || !authenticatedOnClientSide ? (
          <NamedLink className={css.createListingLink} name="NewListingPage">
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.createListing" />
            </span>
          </NamedLink>
        ) : null}{' '} */}

      {/*{inboxLink}*/}
      {profileMenu}
      {signupLink}
      {loginLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
