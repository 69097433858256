import React, { Component } from 'react';
import { array, string, func } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage, AddToFavourites } from '../../components';

import css from './ListingCard.module.css';
import moment from 'moment';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const getRegistrationInfo = (registrationOptions, key) => {
  return registrationOptions.find(c => c.key === key);
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    currentUser,
    listing,
    renderSizes,
    filtersConfig,
    setActiveListing,
    onAddToFavourites,
    addToFavouritesInProgress,
    addToFavouritesError,
    currentFavouritesListingId,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const firstPartPostcode = publicData?.firstPartPostcode;
  const registrationOptions = findOptionsForSelectFilter('registration', filtersConfig);
  const registration = publicData
    ? getRegistrationInfo(registrationOptions, publicData.registration)
    : null;

  let reg = registration && registration.label;
  if (reg) {
    reg = reg.replace('Registered', '');
  }
  if (reg) {
    reg = reg.replace('Trainee', '');
  }

  const isRequirement = publicData?.listingtype === 'requirement';
  let data = {};
  const options = findOptionsForSelectFilter('workDomains', config.custom.filters);
  const typeLabel = [publicData?.workDomain];

  const isInPerson = publicData?.personOrRemote === 'In person';

  const typeLabelOptions = typeLabel.map(c => options?.find(o => o.key === c)?.label);

  if (isRequirement) {
    data = {
      type: typeLabelOptions,
      time: {
        start: publicData?.requirementStart,
        end: publicData?.requirementEnd,
      },
      person_or_remote: publicData?.personOrRemote,
    };
  }

  const formattedTime = time => moment(time).format('DD/MM/YYYY, HH:mm');

  return (
    <div className={classes}>
      <NamedLink name="ListingPage" params={{ id, slug }}>
        <div
          className={css.threeToTwoWrapper}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          {isRequirement ? (
            <div className={css.aspectWrapperLandscape}>
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
              sizes={renderSizes}
              isRequirement={isRequirement}
              registration={registration}
              firstPartPostcode={firstPartPostcode}
            />
            </div>
            ) : (
              <div className={css.aspectWrapper}>
              <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={['portrait-crop', 'portrait-crop2x']}
              sizes={renderSizes}
              isRequirement={isRequirement}
              registration={registration}
            />
            </div>
          )}
          </div>
      </NamedLink>
      <div className={css.info}>
        <div className={css.cardRow}>
          <div className={css.cardColumn}>
            <NamedLink name="ListingPage" params={{ id, slug }}>
              {isRequirement ? (
                <>
                  <div className={classNames(css.registrationInfo, css.bookingInfo)}>
                    <time>
                      <span>{formattedTime(data.time.start)} </span>
                    </time>{' '}
                    -
                    <br />
                    <time>
                      <span>{formattedTime(data.time.end)}</span>
                    </time>
                    <p>
                      <span>{data.type.join(',')}</span>
                    </p>
                    <p>
                      <span>{data.person_or_remote}</span>
                    </p>
                    {isInPerson ? (
                    <p>
                      <span>Location: {firstPartPostcode}</span>
                    </p>
                    ) : null}
                  </div>
                </>
              ) : (
                <div className={css.title}>
                  {richText(title, {
                    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                    longWordClass: css.longWord,
                  })}
                </div>
              )}
              <div className={css.registrationInfo}>
                {registration && !registration.hideFromListingInfo ? <span>{reg}</span> : null}
              </div>
            </NamedLink>
          </div>
          {!isRequirement ? (
            <AddToFavourites
              listingId={id}
              currentUser={currentUser}
              onAddToFavourites={onAddToFavourites}
              listing={listing}
              currentFavouritesListingId={currentFavouritesListingId}
              addToFavouritesInProgress={addToFavouritesInProgress}
              addToFavouritesError={addToFavouritesError}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
