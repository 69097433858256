import React, { Component } from 'react';
import css from './MailchimpSignupForm.module.css';
import classNames from 'classnames';

const mailchimpURL = process.env.REACT_APP_MAILCHIMP_URL;
class MailchimpSignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValue: '',
      fNameValue: '',
      lNameValue: '',
    };
  }

  render() {
    return (
      <form action={mailchimpURL} method="POST" noValidate className={ css.subscribeForm }>
        <div className={classNames(css.sectionContainer)}>
          <input type="hidden" name="u" value="8776592a2ecbf77d1b0ca5242"/>
          <input type="hidden" name="id" value="40e4e6412a"/>

          <p><sup>&lowast;</sup> indicates this information is required</p>
          
          <label htmlFor='mce-FNAME'>
            First Name <sup>&lowast;</sup>
            </label>
            <input
              type="text"
              name="FNAME"
              id="mce-FNAME"
              value={this.state.fNameValue}
              placeholder="Enter your first name"
              onChange={(e)=>{this.setState({fNameValue: e.target.value});}}
              required
            />

          <label htmlFor='mce-LNAME'>
            Last Name <sup>&lowast;</sup>
            </label>
            <input
              type="text"
              name="LNAME"
              id="mce-LNAME"
              value={this.state.lNameValue}
              placeholder="Enter your last name"
              onChange={(e)=>{this.setState({lNameValue: e.target.value});}}
              required
            />

          <label htmlFor='mce-EMAIL'>
            Email <sup>&lowast;</sup>
            </label>
            <input
              type="email"
              name="EMAIL"
              id="mce-EMAIL"
              value={this.state.emailValue}
              onChange={ (e)=>{this.setState({emailValue: e.target.value});} }
              autoCapitalize="off"
              autoCorrect="off"
              placeholder="Enter your email address"
              required
            />

          <fieldset className={ css.fieldset }>
            <legend>Choose which suits you best</legend>
              <label className={ css.label }htmlFor="mce-group[2287]-2287-0"
                >Deaf community member
                <input
                  type="radio"
                  value="1"
                  name="group[2287]"
                  id="mce-group[2287]-2287-0"
                /><span></span>
              </label>
              
              <label className={ css.label } htmlFor="mce-group[2287]-2287-1"
                >BSL/English Interpreter / communication
                professional
                <input
                  type="radio"
                  value="2"
                  name="group[2287]"
                  id="mce-group[2287]-2287-1"
                /><span></span>
              </label>
              
              <label className={ css.label } htmlFor="mce-group[2287]-2287-2"
                >Service, organisation or other interested party
                <input
                  type="radio"
                  value="4"
                  name="group[2287]"
                  id="mce-group[2287]-2287-2"
                /><span></span>
              </label>
            </fieldset>

            <div className={ css.marketingAcceptance }>
              <p>Please select to confirm you would like to hear from Signalise Co-op by</p>
              <label className={ [css.label, css.check].join(' ')} htmlFor="gdpr_23471"
                >Email
                <input
                  type="checkbox"
                  id="gdpr_23471"
                  name="gdpr[23471]"
                  value="Y"
                /><span></span>
              </label>
            </div>

          <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className={ css.primaryButtonRoot}/>

          <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true' aria-label="Please leave the following three fields empty">
            <label htmlFor="fName">First Name: </label>
            <input type="text" name="b_name" tabIndex="-1" defaultValue="" placeholder="Enter your first name" id="fName"/>

            <label htmlFor="lName">Last Name: </label>
            <input type="text" name="b_name" tabIndex="-1" defaultValue="" placeholder="Enter your last name" id="lName"/>

            <label htmlFor="eMail">Email: </label>
            <input type="email" name="b_email" tabIndex="-1" defaultValue="" placeholder="Enter your youremail@gmail.com" id="email"/>

            <label htmlFor="b_choose">Choose which suits you best:</label>
            <textarea name="b_choose" tabIndex="-1" placeholder="Please choose which suit you best" id="b_choose"></textarea>
          </div>

          <div className="content__gdprLegal">
            <p>
              We use Mailchimp as our marketing platform. By clicking
              to subscribe, you acknowledge that your information
              will be transferred to Mailchimp for processing.
              <a href="https://mailchimp.com/legal/"
                > Learn more about Mailchimp's privacy practices here.</a>
            </p>

            <p>
            You are signing up to receive email updates on the work Signalise is doing.
            You can unsubscribe at any time by clicking the link in
            the footer of our emails. More information about our
            privacy practices is available on our website.
            </p>
          </div>
        </div>
      </form>
    )
  }
}

export default MailchimpSignupForm;