import pick from 'lodash/pick';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { initiatePrivileged, transactionLineItems } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { findNextBoundary, nextMonthFn, monthIdStringInTimeZone } from '../../util/dates';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import moment from 'moment';
import axios from 'axios';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const SEND_FAVOURITES_REQUEST = 'app/SearchPage/SEND_FAVOURITES_REQUEST';
export const SAVE_FAVOURITES_SUCCESS = 'app/SearchPage/SAVE_FAVOURITES_SUCCESS';
export const SAVE_FAVOURITES_ERROR = 'app/SearchPage/SAVE_FAVOURITES_ERROR';

//reverse marketplace specific action constants

export const FETCH_CURRENT_USER_LISTINGS_REQUEST =
  'app/ListingPage/FETCH_CURRENT_USER_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_LISTINGS_REQUEST_SUCCESS =
  'app/ListingPage/FETCH_CURRENT_USER_LISTINGS_REQUEST_SUCCESS';
export const FETCH_CURRENT_USER_LISTINGS_REQUEST_ERROR =
  'app/ListingPage/FETCH_CURRENT_USER_LISTINGS_REQUEST_ERROR';

export const INTERESTED_LISTING_SLOTS_FOUND_SUCCESS =
  'app/ListingPage/INTERESTED_LISTING_SLOTS_FOUND_SUCCESS';
export const INTERESTED_LISTING_SLOTS_FOUND_REQUEST =
  'app/ListingPage/INTERESTED_LISTING_SLOTS_FOUND_REQUEST';
export const INTERESTED_LISTING_SLOTS_FOUND_ERROR =
  'app/ListingPage/INTERESTED_LISTING_SLOTS_FOUND_ERROR';

export const RESET_REVERSE_MARKETPLACE_BOOKING_VALUES =
  'app/ListingPage/RESET_REVERSE_MARKETPLACE_BOOKING_VALUES';

//end reverse marketplace specific action constants

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: {
    // '2019-12': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  saveFavouritesInProgress: false,
  saveFavouritesError: null,
  userId: null,

  //reverse marketplace data
  fetchCurrentUserListingsInProgress: false,
  currentUserListings: null,
  fetchCurrentUserListingsError: null,

  interestedListingSlotsLoading: false,
  userChosenListing: null,
  interestedListingSlotsError: null,
  //end reverse marketplace data
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case SEND_FAVOURITES_REQUEST:
      return { ...state, sendFavouriteInProgress: true, sendFavouriteError: null };
    case SAVE_FAVOURITES_SUCCESS:
      return { ...state, sendFavouriteInProgress: false };
    case SAVE_FAVOURITES_ERROR:
      return { ...state, sendFavouriteInProgress: false, sendFavouriteError: payload };

    //reverse marketplace specific cases
    case FETCH_CURRENT_USER_LISTINGS_REQUEST:
      return {
        ...state,
        fetchCurrentUserListingsInProgress: true,
        interestedListingSlotsError: null,
      };
    case FETCH_CURRENT_USER_LISTINGS_REQUEST_SUCCESS:
      return {
        ...state,
        currentUserListings: payload,
        fetchCurrentUserListingsInProgress: false,
        interestedListingSlotsError: null,
      };
    case FETCH_CURRENT_USER_LISTINGS_REQUEST_ERROR:
      return {
        ...state,
        fetchCurrentUserListingsError: payload,
        fetchCurrentUserListingsInProgress: false,
        interestedListingSlotsError: null,
      };
    case INTERESTED_LISTING_SLOTS_FOUND_REQUEST:
      return {
        ...state,
        interestedListingSlotsLoading: true,
        interestedListingSlotsError: null,
      };
    case INTERESTED_LISTING_SLOTS_FOUND_SUCCESS:
      return {
        ...state,
        ...payload,
        interestedListingSlotsLoading: false,
        interestedListingSlotsError: null,
      };
    case INTERESTED_LISTING_SLOTS_FOUND_ERROR:
      return {
        ...state,
        ...payload,
        interestedListingSlotsLoading: false,
      };
    case RESET_REVERSE_MARKETPLACE_BOOKING_VALUES:
      return {
        ...state,
        fetchCurrentUserListingsInProgress: false,
        currentUserListings: null,
        fetchCurrentUserListingsError: null,

        interestedListingSlotsLoading: false,
        userChosenListing: null,
        interestedListingSlotsError: null,
      };
    //end reverse marketplace specific cases

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const sendFavouritesRequest = () => ({ type: SEND_FAVOURITES_REQUEST });
export const saveFavouritesSuccess = () => ({ type: SAVE_FAVOURITES_SUCCESS });
export const saveFavouritesError = error => ({
  type: SAVE_FAVOURITES_ERROR,
  payload: error,
  error: true,
});

//reverse marketplace specific action creators

export const fetchCurrentUserListingsRequest = () => ({
  type: FETCH_CURRENT_USER_LISTINGS_REQUEST,
});
export const fetchCurrentUserListingsSuccess = data => ({
  type: FETCH_CURRENT_USER_LISTINGS_REQUEST_SUCCESS,
  payload: data,
});
export const fetchCurrentUserListingsError = e => ({
  type: FETCH_CURRENT_USER_LISTINGS_REQUEST_ERROR,
  error: true,
  payload: e,
});

export const interestedListingSlotsRequest = () => ({
  type: INTERESTED_LISTING_SLOTS_FOUND_REQUEST,
});

export const interestedListingSlotsfoundSuccess = data => ({
  type: INTERESTED_LISTING_SLOTS_FOUND_SUCCESS,
  payload: data,
});

export const interestedListingSlotsError = error => ({
  type: INTERESTED_LISTING_SLOTS_FOUND_ERROR,
  payload: error,
});

export const resetReverseBookingValues = () => ({ type: RESET_REVERSE_MARKETPLACE_BOOKING_VALUES });

//end reverse marketplace specific action creators

// ================ Thunks ================ //

//reverse marketplace specific thunks

export const fetchCurrentUserListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserListingsRequest());
  return sdk.ownListings
    .query()
    .then(res => {
      let response = denormalisedResponseEntities(res);
      response = response
        .filter(
          responseItem => !['draft', 'pendingApproval'].includes(responseItem.attributes.state)
        )
        .filter(responseItem => responseItem.attributes.publicData.listingtype != 'requirement');

      if (response.length > 0) {
        return dispatch(fetchCurrentUserListingsSuccess(response));
      }
      return dispatch(fetchCurrentUserListingsError('No Bookable Listings were found'));
    })
    .catch(err => dispatch(fetchCurrentUserListingsError('No Bookable Listings were found')));
};

const fetchRequirementListingById = id => async (dispatch, getState, sdk) => {
  try {
    const response = await sdk.listings.show({ id });
    return response;
  } catch (e) {
    console.log(e);
    // return e;
    throw new Error(e.message);
  }
};

const fetchInterestedListingTimeSlots = (listingId, start, end, timeZone) => async (
  dispatch,
  getState,
  sdk
) => {
  const extraParams = {
    per_page: 500,
    page: 1,
  };

  try {
    let response = await sdk.timeslots.query({ listingId, start, end, ...extraParams });
    response = denormalisedResponseEntities(response);
    console.log(response);
    return response;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const checkIfChosenListingAvailabilityMatchCurrentListing = (
  userChosenListingId,
  requirementListingId
) => async (dispatch, getState, sdk) => {
  const { ListingPage } = getState();
  const { id, currentUserListings } = ListingPage;
  const tz = currentUserListings?.find(l => l.id.uuid === userChosenListingId)?.attributes
    ?.availabilityPlan?.timezone;
  // const bd = moment(new Date())
  //   .add(6, 'days')
  //   .format('DD-MM-YYYY');
  // const bts = moment(new Date())
  //   .startOf('day')
  //   .add(6, 'days')
  //   .add(1, 'hours')
  //   // .add(30, 'minutes')
  //   .valueOf();
  // const bte = moment(new Date())
  //   .startOf('day')
  //   .add(6, 'days')
  //   // .add(2, 'hours')
  //   .add(1, 'hours')
  //   .add(30, 'minutes')
  //   .valueOf();

  try {
    let requirementListingData = await dispatch(fetchRequirementListingById(requirementListingId));
    requirementListingData = denormalisedResponseEntities(requirementListingData);
    requirementListingData = requirementListingData?.[0]?.attributes?.publicData;
    const { requirementStart, requirementEnd, interpretersNeeded } = requirementListingData;

    //interestedListingSlots

    dispatch(interestedListingSlotsRequest());
    const timeSlots = await dispatch(
      fetchInterestedListingTimeSlots(
        userChosenListingId,
        moment(requirementStart)
          .subtract(1, 'days')
          .toISOString(),
        moment(requirementEnd)
          .endOf('month')
          .toISOString(),
        tz
      )
    );
    // console.log(timeSlots);
    //check if the user has timeslots
    if (timeSlots.length > 0) {
      const found = timeSlots.find(t => {
        const {
          attributes: { start, end },
        } = t;
        const timeObject = { start: moment(start).valueOf(), end: moment(end).valueOf() };
        if (
          requirementStart >= timeObject.start &&
          requirementStart <= timeObject.end &&
          requirementEnd >= timeObject.start &&
          requirementEnd <= timeObject.end
        )
          return true;
      });
      console.log(found);
      if (found) {
        dispatch(
          interestedListingSlotsfoundSuccess({
            userChosenListing: userChosenListingId,
          })
        );
      } else {
        dispatch(
          interestedListingSlotsError({
            interestedListingSlotsError:
              'Your calendar says you are not available, please adjust your availability and come back to make an offer.',
          })
        );
      }
    } else {
      dispatch(
        interestedListingSlotsError({
          interestedListingSlotsError:
            'Your calendar says you are not available, please adjust your availability and come back to make an offer.',
        })
      );
    }
  } catch (e) {
    console.log(e);
    dispatch(
      interestedListingSlotsError({
        interestedListingSlotsError: 'Something went wrong, please refresh the page and try again.',
      })
    );
  }
};

export const submitOffer = (listingId, price) => async (dispatch, getState, sdk) => {
  const {
    ListingPage: { userChosenListing },
  } = getState();

  let requirementListingData = await dispatch(fetchRequirementListingById(listingId));
  requirementListingData = denormalisedResponseEntities(requirementListingData);
  requirementListingData = requirementListingData?.[0]?.attributes?.publicData;
  const { requirementStart, requirementEnd, interpretersNeeded } = requirementListingData;

  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.reverseMarketplaceProcessAlias,
    params: {
      listingId,
    },
  };
  const queryParams = {
    expand: true,
  };
  // return sdk.transactions
  //   .initiate(bodyParams)
  return initiatePrivileged({
    isSpeculative: false,
    bookingData: { quantity: 1, booking: true },
    bodyParams,
    queryParams,
  })
    .then(response => {
      const transactionId = response.data.data.id;
      console.log('transactionId', { transactionId, response });
      // return;
      return axios
        .post(`/submit-offer`, {
          requirementStart,
          requirementEnd,
          transactionId: transactionId.uuid,
          listingId: userChosenListing,
          price: price.amount,
        })
        .then(res => {
          return sdk.messages
            .send({ transactionId, content: 'I am available for this booking.' })
            .then(() => {
              dispatch(sendEnquirySuccess());
              dispatch(fetchCurrentUserHasOrdersSuccess(true));
              return transactionId;
            });
        })
        .catch(err => console.log(err));
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

// end reverse marketplace specific thunks

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdStringInTimeZone(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    per_page: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const sendEnquiry = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendEnquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const nextBoundary = findNextBoundary(tz, new Date());

    const nextMonth = nextMonthFn(nextBoundary, tz);
    const nextAfterNextMonth = nextMonthFn(nextMonth, tz);

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

export const fetchTransactionLineItems = ({ bookingData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ bookingData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        bookingData: bookingData,
      });
    });
};

const requestSaveFavourites = params => (dispatch, getState, sdk) => {
  const favouritesList = params;

  return sdk.currentUser
    .updateProfile(
      { privateData: { favouritesList } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveFavouritesError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/**
 * Save Favourites and update the current user.
 */
export const saveFavourites = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestSaveFavourites(params))
      .then(user => {
        dispatch(saveFavouritesSuccess(user));
      })
      // error action dispatched in requestSaveNhsNumber
      .catch(e => null)
  );
};

export const loadData = (params, search) => dispatch => {
  const listingId = new UUID(params.id);

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  return Promise.all([dispatch(showListing(listingId)), dispatch(fetchReviews(listingId))]).then(
    responses => {
      if (responses[0] && responses[0].data && responses[0].data.data) {
        const listing = responses[0].data.data;

        // Fetch timeSlots.
        // This can happen parallel to loadData.
        // We are not interested to return them from loadData call.
        fetchMonthlyTimeSlots(dispatch, listing);
      }
      return responses;
    }
  );
};
