import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { required, validRBSLI, validNRCPD, validSRLPDC } from '../../util/validators'; // composeValidators removed as not used
import { Form, Button, FieldTextInput, FieldSelect } from '../../components';
import config from '../../config';

import css from './EditListingRegistrationForm.module.css';

const EditListingRegistrationFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        rootClassName,
        handleSubmit,
        intl,
        invalid,
        values,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        registrationOptions,
      } = formRenderProps;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingRegistrationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingRegistrationForm.showListingFailed" />
        </p>
      ) : null;

      // qualification details
      const qualificationDetailsLabel = intl.formatMessage({
        id: 'EditListingRegistrationForm.qualificationDetailsLabel',
      });

      const qualificationDetailsPlaceholder = intl.formatMessage({
        id: 'EditListingRegistrationForm.qualificationDetailsPlaceholder',
      });

      const qualificationDetailsValidation = required(
        intl.formatMessage({
          id: 'EditListingRegistrationForm.qualificationDetailsValidation',
        })
      );
      // registration status (trainee/registered) label
      const registrationLabel = intl.formatMessage({
        id: 'EditListingRegistrationForm.registrationLabel',
      });
      // registered/trainee validation message
      const registrationStatusValidation = required(
        intl.formatMessage({
          id: 'EditListingRegistrationForm.registrationStatusValidation',
        })
      );
      // registration body label
      const registrationBodyLabel = intl.formatMessage({
        id: 'EditListingRegistrationForm.registrationBodyLabel',
      });
      // registration body validation message
      const registrationBodyValidation = required(
        intl.formatMessage({
          id: 'EditListingRegistrationForm.registrationBodyValidation',
        })
      );
      // registration # label
      const registrationNumberLabel = intl.formatMessage({
        id: 'EditListingRegistrationForm.registrationNumberLabel',
      });
      // registration # placeholder
      const registrationNumberPlaceholder = () => registrationBodies[values.registrationBody]?.[1];
      // registration # validation message
      const registrationNumberValidation = () =>
        registrationBodies[values.registrationBody]?.[0](
          intl.formatMessage({
            id: 'EditListingRegistrationForm.registrationNumberValidation',
          })
        );
      // registration expiry validation
      const registrationExpiryMonthValidation = intl.formatMessage({
        id: 'EditListingRegistrationForm.registrationExpiryMonthValidation',
      });
      // registration expiry month label
      const registrationExpiryMonthLabel = intl.formatMessage({
        id: 'EditListingRegistrationForm.registrationExpiryMonthLabel',
      });
      // registration length label
      const registrationLengthLabel = intl.formatMessage({
        id: 'EditListingRegistrationForm.registrationLengthLabel',
      });
      // registration length validation
      const registrationLengthValidation = required(
        intl.formatMessage({
          id: 'EditListingRegistrationForm.registrationLengthValidation',
        })
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const registrationBodyOptions = findOptionsForSelectFilter('registrationBody', filterConfig);
      const registrationLengthOptions = findOptionsForSelectFilter(
        'registrationLength',
        filterConfig
      );
      const registrationBodies = {
        rbsli: [validRBSLI, '15****'],
        nrcpd: [validNRCPD, '1******'],
        srlpdc: [validSRLPDC, 'QSLI******'],
      };
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <div className={classNames(css.sectionContainer)}>
            <h2 className={css.sectionTitle}>
              <FormattedMessage id="EditListingRegistrationForm.registrationTitle" />
            </h2>
            <FieldSelect
              className={css.features}
              id="registration"
              name="registration"
              type="select"
              label={registrationLabel}
              intl={intl}
              validate={registrationStatusValidation}
            >
              <option disabled value="">
                Choose from the following...{' '}
              </option>
              {registrationOptions.map(c => (
                <option key={c.key} value={c.key}>
                  {c.label}
                </option>
              ))}
            </FieldSelect>

            <FieldTextInput
              className={css.features}
              type="text"
              id="qualificationDetails"
              name="qualificationDetails"
              label={qualificationDetailsLabel}
              placeholder={qualificationDetailsPlaceholder}
              minLength={10}
              validate={required(qualificationDetailsValidation)}
            />
            <p className={css.formInputHelperInfo}>
              <FormattedMessage id="EditListingRegistrationForm.qualificationDetailsTip" />
            </p>
          </div>
          <FieldSelect
            className={css.features}
            id="registrationBody"
            name="registrationBody"
            type="select"
            label={registrationBodyLabel}
            intl={intl}
            validate={registrationBodyValidation}
          >
            <option disabled value="">
              Choose from the following...{' '}
            </option>
            {registrationBodyOptions.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>
          {values.registrationBody && (
            <FieldTextInput
              className={css.features}
              type="text"
              id="registrationNumber"
              name="registrationNumber"
              label={registrationNumberLabel}
              placeholder={registrationNumberPlaceholder()}
              validate={registrationNumberValidation()}
            />
          )}
          <div className={classNames(css.sectionContainer)}>
            <h3 className={css.sectionTitle}>
              <FormattedMessage id="EditListingRegistrationForm.registrationExpiryLabel" />
            </h3>
            <div>
              <FieldSelect
                id="registrationExpiryMonth"
                name="registrationExpiryMonth"
                label={registrationExpiryMonthLabel}
                intl={intl}
                validate={required(registrationExpiryMonthValidation)}
              >
                <option disabled value="">
                  Choose a month...
                </option>
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
              </FieldSelect>
            </div>
          </div>
          <FieldSelect
            className={css.features}
            id="registrationLength"
            name="registrationLength"
            type="select"
            label={registrationLengthLabel}
            intl={intl}
            validate={registrationLengthValidation}
          >
            <option disabled value="">
              Choose from the following...{' '}
            </option>
            {registrationLengthOptions.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingRegistrationFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingRegistrationFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),

  registration: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  registrationBody: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  registrationNumber: string.isRequired,

  registrationExpiryMonth: string.isRequired,
  qualificationDetails: string.isRequired,
  registrationLength: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingRegistrationFormComponent);
