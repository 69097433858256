import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingRegistrationForm } from '../../forms';
import config from '../../config';

import css from './EditListingRegistrationPanel.module.css';

const EditListingRegistrationPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingRegistrationPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingRegistrationPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingRegistrationPanel.createListingTitle" />
  );

  const qualificationDetails = '';
  const registrationExpiryMonth = '';
  const registrationBodyOptions = findOptionsForSelectFilter(
    'registrationBody',
    config.custom.filters
  );
  const registrationOptions = findOptionsForSelectFilter('registration', config.custom.filters);
  const registrationNumber = '';
  const registrationLengthOptions = findOptionsForSelectFilter(
    'registrationLength',
    config.custom.filters
  );
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingRegistrationForm
        className={css.form}
        initialValues={{
          title,
          description,
          qualificationDetails: publicData.qualificationDetails,
          registrationBody: publicData.registrationBody,
          registration: publicData.registration,
          registrationLength: publicData.registrationLength,
          registrationNumber: publicData.registrationNumber,
          registrationExpiryMonth: publicData.registrationExpiryMonth,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            title,
            description,
            qualificationDetails,
            registration,
            registrationBody,
            registrationLength,
            registrationNumber,
            registrationExpiryMonth,
          } = values;
          const updateValues = {
            title: title.trim(),
            description,
            publicData: {
              qualificationDetails,
              registration,
              registrationBody,
              registrationLength,
              registrationNumber,
              registrationExpiryMonth,
              favouriteListingId: currentListing?.id?.uuid,
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        qualificationDetails={qualificationDetails}
        registrationOptions={registrationOptions}
        registrationBodyOptions={registrationBodyOptions}
        registrationNumber={registrationNumber}
        registrationExpiryMonth={registrationExpiryMonth}
        registrationLengthOptions={registrationLengthOptions}
      />
    </div>
  );
};

EditListingRegistrationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingRegistrationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingRegistrationPanel;
