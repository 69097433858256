import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const MenuIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootMenuIcon, className);

  return (
    <svg 
      className={classes}
      style={{fill: "none", stroke: "#000", strokeDasharray: ".0385 4.2391", strokeMiterlimit: "10", strokeLinejoin: "round", strokeLinecap:"round", strokeWidth:"2.4664"}}
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      xmlns="http://www.w3.org/2000/svg">
      <g>
        fillRule="evenodd"
        <path d="m-6.551 10.912c-2.684-.033 10.818.11 35.848.366"/>
        <path d="m-6.552 5.495c-2.684-.033 10.818.11 35.849.366"/>
        <path d="m-6.551 16.508c-2.684-.032 10.818.111 35.848.367"/>
      </g>
    </svg>
  );
};

const { string } = PropTypes;

MenuIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

MenuIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default MenuIcon;
