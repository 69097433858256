import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';

import { FieldSelect, FieldCheckboxGroup, Form, PrimaryButton } from '../../components';

import css from './PreferencesForm.module.css';

const identity = v => v;

class PreferencesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.submittedValues = {};
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            saveGenderError,
            currentUser,
            formId,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            values,
            filterConfig,
          } = fieldRenderProps;

          const { preferredGender } = values;
          const user = ensureCurrentUser(currentUser);
          const { profile } = user.attributes;
          const options = findOptionsForSelectFilter('genderPrefs', filterConfig);

          // if (!user.id) {
          //   return null;
          // }
          const preferredGenderLabel = intl.formatMessage({
            id: 'VideoConferencingForm.preferredSexLabel',
          });
          const preferredGenderPlaceholder = intl.formatMessage({
            id: 'VideoConferencingForm.preferredSexPlaceholder',
          });

          // fetch data values
          const privateData = profile.privateData || {};

          if (preferredGender) {
            const genderChanged = privateData.preferredGender !== preferredGender;
          }

          // generic error
          const isGenericError = saveGenderError;
          let genericError = null;

          if (isGenericError) {
            genericError = (
              <span className={css.error}>
                <FormattedMessage id="ContactDetailsForm.genericFailure" />
              </span>
            );
          }
          const classes = classNames(rootClassName || css.root, className);
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled = invalid || pristineSinceLastSubmit || inProgress;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.preferencesSection}>
                <FieldSelect
                  id="preferredGender"
                  name="preferredGender"
                  className={css.fieldRenderPropsieldSelect}
                  label={preferredGenderLabel}
                  placeholder={preferredGenderPlaceholder}
                >
                  <option disabled value="">
                    Choose either...
                  </option>
                  {config.custom.prefSexOptions.map(g => (
                    <option key={g.key} value={g.label}>
                      {g.label}
                    </option>
                  ))}
                </FieldSelect>{' '}
                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={pristineSinceLastSubmit}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="ContactDetailsForm.saveChanges" />
                </PrimaryButton>
              </div>
              {genericError}
            </Form>
          );
        }}
      />
    );
  }
}

PreferencesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  saveGenderError: null,
  inProgress: false,
  preferredGender: null,
  filterConfig: config.custom.filters,
};

const { bool, func, string } = PropTypes;

PreferencesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  saveGenderError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
  filterConfig: propTypes.filterConfig,
};

const PreferencesForm = compose(injectIntl)(PreferencesFormComponent);

PreferencesForm.displayName = 'PreferencesForm';

export default PreferencesForm;
