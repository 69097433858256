import React, { useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { array, bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import {
  ModalInMobile,
  Button,
  Modal,
  FieldCurrencyInput,
  Form,
  IconFavourites,
  NamedLink,
} from '../../components';
import { BookingTimeForm } from '../../forms';
import { Form as FinalForm } from 'react-final-form';
import { PrimaryButton, SecondaryButton } from '../Button/Button';
import { connect } from 'react-redux';
import {
  fetchCurrentUserListings,
  checkIfChosenListingAvailabilityMatchCurrentListing,
  submitOffer,
} from '../../containers/ListingPage/ListingPage.duck';
import { required } from '../../util/validators';
import routeConfiguration from '../../routeConfiguration';

import css from './BookingPanel.module.css';
import woopsHand from './helping-hand-woops.png';
// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const BookingPanel = props => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    requirementListing,
    communicationProfessional,
    fetchCurrentUserListingsError,
    currentUserListings,
    fetchCurrentUserListingsInProgress,
    onShowInterest,
    onCheckIfChosenListingAvailabilityMatchCurrentListing,
    userChosenListing,
    interestedListingSlotsError,
    interestedListingSlotsLoading,
    onSubmitOffer,
  } = props;

  const price = listing.attributes.price;
  const listingId = listing.id;
  const timeZone =
    listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  //const showBookingTimeForm = hasListingState && !isClosed;
  const showBookingTimeForm = null;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isBook = !!parse(location.search).book;

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit';

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  const handleClick = () => {
    setShow(true);
    onShowInterest();
  };
  const priceRequired = required(
    intl.formatMessage({
      id: 'EditListingPricingForm.priceRequired',
    })
  );

  const ModalContent = (
    <Modal
      isOpen={show}
      id="Interest Modal"
      name="Interest Modal"
      onClose={() => setShow(false)}
      onManageDisableScrolling={() => ({})}
    >
      <FinalForm
        onSubmit={console.log}
        onSubmitOffer={onSubmitOffer}
        render={formRenderProps => {
          const {
            className,
            disabled,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            updated,
            fetchErrors,
            values,
            onSubmitOffer,
          } = formRenderProps;
          return (
            <Form onSubmit={handleSubmit}>
              {fetchCurrentUserListingsInProgress && (
                <section>
                  <h3>Please be patient, while we load your bookable listings</h3>
                </section>
              )}
              {currentUserListings == null ? null : (
                <section>
                  <h3>Please choose a listing to match the availability</h3>
                  <select
                    name="listings"
                    onChange={e => {
                      onCheckIfChosenListingAvailabilityMatchCurrentListing(
                        e.target.value,
                        listingId.uuid
                      );
                    }}
                  >
                    <option value="" disabled selected>
                      Please choose a listing...
                    </option>
                    {currentUserListings?.map(l => (
                      <option key={l.id.uuid} value={l.id.uuid}>
                        {l.attributes.title}
                      </option>
                    ))}
                  </select>

                  {interestedListingSlotsLoading && (
                    <h4>Please wait while we are checking the availability criteria</h4>
                  )}
                  {interestedListingSlotsError && (
                    <h4 style={{ color: 'red' }}>{interestedListingSlotsError}</h4>
                  )}

                  {userChosenListing && (
                    <>
                      <div style={{ marginTop: '1rem' }}>
                        <FieldCurrencyInput
                          id="price"
                          name="price"
                          label="Enter Price"
                          placeholder="Enter Price"
                          currencyConfig={config.currencyConfig}
                          validate={priceRequired}
                        />
                      </div>
                      <div style={{ marginTop: '1rem' }}>
                        <PrimaryButton
                          type="button"
                          inProgress={loading}
                          disabled={!values?.price}
                          onClick={() => {
                            setLoading(true);
                            const { history } = props;
                            const routes = routeConfiguration();
                            const { price } = values;
                            if (!price) return;
                            // TODO: PASS PRICE TO SUBMIT OFFER AND SAVE IN TRANSITION
                            onSubmitOffer(listingId.uuid, price)
                              .then(txId => {
                                setShow(false);
                                setLoading(false);
                                // Redirect to OrderDetailsPage
                                console.log(txId);
                                history.push(`/order/${txId.uuid}/details`);
                              })
                              .catch(e => {
                                console.log(e);
                                setLoading(false);
                                // Ignore, error handling in duck file
                              });
                          }}
                        >
                          Submit Offer
                        </PrimaryButton>
                      </div>
                    </>
                  )}
                </section>
              )}

              {fetchCurrentUserListingsError ? (
                <section>
                  <h3>Error finding the listing</h3>
                </section>
              ) : null}
            </Form>
          );
        }}
      />
    </Modal>
  );

  const { publicData } = listing.attributes;
  const metadata = listing?.attributes?.metadata;

  const makeOfferButton =
    metadata &&
    publicData &&
    (metadata?.locked >= publicData?.interpretersNeeded ? (
      <div>
        <img src={woopsHand} alt="Signalise woops hand" className={css.helpingHand} />
        <h3>
          This booking may have been filled or removed. You cannot make an offer at this time.
        </h3>
      </div>
    ) : (
      <div>
        <PrimaryButton onClick={handleClick}>Make an Offer</PrimaryButton>
        <p>
          <FormattedMessage id="ListingPage.inclusiveOfferMessage"></FormattedMessage>
        </p>
      </div>
    ));

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {requirementListing ? null : (
          <div className={css.bookingHeading}>
            {/* <div className={css.desktopPriceContainer}>
              <div className={css.desktopPriceValue} title={priceTitle}>
                {formattedPrice}
              </div>
              <div className={css.desktopPerUnit}>
                <FormattedMessage id={unitTranslationKey} />
              </div>
            </div> */}
            <div className={css.bookingHeadingContainer}>
              {/*<h2 className={titleClasses}>{title}</h2>
              {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
              */}
              <h2 className={titleClasses}>Book a professional</h2>
              <p>
                You can create a booking and once approved this will go live on the site for
                professionals to see.
              </p>
              <p>
                If you like this professional, please make sure you add them to your favourites{' '}
                <IconFavourites className={classNames(css.highlightedIcon, css.favouriteIcon)} /> so
                we know you like to work with them.
              </p>
            </div>
            <NamedLink name="CreateRequirementPage" className={css.bookingButton}>
              <FormattedMessage id="TopbarDesktop.createRequirementListing" />
            </NamedLink>
          </div>
        )}
        {showBookingTimeForm
          ? requirementListing
            ? null
            : {
                /*<BookingTimeForm
              className={css.bookingForm}
              formId="BookingPanel"
              submitButtonWrapperClassName={css.submitButtonWrapper}
              unitType={unitType}
              onSubmit={onSubmit}
              price={price}
              listingId={listing.id}
              isOwnListing={isOwnListing}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
              endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
              timeZone={timeZone}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
            />*/
              }
          : null}
        {requirementListing && communicationProfessional && !isOwnListing && makeOfferButton}
        {ModalContent}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        {/* <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div> */}

        {showBookingTimeForm ? (
          <Button
            rootClassName={css.bookButton}
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
          >
            <FormattedMessage id="BookingPanel.ctaButtonMessage" />
          </Button>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
        <NamedLink name="CreateRequirementPage" className={css.bookButton}>
          <FormattedMessage id="TopbarDesktop.createRequirementListing" />
        </NamedLink>
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    ListingPage: {
      currentUserListings,
      fetchCurrentUserListingsInProgress,
      fetchCurrentUserListingsError,
      // interestStart,
      // interestEnd,
      interestedListingSlotsLoading,
      interestedListingSlotsError,
      userChosenListing,
    },
  } = state;
  return {
    // interestStart,
    // interestEnd,
    userChosenListing,
    currentUserListings,
    fetchCurrentUserListingsInProgress,
    fetchCurrentUserListingsError,
    interestedListingSlotsError,
    interestedListingSlotsLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onShowInterest: () => dispatch(fetchCurrentUserListings()),
    onCheckIfChosenListingAvailabilityMatchCurrentListing: (
      userChosenListingId,
      requirementListingId
    ) =>
      dispatch(
        checkIfChosenListingAvailabilityMatchCurrentListing(
          userChosenListingId,
          requirementListingId
        )
      ),
    onSubmitOffer: (listingId, price) => dispatch(submitOffer(listingId, price)),
  };
};

export default compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(BookingPanel);
