import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';
import data from '../../data/signalise.json';
import css from './EditListingDescriptionPanel.module.css';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { types } from '../../util/sdkLoader';
import { saveTelephone } from '../../containers/EditListingPage/EditListingPage.duck';
import { useDispatch } from 'react-redux';
const { LatLng } = types;

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
  } = props;
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(false);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData, privateData } = currentListing.attributes;
  const category = props?.match?.params?.category || publicData?.listingtype;
  const userTypes = currentUser.attributes.profile.privateData.userTypes;
  const dev = config.env != 'production';
  const emailVerified = dev || w(!!currentUser?.id && !!currentUser.attributes.emailVerified);
  const [initialValuesForForm, setInitialValuesForForm] = useState({});
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle =
    isPublished && category == 'requirement' ? (
      <FormattedMessage id="EditListingDescriptionPanel.editRequirementTitle" />
    ) : isPublished ? (
      <FormattedMessage
        id="EditListingDescriptionPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              <FormattedMessage id="EditListingDescriptionPanel.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : category == 'requirement' ? (
      <FormattedMessage id="EditListingDescriptionPanel.createRequirementTitle" />
    ) : (
      <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
    );

  const myGenderOptions = findOptionsForSelectFilter('myGender', config.custom.filters);
  const myEthnicityOptions = findOptionsForSelectFilter('myEthnicity', config.custom.filters);

  const subheading = (
    <FormattedMessage id="EditListingDescriptionPanel.createRequirementSubTitle" />
  );

  let initialValues = {};

  const getAddress = payload => {
    const currentUserPrivateData = currentUser?.attributes?.profile?.privateData;
    // spread values before submit in initialValues
    if (payload) {
      const {
        invoicingAddress: { address },
        invoicingOrigin,
      } = payload;

      return {
        search: address,
        selectedPlace: {
          address,
          origin: new LatLng(invoicingOrigin.lat, invoicingOrigin.lng),
        },
      };
    }

    if (privateData?.invoicingAddress) {
      const {
        invoicingAddress: { address },
        invoicingOrigin,
      } = privateData;
      return {
        search: address,
        selectedPlace: {
          address,
          origin: new LatLng(invoicingOrigin.lat, invoicingOrigin.lng),
        },
      };
    }

    if (currentUserPrivateData?.address) {
      const {
        address: { address },
        origin,
      } = currentUserPrivateData;

      return {
        search: address,
        selectedPlace: {
          address,
          origin: new LatLng(origin.lat, origin.lng),
        },
      };
    }

    return undefined;
  };

  if (category === 'requirement') {
    initialValues = {
      title: privateData?.title
        ? privateData?.title
        : `${currentUser?.attributes?.profile?.firstName} ${currentUser?.attributes?.profile?.lastName}`,
      listingtype: category,
      email: currentUser.attributes.email,
      companyNameOrOrganization:
        currentUser.attributes.profile.privateData?.companyNameOrOrganization,
      contractCheck: currentUser.attributes.profile.privateData?.contractCheck,
      contractDepartment: currentUser.attributes.profile.privateData?.contractDepartment,
      addressDepartment: currentUser.attributes.profile.privateData?.addressDepartment,
      description,
      invoicingAddress: getAddress(),
      ...initialValuesForForm,
    };
    if (userTypes.includes('contractCustomer') || userTypes.includes('customer')) {
      if (currentUser.attributes.profile.privateData.billing_code) {
        initialValues.tel = data.filter(
          a => a.code.trim() == currentUser.attributes.profile.privateData.billing_code.trim()
        )[0].phoneNumber;
      } else {
        initialValues.tel =
          privateData?.phoneNumber || currentUser.attributes.profile.privateData?.phoneNumber;
      }
    }
  } else {
    initialValues = {
      title,
      description,
      myGender: publicData.myGender,
      myEthnicity: publicData.myEthnicity,
      ethnicityConsent: publicData.ethnicityConsent,
      additionalQualifications: publicData.additionalQualifications,
    };
  }
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {category == 'requirement' && (
        <div>
          <p>
            Please note: this form is for a face to face booking. If you want to book the video
            service and have access via a contract, you will have a link in the top navigation bar.
            If not, please email{' '}
            <a href="mailto:bookings@signalise.coop">bookings@signalise.coop</a> to arrange access.
          </p>
          <h2 style={{ marginTop: '2rem' }}>{subheading}</h2>
        </div>
      )}
      <EditListingDescriptionForm
        className={css.form}
        category={category}
        userTypes={userTypes}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={async values => {
          const {
            title,
            description,
            tel,
            telImport,
            myGender,
            ethnicityConsent,
            invoicingAddress,
            myEthnicity,
            companyNameOrOrganization,
            contractDepartment,
            addressDepartment,
            contractCheck,
            secondaryEmail,
          } = values;
          let invoicingAddressLocation;

          if (invoicingAddress) {
            const {
              selectedPlace: { address, origin },
            } = invoicingAddress;
            const { lat, lng } = origin;

            invoicingAddressLocation = {
              invoicingAddress: {
                address,
                building: '',
              },
              invoicingOrigin: { lat, lng },
            };
          }
          // return;
          let updateValues;
          if (category == 'requirement') {
            updateValues = {
              title: 'Booking',
              description: publicData?.description ?? 'TBC',
              publicData: {
                listingtype: category,
              },
              privateData: {
                title: title.trim(),
                // invoicingAddress,
                ...invoicingAddressLocation,
                companyNameOrOrganization,
                contractDepartment,
                contractCheck,
                addressDepartment,
                secondaryEmail,
              },
            };
            if (userTypes.includes('contractCustomer') || userTypes.includes('customer')) {
              updateValues.privateData.phoneNumber = tel;
              if (telImport && telImport[0] == 'true') {
                setUpdating(true);
                try {
                  await dispatch(
                    saveTelephone({
                      privateData: {
                        tel,
                      },
                    })
                  );
                  setUpdating(false);
                } catch (e) {
                  setUpdating(false);
                }
              }
            }

            setInitialValuesForForm({
              ...updateValues,
              ...updateValues?.publicData,
              ...updateValues?.privateData,
              invoicingAddress: getAddress(invoicingAddressLocation),
              tel,
            });
          } else {
            updateValues = {
              title: title.trim(),
              description,
              publicData: { myGender, myEthnicity, ethnicityConsent, listingtype: 'listing' },
              privateData: {
                // Putting these 2 props on the booking itself facilitates `server/index` (and anything else)
                // doing work like pinging CRMs, without having to first do convoluted SDK operations.
                userEmail: currentUser.attributes.email,
                userEmailVerified: currentUser.attributes.emailVerified,
              },
            };
          }

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        emailVerified={emailVerified}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        myGenderOptions={myGenderOptions}
        myEthnicityOptions={myEthnicityOptions}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,
  category: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

const mapStateToProps = state => {
  return { currentUser: state.user.currentUser };
};
const mapDispatchToProps = dispatch => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(EditListingDescriptionPanel);
