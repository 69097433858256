import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, format, ...rest } = props;

  if (format === 'desktop') {
    return (
      <svg
	  	className={className}
	  	{...rest}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        width="180"
        color="#000000"
        x="0px"
        y="0px"
        viewBox="0 0 381.9855042 152.3309326"
      >
        <path
          d="M45.9151573,37.9198265c1.6370239,0,2.9680176-1.3309937,2.9680176-2.9679565
		c0-1.6370239-1.3309937-2.9689922-2.9680176-2.9689922c-1.6369629,0-2.9680176,1.3319683-2.9680176,2.9689922
		C42.9471397,36.5888329,44.2781944,37.9198265,45.9151573,37.9198265z M24.7914391,50.318325
		c1.6370239,0,2.9680176-1.3319702,2.9680176-2.9679565c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941
		c-1.6369629,0-2.9679565,1.3319702-2.9679565,2.9689941C21.8234825,48.9863548,23.1544762,50.318325,24.7914391,50.318325z
		M28.4182701,42.087368c1.6370239,0,2.9680176-1.3320312,2.9680176-2.9680176c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941
		s-2.9689941,1.3319702-2.9689941,2.9689941C25.449276,40.7553368,26.7812462,42.087368,28.4182701,42.087368z
		M36.4044762,37.9198265c1.6369629,0,2.9689941-1.3309937,2.9689941-2.9679565c0-1.6370239-1.3320312-2.9689922-2.9689941-2.9689922
		c-1.6370239,0-2.9680176,1.3319683-2.9680176,2.9689922C33.4364586,36.5888329,34.7674522,37.9198265,36.4044762,37.9198265z
		M28.9765587,52.4660301c-1.6369629,0-2.9680176,1.3309937-2.9680176,2.9680176s1.3310547,2.9689941,2.9680176,2.9689941
		c1.6370239,0,2.9680176-1.3319702,2.9680176-2.9689941S30.6135826,52.4660301,28.9765587,52.4660301z M24.0357628,83.5520325
		c-1.6370239,0-2.9680176,1.3320312-2.9680176,2.9689941c0,1.6370239,1.3309937,2.9680176,2.9680176,2.9680176
		s2.9680176-1.3309937,2.9680176-2.9680176C27.0037804,84.8840637,25.6727867,83.5520325,24.0357628,83.5520325z
		M30.2750816,90.1683655c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9680176
		c0,1.6369629,1.3319702,2.9689941,2.9689941,2.9689941c1.6369629,0,2.9679565-1.3320312,2.9679565-2.9689941
		C33.2430382,91.4993591,31.9120445,90.1683655,30.2750816,90.1683655z M24.8383751,77.7738342
		c0-1.6369629-1.3320312-2.9679565-2.9689941-2.9679565c-1.6370239,0-2.9680176,1.3309937-2.9680176,2.9679565
		c0,1.6370239,1.3309937,2.9689941,2.9680176,2.9689941C23.5063438,80.7428284,24.8383751,79.4108582,24.8383751,77.7738342z
		M52.0220909,72.9221497c1.6369629,0,2.9679565-1.3319702,2.9679565-2.9689941s-1.3309937-2.9680176-2.9679565-2.9680176
		c-1.6370239,0-2.9680176,1.3309937-2.9680176,2.9680176S50.385067,72.9221497,52.0220909,72.9221497z M76.6237793,42.8196068
		c0.0629883,0.0039673,0.125,0.0059814,0.1870117,0.0059814c1.5529785,0,2.8629761-1.2119751,2.9609985-2.782959
		c0.1019897-1.6330566-1.144043-3.0450439-2.7770386-3.1480103c-1.6369629-0.1090088-3.0459595,1.1439819-3.1480103,2.7769775
		c-0.0499878,0.7910156,0.2120361,1.5549927,0.7360229,2.1489868C75.1077881,42.4156151,75.8327637,42.7705956,76.6237793,42.8196068
		z M85.5423584,82.2381287c1.6370239,0,2.9680176-1.3309937,2.9680176-2.9679565
		c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941c-1.6369629,0-2.9680176,1.3319702-2.9680176,2.9689941
		C82.5743408,80.907135,83.9053955,82.2381287,85.5423584,82.2381287z M88.6015015,73.5603333
		c1.6369629,0,2.9689941-1.3309937,2.9689941-2.9679565c0-1.6370239-1.3320312-2.9689941-2.9689941-2.9689941
		c-1.6370239,0-2.9680176,1.3319702-2.9680176,2.9689941C85.6334839,72.2293396,86.9644775,73.5603333,88.6015015,73.5603333z
		M86.0802612,115.0860291c-0.0629883,0.7910156,0.1860352,1.559021,0.7000122,2.1630249
		c0.5150146,0.6030273,1.2329712,0.9699707,2.0230103,1.0319824c0.0799561,0.007019,0.1589966,0.0100098,0.2379761,0.0100098
		c1.5300293,0,2.8320312-1.1809692,2.9570312-2.7329712c0.1289673-1.6300049-1.092041-3.0640259-2.7230225-3.1950073
		C87.6372681,112.2350769,86.2102661,113.4560242,86.0802612,115.0860291z M73.7263184,55.6783714
		c0.0629883,0.0039673,0.1270142,0.0059814,0.1900024,0.0059814c0.7219849,0,1.4119873-0.2600098,1.9589844-0.742981
		c0.5950317-0.5240479,0.9500122-1.2490234,0.9990234-2.0400391c0.1030273-1.6329956-1.1430054-3.0449829-2.7769775-3.1480103
		c-1.6430054-0.1029663-3.0450439,1.144043-3.1480103,2.7770386c-0.0499878,0.7910156,0.2119751,1.5540161,0.7359619,2.1489868
		C72.2103271,55.2743797,72.9353027,55.6293602,73.7263184,55.6783714z M53.9027672,42.4170189
		c1.6370239,0,2.9689941-1.3319702,2.9689941-2.9679565c0-1.6370239-1.3319702-2.9689941-2.9689941-2.9689941
		s-2.9680176,1.3319702-2.9680176,2.9689941C50.9347496,41.0850487,52.2657433,42.4170189,53.9027672,42.4170189z
		M71.8449707,64.596344c0.0630493,0.0040283,0.1270142,0.0060425,0.1890259,0.0060425
		c0.723999,0,1.4140015-0.2600098,1.9609985-0.7420082c0.5939941-0.5250244,0.9489746-1.25,0.9979858-2.0410156
		c0.0500488-0.7919922-0.2119751-1.5549927-0.7359619-2.1500244c-0.5250244-0.5939941-1.2490234-0.9489746-2.0410156-0.9979858
		c-0.7940063-0.0490112-1.5540161,0.2109985-2.1489868,0.7360229c-0.5950317,0.5249634-0.9500122,1.2489624-0.9990234,2.0409546
		C68.9660034,63.0813255,70.2119751,64.4933777,71.8449707,64.596344z M94.1916504,86.7185364
		c0-1.6369629-1.3319702-2.9679565-2.9679565-2.9679565c-1.6370239,0-2.9690552,1.3309937-2.9690552,2.9679565
		c0,1.6370239,1.3320312,2.9680176,2.9690552,2.9680176C92.8596802,89.686554,94.1916504,88.3555603,94.1916504,86.7185364z
		M91.6474609,128.1963806c-1.6270142-0.1330566-3.065979,1.09198-3.1959839,2.7229614
		c-0.1290283,1.6329956,1.0930176,3.06604,2.723999,3.1959839c0.0800171,0.0060425,0.1599731,0.0090332,0.2399902,0.0090332
		c0.7039795,0,1.3790283-0.2479858,1.9210205-0.710022c0.6040039-0.5139771,0.9710083-1.2319946,1.0339966-2.0230103
		C94.4994507,129.7593689,93.2774658,128.3263245,91.6474609,128.1963806z M86.2144775,120.8857727
		c-1.6279907-0.1190186-3.0650024,1.0949707-3.1940308,2.7249756c-0.0619507,0.7910156,0.1860352,1.559021,0.7020264,2.1619873
		c0.5139771,0.6030273,1.2329712,0.9700317,2.0239868,1.0320435c0.0789795,0.0059814,0.1569824,0.0089722,0.2349854,0.0089722
		c1.5310059,0,2.8340454-1.1820068,2.9580078-2.7340088C89.0674438,122.4467468,87.845459,121.01474,86.2144775,120.8857727z
		M84.4064941,87.5279236c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565
		c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176
		C83.0744629,90.4959412,84.4064941,89.1649475,84.4064941,87.5279236z M99.4272461,110.2961731
		c0.118042-1.6329956-1.1119995-3.059021-2.7439575-3.1780396c-1.6480103-0.1279907-3.059021,1.1130371-3.177002,2.7440186
		c-0.0580444,0.7910156,0.1950073,1.5570068,0.7130127,2.15802c0.5189819,0.5999756,1.2399902,0.9619751,2.0299683,1.0189819
		c0.072998,0.0059814,0.1459961,0.0089722,0.2180176,0.0089722C98.005249,113.0471497,99.3112793,111.8551331,99.4272461,110.2961731
		z M107.4104004,105.3920593c0.1189575-1.6329956-1.1120605-3.059021-2.7440186-3.177002
		c-1.6260376-0.117981-3.0579834,1.1119995-3.177002,2.742981c-0.0579834,0.7910156,0.1950073,1.5570068,0.7130127,2.15802
		c0.5189819,0.5999756,1.2399902,0.9619751,2.0310059,1.0200195c0.072998,0.0050049,0.1449585,0.0079956,0.21698,0.0079956
		C105.9893799,108.1440735,107.2953491,106.9510803,107.4104004,105.3920593z M75.2400513,91.4533386
		c-1.6369629,0-2.9679565,1.3309937-2.9679565,2.9680176s1.3309937,2.9689941,2.9679565,2.9689941
		c1.6370239,0,2.9680176-1.3319702,2.9680176-2.9689941S76.8770752,91.4533386,75.2400513,91.4533386z M100.7059937,130.0115662
		c-1.6390381-0.1300049-3.0650024,1.09198-3.1950073,2.7229614c-0.0640259,0.7900391,0.1849976,1.559021,0.7000122,2.1620483
		c0.5139771,0.6040039,1.2329712,0.9709473,2.0229492,1.0339966c0.0800171,0.0059814,0.1589966,0.0089722,0.2369995,0.0089722
		c1.5300293,0,2.8330078-1.1809692,2.9580078-2.7329712c0.0629883-0.7900391-0.1859741-1.5580444-0.7000122-2.1620483
		C102.2139893,130.4415588,101.4959717,130.0745544,100.7059937,130.0115662z M68.2868042,85.9127502
		c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9680176c0,1.6369629,1.3319702,2.9689941,2.9689941,2.9689941
		c1.6369629,0,2.9679565-1.3320312,2.9679565-2.9689941C71.2547607,87.2437439,69.9237671,85.9127502,68.2868042,85.9127502z
		M48.1878624,89.8269348c-1.6370239,0-2.9680176,1.3309937-2.9680176,2.9680176s1.3309937,2.9689941,2.9680176,2.9689941
		s2.9689941-1.3319702,2.9689941-2.9689941S49.8248863,89.8269348,48.1878624,89.8269348z M54.9185753,83.6788635
		c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9680176c0,1.6369629,1.3319702,2.9689941,2.9689941,2.9689941
		c1.6369629,0,2.9680176-1.3320312,2.9680176-2.9689941C57.8865929,85.0108337,56.5555382,83.6788635,54.9185753,83.6788635z
		M47.2763634,65.1107483c0-1.6369667-1.3319702-2.9680214-2.9689941-2.9680214s-2.9680176,1.3310547-2.9680176,2.9680214
		c0,1.6370239,1.3309937,2.9680176,2.9680176,2.9680176S47.2763634,66.7477722,47.2763634,65.1107483z M56.9125938,74.6448669
		c-1.6370239,0-2.9680176,1.3309937-2.9680176,2.9680176c0,1.6369629,1.3309937,2.9689941,2.9680176,2.9689941
		c1.6369629,0,2.9679565-1.3320312,2.9679565-2.9689941C59.8805504,75.9758606,58.5495567,74.6448669,56.9125938,74.6448669z
		M70.0686035,67.8467712c-0.7870483-0.0480347-1.5540161,0.2119751-2.1490479,0.7369995
		c-0.5949707,0.5249634-0.9499512,1.2489624-0.9989624,2.039978c-0.0499878,0.7919922,0.2119751,1.5549927,0.7369995,2.1500244
		c0.5239868,0.5939941,1.2489624,0.9489746,2.039978,0.9979858c0.0629883,0.0039673,0.125,0.0059814,0.1870117,0.0059814
		c1.5529785,0,2.8629761-1.2119751,2.9619751-2.782959C72.9486084,69.3607483,71.7015991,67.948761,70.0686035,67.8467712z
		M39.1103477,61.0475731c0-1.6370239-1.3309937-2.9680176-2.9679565-2.9680176
		c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9680176c0,1.6369629,1.3319702,2.9679604,2.9689941,2.9679604
		C37.7793541,64.0155334,39.1103477,62.684536,39.1103477,61.0475731z M67.5507202,82.839447
		c0.0629883,0.0039673,0.125,0.0059814,0.1870117,0.0059814c1.5529785,0,2.8629761-1.2119751,2.9619751-2.782959
		c0.0490112-0.7910156-0.2130127-1.5540161-0.7379761-2.1490479c-0.5240479-0.5949707-1.2490234-0.9500122-2.0400391-0.9989624
		c-0.7869873-0.0490112-1.5539551,0.2119751-2.1489868,0.7369995c-0.5949707,0.5249634-0.9500122,1.2489624-0.9989624,2.039978
		c-0.0500488,0.7910156,0.2119751,1.5549927,0.7369995,2.1500244C66.034729,82.4354553,66.7597046,82.7904358,67.5507202,82.839447z
		M167.1036377,72.7063293c-1.644043-0.1450195-3.0720215,1.0720215-3.2130127,2.7030029
		c-0.1390381,1.6309814,1.072998,3.0709839,2.7039795,3.2119751c0.0859985,0.0079956,0.1729736,0.0110474,0.2600098,0.0110474
		c0.6959839,0,1.3649902-0.243042,1.9049683-0.6970215c0.6069946-0.5100098,0.9790039-1.2260132,1.0469971-2.0159912
		C169.9476318,74.2883606,168.7346191,72.8473206,167.1036377,72.7063293z M247.9038086,31.245512
		c0,1.637022,1.3319702,2.9680157,2.9689941,2.9680157c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680157
		c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565C249.2357788,28.2775555,247.9038086,29.6085491,247.9038086,31.245512z
		M272.5583496,42.8196068c0.0629883,0.0039673,0.125,0.0059814,0.1870117,0.0059814
		c1.5529785,0,2.8629761-1.2119751,2.9609985-2.782959c0.1019897-1.6330566-1.144043-3.0450439-2.7770386-3.1480103
		c-1.6369629-0.1090088-3.0459595,1.1439819-3.1480103,2.7769775c-0.0499878,0.7910156,0.2120361,1.5549927,0.7360229,2.1489868
		C271.0423584,42.4156151,271.767334,42.7705956,272.5583496,42.8196068z M267.7796021,64.596344
		c0.0630493,0.0040283,0.1270142,0.0060425,0.1890259,0.0060425c0.723999,0,1.4140015-0.2600098,1.9609985-0.7420082
		c0.5939941-0.5250244,0.9489746-1.25,0.9979858-2.0410156c0.0500488-0.7919922-0.2119751-1.5549927-0.7359619-2.1500244
		c-0.5250244-0.5939941-1.2490234-0.9489746-2.0410156-0.9979858c-0.7940063-0.0490112-1.5540161,0.2109985-2.1489868,0.7360229
		c-0.5950317,0.5249634-0.9500122,1.2489624-0.9990234,2.0409546
		C264.9006348,63.0813255,266.1466064,64.4933777,267.7796021,64.596344z M269.6609497,55.6783714
		c0.0629883,0.0039673,0.1270142,0.0059814,0.1900024,0.0059814c0.7219849,0,1.4119873-0.2600098,1.9589844-0.742981
		c0.5950317-0.5240479,0.9500122-1.2490234,0.9990234-2.0400391c0.1030273-1.6329956-1.1430054-3.0449829-2.7769775-3.1480103
		c-1.6430054-0.1029663-3.0450439,1.144043-3.1480103,2.7770386c-0.0499878,0.7910156,0.2119751,1.5540161,0.7359619,2.1489868
		C268.1449585,55.2743797,268.8699341,55.6293602,269.6609497,55.6783714z M265.6322021,73.771759
		c0.0629883,0.0039673,0.125,0.0059814,0.1870117,0.0059814c1.5529785,0,2.8629761-1.2119751,2.9619751-2.782959
		c0.1020508-1.6340332-1.1449585-3.0460205-2.7779541-3.1480103c-0.7870483-0.0480347-1.5540161,0.2119751-2.1490479,0.7369995
		c-0.5949707,0.5249634-0.9499512,1.2489624-0.9989624,2.039978c-0.0499878,0.7919922,0.2119751,1.5549927,0.7369995,2.1500244
		C264.1162109,73.3677673,264.8411865,73.7227478,265.6322021,73.771759z M253.8408203,40.6070213
		c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
		c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176C252.5087891,43.5750389,253.8408203,42.2440453,253.8408203,40.6070213z
		M248.8286133,25.8465862c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
		c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
		C245.8596191,24.5155926,247.1915894,25.8465862,248.8286133,25.8465862z M246.3317261,49.9684715
		c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
		c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565C247.6636963,47.000515,246.3317261,48.3315086,246.3317261,49.9684715z
		M337.5828247,70.5116272c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941
		c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176s2.9680176-1.3310547,2.9680176-2.9680176
		C340.5508423,71.8435974,339.2198486,70.5116272,337.5828247,70.5116272z M345.807251,54.8006248
		c1.6370239,0,2.9680176-1.3310547,2.9680176-2.9680176c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941
		s-2.9689941,1.3319702-2.9689941,2.9689941C342.8382568,53.4695702,344.1702271,54.8006248,345.807251,54.8006248z
		M353.8479004,58.4690819c1.6370239,0,2.9680176-1.3310547,2.9680176-2.9680176
		c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941s-2.9689941,1.3319702-2.9689941,2.9689941
		C350.8789062,57.1380272,352.2108765,58.4690819,353.8479004,58.4690819z M301.7613525,55.7982445
		c1.6370239,0,2.9680176-1.3310547,2.9680176-2.9680176c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941
		s-2.9689941,1.3319702-2.9689941,2.9689941C298.7923584,54.4671898,300.1243286,55.7982445,301.7613525,55.7982445z
		M337.7666016,58.2125511c1.6370239,0,2.9680176-1.3310547,2.9680176-2.9680176
		c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941s-2.9689941,1.3319702-2.9689941,2.9689941
		C334.7976074,56.8814964,336.1295776,58.2125511,337.7666016,58.2125511z M92.4302979,65.118866
		c1.6369629,0,2.9679565-1.3309975,2.9679565-2.9680214c0-1.6369629-1.3309937-2.9689941-2.9679565-2.9689941
		c-1.6370239,0-2.9690552,1.3320312-2.9690552,2.9689941C89.4612427,63.7878685,90.7932739,65.118866,92.4302979,65.118866z
		M241.2668457,29.5804119c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
		c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
		C238.2978516,28.2494183,239.6298218,29.5804119,241.2668457,29.5804119z M332.5537109,65.0363464
		c1.6370239,0,2.9680176-1.3310585,2.9680176-2.9680214c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941
		s-2.9689941,1.3319702-2.9689941,2.9689941C329.5847168,63.7052879,330.916687,65.0363464,332.5537109,65.0363464z
		M309.8894653,54.780117c1.6370239,0,2.9680176-1.3310547,2.9680176-2.9680176c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941
		s-2.9689941,1.3319702-2.9689941,2.9689941C306.9204712,53.4490623,308.2524414,54.780117,309.8894653,54.780117z
		M115.0828857,58.7219505c1.6369629,0,2.9679565-1.3319702,2.9679565-2.9680176
		c0-1.6369629-1.3309937-2.9689941-2.9679565-2.9689941c-1.6370239,0-2.9689941,1.3320312-2.9689941,2.9689941
		C112.1138916,57.3899803,113.4458618,58.7219505,115.0828857,58.7219505z M113.6306763,64.5538635
		c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176s2.9680176-1.3310547,2.9680176-2.9680176
		c0-1.6370277-1.3309937-2.968998-2.9680176-2.968998S113.6306763,62.9168358,113.6306763,64.5538635z M106.4343872,55.684536
		c1.6369629,0,2.9679565-1.3319702,2.9679565-2.9689941c0-1.6369629-1.3309937-2.9680176-2.9679565-2.9680176
		c-1.6370239,0-2.9689941,1.3310547-2.9689941,2.9680176C103.4653931,54.3525658,104.7973633,55.684536,106.4343872,55.684536z
		M108.248291,125.2361755c-1.6329956-0.1290283-3.0650024,1.09198-3.1950073,2.7229614
		c-0.0640259,0.7900391,0.1849976,1.559021,0.7000122,2.1619873c0.5139771,0.6040039,1.2329712,0.9710083,2.0229492,1.0340576
		c0.0800171,0.0059814,0.15802,0.0089722,0.2369995,0.0089722c1.5300293,0,2.8330078-1.1810303,2.9580078-2.7319946
		c0.0629883-0.7910156-0.1859741-1.559021-0.7000122-2.1630249
		C109.7562866,125.6661682,109.038269,125.2991638,108.248291,125.2361755z M98.0610962,89.7672424
		c-1.6370239,0-2.9690552,1.3320312-2.9690552,2.9689941c0,1.6370239,1.3320312,2.9680176,2.9690552,2.9680176
		c1.6369629,0,2.9679565-1.3309937,2.9679565-2.9680176C101.0290527,91.0992737,99.6980591,89.7672424,98.0610962,89.7672424z
		M164.6365967,66.0190125c-0.1389771,1.6310425,1.072998,3.0720215,2.7040405,3.2120361
		c0.0859985,0.0079956,0.1729736,0.0119629,0.2599487,0.0119629c0.6960449,0,1.3660278-0.242981,1.9050293-0.6969604
		c0.6069946-0.5109863,0.9790039-1.2269897,1.0469971-2.0170288c0.0679932-0.789978-0.1760254-1.55896-0.6870117-2.1660156
		c-0.5100098-0.6069984-1.2260132-0.9779701-2.0159912-1.0459633
		C166.2095947,63.1750145,164.7775879,64.3890076,164.6365967,66.0190125z M98.0610962,57.8534203
		c1.6369629,0,2.9679565-1.3309937,2.9679565-2.9679565c0-1.6370239-1.3309937-2.9689941-2.9679565-2.9689941
		c-1.6370239,0-2.9690552,1.3319702-2.9690552,2.9689941C95.092041,56.5224266,96.4240723,57.8534203,98.0610962,57.8534203z
		M105.869873,91.035553c1.6369629,0,2.9680176-1.3319702,2.9680176-2.9680176c0-1.6369629-1.3310547-2.9689941-2.9680176-2.9689941
		c-1.6370239,0-2.9680176,1.3320312-2.9680176,2.9689941C102.9018555,89.7035828,104.2328491,91.035553,105.869873,91.035553z
		M235.5097656,45.6245384c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
		c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
		C232.5407715,44.2935448,233.8727417,45.6245384,235.5097656,45.6245384z M237.7099609,37.2185936
		c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176c0-1.6369629-1.3320312-2.9679546-2.9680176-2.9679546
		c-1.6370239,0-2.9689941,1.3309917-2.9689941,2.9679546C234.7409668,35.8875999,236.072937,37.2185936,237.7099609,37.2185936z
		M233.5594482,54.8825951c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
		c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
		C230.5904541,53.5516014,231.9224243,54.8825951,233.5594482,54.8825951z M237.0057373,73.9111633
		c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
		c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565C238.3377075,70.9432068,237.0057373,72.2742004,237.0057373,73.9111633z
		M231.0065918,73.2529602c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
		c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
		C228.0375977,71.9219666,229.3695679,73.2529602,231.0065918,73.2529602z M193.4141235,58.2883568
		c1.6369629,0,2.9679565-1.3309937,2.9679565-2.9679565c0-1.6370239-1.3309937-2.9689941-2.9679565-2.9689941
		c-1.6370239,0-2.9690552,1.3319702-2.9690552,2.9689941C190.4450684,56.9573631,191.7770996,58.2883568,193.4141235,58.2883568z
		M231.8432617,63.8912621c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
		c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
		C228.8742676,62.5602684,230.2062378,63.8912621,231.8432617,63.8912621z M184.6491699,74.1889343
		c1.6369629,0,2.9689941-1.3309937,2.9689941-2.9679565c0-1.6370239-1.3320312-2.9689941-2.9689941-2.9689941
		c-1.6370239,0-2.9680176,1.3319702-2.9680176,2.9689941C181.6811523,72.8579407,183.012146,74.1889343,184.6491699,74.1889343z
		M39.2314415,91.9180603c-1.6369629,0-2.9679565,1.3319702-2.9679565,2.9689941s1.3309937,2.9680176,2.9679565,2.9680176
		c1.6370239,0,2.9680176-1.3309937,2.9680176-2.9680176S40.8684654,91.9180603,39.2314415,91.9180603z M286.1747437,69.5484314
		c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
		s2.9680176-1.3310547,2.9680176-2.9680176C289.1427612,70.8804016,287.8117676,69.5484314,286.1747437,69.5484314z
		M280.4579468,85.4016418c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941
		c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176s2.9680176-1.3310547,2.9680176-2.9680176
		C283.4259644,86.7336121,282.0949707,85.4016418,280.4579468,85.4016418z M283.1511841,77.3316345
		c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
		s2.9680176-1.3310547,2.9680176-2.9680176C286.1192017,78.6636047,284.788208,77.3316345,283.1511841,77.3316345z
		M273.911438,91.0448303c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
		c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
		C276.8794556,92.375824,275.5474243,91.0448303,273.911438,91.0448303z M268.3227539,84.3608704
		c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9680176c0,1.6369629,1.3319702,2.9689941,2.9689941,2.9689941
		c1.6369629,0,2.9679565-1.3320312,2.9679565-2.9689941C271.2907104,85.691864,269.9597168,84.3608704,268.3227539,84.3608704z
		M289.6937256,61.7651634c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.968998
		c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176s2.9680176-1.3310547,2.9680176-2.9680176
		C292.6617432,63.0971336,291.3307495,61.7651634,289.6937256,61.7651634z M298.0474243,63.3628807
		c-1.6370239,0-2.9689941,1.331974-2.9689941,2.968998c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
		s2.9680176-1.3310547,2.9680176-2.9680176C301.0154419,64.6948547,299.6844482,63.3628807,298.0474243,63.3628807z
		M298.1577759,58.0321922c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941s-2.9689941,1.3319702-2.9689941,2.9689941
		c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176S298.1577759,59.6691551,298.1577759,58.0321922z M297.0498047,87.8139954
		c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
		s2.9680176-1.3310547,2.9680176-2.9680176C300.0178223,89.1459656,298.6868286,87.8139954,297.0498047,87.8139954z
		M243.5435181,90.3044128c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
		c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
		C246.5115356,91.6354065,245.1795044,90.3044128,243.5435181,90.3044128z M237.2814331,84.0293884
		c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176
		c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176C240.2494507,85.3603821,238.9174194,84.0293884,237.2814331,84.0293884z
		M243.5435181,63.6391869c-1.6370239,0-2.9689941,1.3309975-2.9689941,2.9679604
		c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
		C246.5115356,64.9701843,245.1795044,63.6391869,243.5435181,63.6391869z M258.3974609,84.7539368
		c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176
		c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176C261.3654785,86.0849304,260.0334473,84.7539368,258.3974609,84.7539368z
		M266.4125977,79.8407898c0.0490112-0.7910156-0.2130127-1.5540161-0.7379761-2.1490479
		c-0.5240479-0.5949707-1.2490234-0.9500122-2.0400391-0.9989624c-0.7869873-0.0490112-1.5539551,0.2119751-2.1489868,0.7369995
		c-0.5949707,0.5249634-0.9500122,1.2489624-0.9989624,2.039978c-0.0500488,0.7910156,0.2119751,1.5549927,0.7369995,2.1500244
		c0.5239868,0.5939941,1.2489624,0.9489746,2.039978,0.9979858c0.0629883,0.0039673,0.125,0.0059814,0.1870117,0.0059814
		C265.0036011,82.6237488,266.3135986,81.4117737,266.4125977,79.8407898z M246.5120239,55.3198509
		c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176
		c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176C249.4800415,56.6508446,248.1480103,55.3198509,246.5120239,55.3198509z
		M252.1043701,91.0448303c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
		c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
		C255.0723877,92.375824,253.7403564,91.0448303,252.1043701,91.0448303z M349.1685181,74.8208313
		c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941s-2.9689941,1.3319702-2.9689941,2.9689941
		c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176S349.1685181,76.4577942,349.1685181,74.8208313z M348.2371826,91.044281
		c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
		s2.9680176-1.3310547,2.9680176-2.9680176C351.2052002,92.3762512,349.8742065,91.044281,348.2371826,91.044281z
		M235.5103149,79.599884c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565
		c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176
		C234.1782837,82.5679016,235.5103149,81.236908,235.5103149,79.599884z M306.4610596,72.714386
		c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941s-2.9689941,1.3319702-2.9689941,2.9689941
		c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176S306.4610596,74.3513489,306.4610596,72.714386z M339.9133911,89.7148132
		c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
		s2.9680176-1.3310547,2.9680176-2.9680176C342.8814087,91.0467834,341.550415,89.7148132,339.9133911,89.7148132z
		M111.3493652,116.629364c-0.7869873-0.0579834-1.5579834,0.1870117-2.1630249,0.7000122
		c-0.6029663,0.5149536-0.9699707,1.2329712-1.032959,2.0229492c-0.1290283,1.632019,1.0929565,3.0650024,2.723999,3.1960449
		c0.0789795,0.0059814,0.157959,0.0089722,0.2359619,0.0089722c1.5300293,0,2.8340454-1.1809692,2.9590454-2.7329712
		C114.201355,118.1913757,112.9793701,116.7583313,111.3493652,116.629364z M362.87146,82.9399109
		c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
		s2.9680176-1.3310547,2.9680176-2.9680176C365.8394775,84.2718811,364.5084839,82.9399109,362.87146,82.9399109z
		M333.9763794,83.7778015c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941
		c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176s2.9680176-1.3310547,2.9680176-2.9680176
		C336.944397,85.1097717,335.6134033,83.7778015,333.9763794,83.7778015z M356.0261841,88.0763245
		c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
		s2.9680176-1.3310547,2.9680176-2.9680176C358.9942017,89.4082947,357.663208,88.0763245,356.0261841,88.0763245z
		M357.2530518,71.8725891c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941s-2.9689941,1.3319702-2.9689941,2.9689941
		c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176S357.2530518,73.509552,357.2530518,71.8725891z M333.9758911,79.6003723
		c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941s-2.9689941,1.3319702-2.9689941,2.9689941
		c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176S333.9758911,81.2373352,333.9758911,79.6003723z M305.2220459,90.9169006
		c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
		s2.9680176-1.3310547,2.9680176-2.9680176C308.1900635,92.2488708,306.8590698,90.9169006,305.2220459,90.9169006z
		M313.3445435,87.8005066c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941
		c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176s2.9680176-1.3310547,2.9680176-2.9680176
		C316.312561,89.1324768,314.9815674,87.8005066,313.3445435,87.8005066z M313.9889526,75.7372131
		c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941s-2.9689941,1.3319702-2.9689941,2.9689941
		c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176S313.9889526,77.374176,313.9889526,75.7372131z M332.2734375,70.4013977
		c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941s-2.9689941,1.3319702-2.9689941,2.9689941
		c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176S332.2734375,72.0383606,332.2734375,70.4013977z M323.0586548,74.256073
		c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
		s2.9680176-1.3310547,2.9680176-2.9680176C326.0266724,75.5880432,324.6956787,74.256073,323.0586548,74.256073z
		M315.9927368,79.6759949c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941
		c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176s2.9680176-1.3310547,2.9680176-2.9680176
		C318.9607544,81.0079651,317.6297607,79.6759949,315.9927368,79.6759949z M357.4179688,60.5515404
		c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369667,1.3319702,2.9680214,2.9689941,2.9680214
		s2.9680176-1.3310547,2.9680176-2.9680214C360.3859863,61.8835106,359.0549927,60.5515404,357.4179688,60.5515404z
		M138.2457275,62.7278099c-1.6340332-0.1419678-3.0720215,1.0720215-3.2130127,2.7020302
		c-0.1400146,1.6309814,1.072998,3.0720215,2.7030029,3.2130127c0.0869751,0.0079956,0.1729736,0.0109863,0.2600098,0.0109863
		c0.6969604,0,1.3659668-0.242981,1.9060059-0.6970215c0.6069946-0.5100098,0.9790039-1.2259521,1.0459595-2.0169678
		c0.0679932-0.7890015-0.1749878-1.5579834-0.6859741-2.1650429
		C139.7507324,63.1668358,139.034729,62.7958031,138.2457275,62.7278099z M139.46521,53.3427391
		c-1.6300049-0.1450195-3.0720215,1.0720215-3.2130127,2.7030029c-0.0679932,0.7890015,0.1760254,1.5579834,0.6870117,2.164978
		c0.5100098,0.6080322,1.2269897,0.9790039,2.0159912,1.0469971c0.0870361,0.0079956,0.1740112,0.0109863,0.2609863,0.0109863
		c0.6970215,0,1.3650513-0.242981,1.9050293-0.6969604c0.6069946-0.5100098,0.9790039-1.2269897,1.0460205-2.0159912
		C142.3082275,54.9247093,141.0961914,53.4837303,139.46521,53.3427391z M137.232605,72.1060486
		c-1.6300049-0.1400146-3.0720215,1.0719604-3.2119751,2.7019653c-0.1409912,1.6310425,1.0719604,3.0720215,2.7030029,3.2130127
		c0.0869751,0.0079956,0.1729736,0.0109863,0.2589722,0.0109863c0.697998,0,1.3660278-0.242981,1.9060059-0.6969604
		c0.6069946-0.5100098,0.9790039-1.2260132,1.0469971-2.0170288c0.0679932-0.7890015-0.1760254-1.5579834-0.6870117-2.164978
		C138.7385864,72.5450134,138.0216064,72.1740417,137.232605,72.1060486z M227.5960083,84.0293884
		c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176
		c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176C230.5640259,85.3603821,229.2319946,84.0293884,227.5960083,84.0293884z
		M145.4650269,68.2629089c-0.7850342-0.0669556-1.5580444,0.1750488-2.1660156,0.6860352
		c-0.6069946,0.5109863-0.9780273,1.2269897-1.0460205,2.0159912c-0.0679932,0.7910156,0.1750488,1.5599976,0.6860352,2.1669922
		c0.5100098,0.6060181,1.2269897,0.9780273,2.0159912,1.0460205c0.0859985,0.0079956,0.1719971,0.0109863,0.257019,0.0109863
		c1.5229492,0,2.8219604-1.1690063,2.9559937-2.7139893c0.0679932-0.7890015-0.1760254-1.5579834-0.6870117-2.1650391
		C146.9710083,68.7019348,146.2550049,68.3309021,145.4650269,68.2629089z M157.2858276,54.3323021
		c-0.7880249-0.0699463-1.5580444,0.1760254-2.1660156,0.6860352c-0.6069946,0.5100098-0.9790039,1.2269897-1.0469971,2.0159912
		c-0.1400146,1.6309814,1.072998,3.0720215,2.7030029,3.2119751c0.0869751,0.0080566,0.1740112,0.0120239,0.2609863,0.0120239
		c0.6970215,0,1.3649902-0.242981,1.9050293-0.6970215c0.6069946-0.5099487,0.9779663-1.2259521,1.0469971-2.0169678
		C160.1287842,55.9133568,158.9158325,54.4723167,157.2858276,54.3323021z M136.7755127,90.9890442
		c-1.6110229-0.0140381-2.9940186,1.2739868-3.026001,2.9099731c-0.0150146,0.7940063,0.2789917,1.5450439,0.8289795,2.1160278
		c0.5490112,0.5709839,1.2890015,0.8939819,2.0820312,0.9099731h0.05896c0.7710571,0,1.4990234-0.2929688,2.0560303-0.8289795
		c0.5709839-0.5490112,0.8950195-1.2890015,0.9099731-2.0809937
		C139.7175293,92.3780212,138.411499,91.0210266,136.7755127,90.9890442z M151.4720459,61.2892113
		c-0.7850342-0.0679932-1.5580444,0.1760254-2.1660156,0.6860352c-0.6069946,0.5109863-0.9780273,1.2269897-1.0460205,2.0159912
		c-0.1409912,1.6309853,1.0720215,3.0719643,2.7020264,3.2119789c0.0859985,0.0079956,0.1719971,0.0120239,0.2579956,0.0120239
		c1.5219727,0,2.8219604-1.1699829,2.9549561-2.7139893C154.243042,63.7112083,154,62.943203,153.4890137,62.3362083
		C152.9790039,61.7282372,152.2620239,61.3572044,151.4720459,61.2892113z M121.8269653,94.5047302
		c-0.7890015-0.0650024-1.5579834,0.1860352-2.1619873,0.7000122c-0.6040039,0.5150146-0.9700317,1.2330322-1.03302,2.0230103
		s0.1849976,1.5579834,0.7000122,2.1619873c0.5139771,0.6040039,1.2329712,0.9700317,2.0230103,1.0339966
		c0.0789795,0.0060425,0.1589966,0.0090332,0.2369995,0.0090332c1.5299683,0,2.8330078-1.1820068,2.9580078-2.7330322
		c0.0629883-0.7909546-0.1860352-1.55896-0.7000122-2.1619873C123.3349609,94.9347839,122.6169434,94.5677795,121.8269653,94.5047302
		z M128.5025635,87.7298279c-1.6389771-0.131958-3.0650024,1.092041-3.1950073,2.7230225
		c-0.1300049,1.632019,1.09198,3.0650024,2.7230225,3.1959839c0.0799561,0.0060425,0.1599731,0.0090332,0.2390137,0.0090332
		c0.7049561,0,1.3799438-0.2479858,1.9219971-0.710022c0.6040039-0.5150146,0.9709473-1.2329712,1.0339966-2.0230103
		c0.0629883-0.7909546-0.1860352-1.55896-0.7000122-2.1619873C130.0105591,88.1598816,129.2925415,87.7928772,128.5025635,87.7298279
		z M112.2215576,107.4529724c-0.7890015-0.0620117-1.5579834,0.1859741-2.1619873,0.6999512
		c-0.6040039,0.5150146-0.9700317,1.2330322-1.03302,2.0230103c-0.0629883,0.7900391,0.1849976,1.5580444,0.7000122,2.1620483
		c0.5139771,0.6039429,1.2330322,0.9699707,2.0230103,1.0339966c0.0800171,0.0059814,0.15802,0.0089722,0.2369995,0.0089722
		c1.5299683,0,2.8330078-1.1820068,2.9580078-2.7329712C115.074585,109.0149231,113.8525391,107.5819397,112.2215576,107.4529724z
		M113.8723755,89.092926c-0.7930298,0.0280151-1.5269775,0.3640137-2.0670166,0.9440308
		c-0.539978,0.5800171-0.8219604,1.3359985-0.7940063,2.1279907c0.0280151,0.7930298,0.3630371,1.5270386,0.9429932,2.0670166
		c0.5540161,0.5159912,1.2680054,0.7960205,2.0200195,0.7960205c0.0369873,0,0.072998-0.0010376,0.1090088-0.0020142
		c1.6359863-0.0580444,2.9190063-1.4360352,2.8610229-3.0720215c-0.0280151-0.7919922-0.3630371-1.526001-0.9440308-2.0670166
		C115.4203491,89.3469543,114.6743774,89.0739441,113.8723755,89.092926z M113.2367554,98.2766418
		c-1.6329956-0.1329956-3.0650024,1.0930176-3.1950073,2.7230225c-0.0639648,0.789978,0.1849976,1.559021,0.7000122,2.1619873
		c0.5140381,0.6040039,1.2330322,0.9710083,2.0230103,1.0339966c0.0800171,0.0059814,0.15802,0.0090332,0.2369995,0.0090332
		c1.5300293,0,2.8330078-1.1820068,2.9580078-2.7330322c0.0629883-0.789978-0.1860352-1.5579834-0.7000122-2.1619873
		S114.0267944,98.3396301,113.2367554,98.2766418z M165.9518433,59.9329491
		c0.0879517,0.0079956,0.1739502,0.0109863,0.2609863,0.0109863c0.6969604,0,1.3659668-0.242981,1.9049683-0.6970215
		c0.6060181-0.5100098,0.9790039-1.2259521,1.0460205-2.0159912c0.0679932-0.789978-0.1749878-1.55896-0.6849976-2.1660156
		c-0.5109863-0.6069946-1.2269897-0.9790039-2.0180054-1.0469971c-0.7919922-0.072998-1.559021,0.1760254-2.164978,0.6870117
		c-0.6069946,0.5100098-0.9790039,1.2260132-1.0469971,2.0159912c-0.0680542,0.7900391,0.1759644,1.559021,0.6869507,2.1660156
		C164.4458008,59.4939232,165.1618042,59.8649559,165.9518433,59.9329491z M114.9360962,80.0805359
		c-0.7920532-0.0910034-1.5640259,0.1360474-2.1830444,0.632019c-0.6189575,0.4949951-1.0079956,1.2020264-1.0960083,1.9890137
		c-0.1809692,1.6270142,0.9949951,3.0979614,2.6220093,3.2789917c0.1119995,0.0130005,0.2230225,0.0189819,0.3339844,0.0189819
		c0.6700439,0,1.31604-0.2260132,1.8480225-0.6499634c0.6190186-0.4949951,1.0090332-1.2020264,1.0960083-1.9900513
		c0.0889893-0.7879639-0.1359863-1.5629883-0.632019-2.1819458
		C116.4300537,80.5575256,115.7240601,80.1685486,114.9360962,80.0805359z M115.9508667,70.9610291
		c-1.6240234-0.1829834-3.0969849,0.9960327-3.2789917,2.6220093c-0.1799927,1.6270142,0.9959717,3.0969849,2.6220093,3.2780151
		c0.1109619,0.0130005,0.2219849,0.0189819,0.3299561,0.0189819c1.4910278,0,2.7800293-1.125,2.9490356-2.6399536
		C118.7538452,72.6130676,117.5768433,71.1420593,115.9508667,70.9610291z M136.0958252,87.4096375h0.0579834
		c1.6099854,0,2.9359741-1.2940063,2.9680176-2.9100342c0.0149536-0.7929688-0.2789917-1.5439453-0.8290405-2.1159668
		c-0.5489502-0.5709839-1.2890015-0.8950195-2.0819702-0.9099731c-0.7850342-0.0170288-1.5430298,0.2789917-2.1149902,0.8289795
		c-0.5720215,0.5499878-0.8950195,1.2890015-0.9110107,2.0809937
		C133.1538086,86.0206604,134.4598389,87.377655,136.0958252,87.4096375z M212.4186401,70.4656677
		c-1.6240234-0.1829834-3.0969849,0.9960327-3.2789917,2.6220093c-0.1799927,1.6270142,0.9959717,3.0969849,2.6220093,3.2780151
		c0.1109619,0.0130005,0.2219849,0.0189819,0.3299561,0.0189819c1.4910278,0,2.7800293-1.125,2.9490356-2.6399536
		C215.2216187,72.1177063,214.0446167,70.646698,212.4186401,70.4656677z M203.8894043,85.4456482
		c-1.6370239,0-2.9680176,1.3320312-2.9680176,2.9689941c0,1.6360474,1.3309937,2.9680176,2.9680176,2.9680176
		c1.6369629,0,2.9680176-1.3319702,2.9680176-2.9680176C206.8574219,86.7776794,205.5263672,85.4456482,203.8894043,85.4456482z
		M211.147644,58.1644554c1.6369629,0,2.9679565-1.3319702,2.9679565-2.9680176c0-1.6369629-1.3309937-2.9689941-2.9679565-2.9689941
		c-1.6370239,0-2.9689941,1.3320312-2.9689941,2.9689941C208.1786499,56.8324852,209.5106201,58.1644554,211.147644,58.1644554z
		M166.4478149,87.9494324c1.5219727,0,2.8220215-1.1690063,2.9559937-2.7139893
		c0.1400146-1.6309814-1.072998-3.0709839-2.7030029-3.2120361c-0.7819824-0.0689697-1.5579834,0.1750488-2.164978,0.6860352
		s-0.9800415,1.2269897-1.0480347,2.0159912c-0.0679932,0.789978,0.1760254,1.5599976,0.6860352,2.1669922
		c0.5109863,0.6060181,1.2269897,0.9780273,2.0169678,1.0460205
		C166.2767944,87.9454041,166.3618164,87.9494324,166.4478149,87.9494324z M221.8516846,91.0448303
		c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176
		c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176C224.8197021,92.375824,223.4876709,91.0448303,221.8516846,91.0448303z
		M213.2189941,87.9503479c-0.7930298,0.0280151-1.5269775,0.3640137-2.0670166,0.9440308
		c-0.539978,0.5800171-0.8219604,1.3359985-0.7940063,2.1279907c0.0280151,0.7930298,0.3630371,1.5270386,0.9429932,2.0670166
		c0.5540161,0.5159912,1.2680054,0.7960205,2.0200195,0.7960205c0.0369873,0,0.072998-0.0010376,0.1090088-0.0020142
		c1.6359863-0.0580444,2.9190063-1.4360352,2.8610229-3.0720215c-0.0280151-0.7919922-0.3630371-1.526001-0.9440308-2.0670166
		C214.7669678,88.2043762,214.0209961,87.931366,213.2189941,87.9503479z M212.0080566,85.368988
		c0.6700439,0,1.31604-0.2260132,1.8480225-0.6499634c0.6190186-0.4949951,1.0090332-1.2020264,1.0960083-1.9900513
		c0.0889893-0.7879639-0.1359863-1.5629883-0.632019-2.1819458c-0.4949951-0.6200562-1.2009888-1.0090332-1.9889526-1.0970459
		c-0.7920532-0.0910034-1.5640259,0.1360474-2.1830444,0.632019c-0.6189575,0.4949951-1.0079956,1.2020264-1.0960083,1.9890137
		c-0.1809692,1.6270142,0.9949951,3.0979614,2.6220093,3.2789917
		C211.7860718,85.3630066,211.8970947,85.368988,212.0080566,85.368988z M211.9450073,67.5218811
		c1.6370239,0,2.9680176-1.3310547,2.9680176-2.9680176c0-1.6370277-1.3309937-2.968998-2.9680176-2.968998
		s-2.9689941,1.3319702-2.9689941,2.968998C208.9760132,66.1908264,210.3079834,67.5218811,211.9450073,67.5218811z
		M181.6919556,76.7598572c-1.6369629,0-2.9680176,1.3319702-2.9680176,2.9689941
		c0,1.6369629,1.3310547,2.9679565,2.9680176,2.9679565c1.6370239,0,2.9680176-1.3309937,2.9680176-2.9679565
		C184.6599731,78.0918274,183.3289795,76.7598572,181.6919556,76.7598572z M176.0013428,84.9814148
		c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176
		c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176C178.9693604,86.3124084,177.6373291,84.9814148,176.0013428,84.9814148z
		M168.8167725,90.9996643c-0.7849731-0.0689697-1.55896,0.1749878-2.1659546,0.6860352
		c-0.6060181,0.5109863-0.9790039,1.2269897-1.0460205,2.0159912c-0.0679932,0.7910156,0.1749878,1.5599976,0.6849976,2.1660156
		c0.5109863,0.6069946,1.2269897,0.9789429,2.0180054,1.0469971c0.0859985,0.0079956,0.1709595,0.0109863,0.2559814,0.0109863
		c1.5230103,0,2.822998-1.1690063,2.9570312-2.7139893C171.6607666,92.580658,170.4467773,91.139679,168.8167725,90.9996643z
		M187.6187134,83.7505798c-1.6370239,0-2.9690552,1.3309937-2.9690552,2.9679565
		c0,1.6370239,1.3320312,2.9680176,2.9690552,2.9680176c1.6359863,0,2.9679565-1.3309937,2.9679565-2.9680176
		C190.5866699,85.0815735,189.2546997,83.7505798,187.6187134,83.7505798z M195.4561157,89.0915222
		c-1.6370239,0-2.9690552,1.3320312-2.9690552,2.9689941c0,1.6370239,1.3320312,2.9680176,2.9690552,2.9680176
		c1.6369629,0,2.9679565-1.3309937,2.9679565-2.9680176C198.4240723,90.4235535,197.0930786,89.0915222,195.4561157,89.0915222z
		M204.9716797,52.7844505c0-1.6369629-1.3309937-2.9680176-2.9679565-2.9680176
		c-1.6370239,0-2.9689941,1.3310547-2.9689941,2.9680176c0,1.6370239,1.3319702,2.9689941,2.9689941,2.9689941
		C203.640686,55.7534447,204.9716797,54.4214745,204.9716797,52.7844505z M188.519104,59.9439354
		c-1.6370239,0-2.9690552,1.3320312-2.9690552,2.9689941c0,1.6370277,1.3320312,2.9680214,2.9690552,2.9680214
		c1.6369629,0,2.9679565-1.3309937,2.9679565-2.9680214C191.4870605,61.2759666,190.1560669,59.9439354,188.519104,59.9439354z"
        />
      </svg>
    );
  }

  return (
    <svg
      className={className}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      //   id="Layer_1"
      max-width="100"
      color="#000000"
      //   x="0px"
      //   y="0px"
      viewBox="0 0 381.9855042 152.3309326"
    >
      <path
        d="M45.9151573,37.9198265c1.6370239,0,2.9680176-1.3309937,2.9680176-2.9679565
	c0-1.6370239-1.3309937-2.9689922-2.9680176-2.9689922c-1.6369629,0-2.9680176,1.3319683-2.9680176,2.9689922
	C42.9471397,36.5888329,44.2781944,37.9198265,45.9151573,37.9198265z M24.7914391,50.318325
	c1.6370239,0,2.9680176-1.3319702,2.9680176-2.9679565c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941
	c-1.6369629,0-2.9679565,1.3319702-2.9679565,2.9689941C21.8234825,48.9863548,23.1544762,50.318325,24.7914391,50.318325z
	 M28.4182701,42.087368c1.6370239,0,2.9680176-1.3320312,2.9680176-2.9680176c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941
	s-2.9689941,1.3319702-2.9689941,2.9689941C25.449276,40.7553368,26.7812462,42.087368,28.4182701,42.087368z
	 M36.4044762,37.9198265c1.6369629,0,2.9689941-1.3309937,2.9689941-2.9679565c0-1.6370239-1.3320312-2.9689922-2.9689941-2.9689922
	c-1.6370239,0-2.9680176,1.3319683-2.9680176,2.9689922C33.4364586,36.5888329,34.7674522,37.9198265,36.4044762,37.9198265z
	 M28.9765587,52.4660301c-1.6369629,0-2.9680176,1.3309937-2.9680176,2.9680176s1.3310547,2.9689941,2.9680176,2.9689941
	c1.6370239,0,2.9680176-1.3319702,2.9680176-2.9689941S30.6135826,52.4660301,28.9765587,52.4660301z M24.0357628,83.5520325
	c-1.6370239,0-2.9680176,1.3320312-2.9680176,2.9689941c0,1.6370239,1.3309937,2.9680176,2.9680176,2.9680176
	s2.9680176-1.3309937,2.9680176-2.9680176C27.0037804,84.8840637,25.6727867,83.5520325,24.0357628,83.5520325z
	 M30.2750816,90.1683655c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9680176
	c0,1.6369629,1.3319702,2.9689941,2.9689941,2.9689941c1.6369629,0,2.9679565-1.3320312,2.9679565-2.9689941
	C33.2430382,91.4993591,31.9120445,90.1683655,30.2750816,90.1683655z M24.8383751,77.7738342
	c0-1.6369629-1.3320312-2.9679565-2.9689941-2.9679565c-1.6370239,0-2.9680176,1.3309937-2.9680176,2.9679565
	c0,1.6370239,1.3309937,2.9689941,2.9680176,2.9689941C23.5063438,80.7428284,24.8383751,79.4108582,24.8383751,77.7738342z
	 M52.0220909,72.9221497c1.6369629,0,2.9679565-1.3319702,2.9679565-2.9689941s-1.3309937-2.9680176-2.9679565-2.9680176
	c-1.6370239,0-2.9680176,1.3309937-2.9680176,2.9680176S50.385067,72.9221497,52.0220909,72.9221497z M76.6237793,42.8196068
	c0.0629883,0.0039673,0.125,0.0059814,0.1870117,0.0059814c1.5529785,0,2.8629761-1.2119751,2.9609985-2.782959
	c0.1019897-1.6330566-1.144043-3.0450439-2.7770386-3.1480103c-1.6369629-0.1090088-3.0459595,1.1439819-3.1480103,2.7769775
	c-0.0499878,0.7910156,0.2120361,1.5549927,0.7360229,2.1489868C75.1077881,42.4156151,75.8327637,42.7705956,76.6237793,42.8196068
	z M85.5423584,82.2381287c1.6370239,0,2.9680176-1.3309937,2.9680176-2.9679565
	c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941c-1.6369629,0-2.9680176,1.3319702-2.9680176,2.9689941
	C82.5743408,80.907135,83.9053955,82.2381287,85.5423584,82.2381287z M88.6015015,73.5603333
	c1.6369629,0,2.9689941-1.3309937,2.9689941-2.9679565c0-1.6370239-1.3320312-2.9689941-2.9689941-2.9689941
	c-1.6370239,0-2.9680176,1.3319702-2.9680176,2.9689941C85.6334839,72.2293396,86.9644775,73.5603333,88.6015015,73.5603333z
	 M86.0802612,115.0860291c-0.0629883,0.7910156,0.1860352,1.559021,0.7000122,2.1630249
	c0.5150146,0.6030273,1.2329712,0.9699707,2.0230103,1.0319824c0.0799561,0.007019,0.1589966,0.0100098,0.2379761,0.0100098
	c1.5300293,0,2.8320312-1.1809692,2.9570312-2.7329712c0.1289673-1.6300049-1.092041-3.0640259-2.7230225-3.1950073
	C87.6372681,112.2350769,86.2102661,113.4560242,86.0802612,115.0860291z M73.7263184,55.6783714
	c0.0629883,0.0039673,0.1270142,0.0059814,0.1900024,0.0059814c0.7219849,0,1.4119873-0.2600098,1.9589844-0.742981
	c0.5950317-0.5240479,0.9500122-1.2490234,0.9990234-2.0400391c0.1030273-1.6329956-1.1430054-3.0449829-2.7769775-3.1480103
	c-1.6430054-0.1029663-3.0450439,1.144043-3.1480103,2.7770386c-0.0499878,0.7910156,0.2119751,1.5540161,0.7359619,2.1489868
	C72.2103271,55.2743797,72.9353027,55.6293602,73.7263184,55.6783714z M53.9027672,42.4170189
	c1.6370239,0,2.9689941-1.3319702,2.9689941-2.9679565c0-1.6370239-1.3319702-2.9689941-2.9689941-2.9689941
	s-2.9680176,1.3319702-2.9680176,2.9689941C50.9347496,41.0850487,52.2657433,42.4170189,53.9027672,42.4170189z
	 M71.8449707,64.596344c0.0630493,0.0040283,0.1270142,0.0060425,0.1890259,0.0060425
	c0.723999,0,1.4140015-0.2600098,1.9609985-0.7420082c0.5939941-0.5250244,0.9489746-1.25,0.9979858-2.0410156
	c0.0500488-0.7919922-0.2119751-1.5549927-0.7359619-2.1500244c-0.5250244-0.5939941-1.2490234-0.9489746-2.0410156-0.9979858
	c-0.7940063-0.0490112-1.5540161,0.2109985-2.1489868,0.7360229c-0.5950317,0.5249634-0.9500122,1.2489624-0.9990234,2.0409546
	C68.9660034,63.0813255,70.2119751,64.4933777,71.8449707,64.596344z M94.1916504,86.7185364
	c0-1.6369629-1.3319702-2.9679565-2.9679565-2.9679565c-1.6370239,0-2.9690552,1.3309937-2.9690552,2.9679565
	c0,1.6370239,1.3320312,2.9680176,2.9690552,2.9680176C92.8596802,89.686554,94.1916504,88.3555603,94.1916504,86.7185364z
	 M91.6474609,128.1963806c-1.6270142-0.1330566-3.065979,1.09198-3.1959839,2.7229614
	c-0.1290283,1.6329956,1.0930176,3.06604,2.723999,3.1959839c0.0800171,0.0060425,0.1599731,0.0090332,0.2399902,0.0090332
	c0.7039795,0,1.3790283-0.2479858,1.9210205-0.710022c0.6040039-0.5139771,0.9710083-1.2319946,1.0339966-2.0230103
	C94.4994507,129.7593689,93.2774658,128.3263245,91.6474609,128.1963806z M86.2144775,120.8857727
	c-1.6279907-0.1190186-3.0650024,1.0949707-3.1940308,2.7249756c-0.0619507,0.7910156,0.1860352,1.559021,0.7020264,2.1619873
	c0.5139771,0.6030273,1.2329712,0.9700317,2.0239868,1.0320435c0.0789795,0.0059814,0.1569824,0.0089722,0.2349854,0.0089722
	c1.5310059,0,2.8340454-1.1820068,2.9580078-2.7340088C89.0674438,122.4467468,87.845459,121.01474,86.2144775,120.8857727z
	 M84.4064941,87.5279236c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565
	c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176
	C83.0744629,90.4959412,84.4064941,89.1649475,84.4064941,87.5279236z M99.4272461,110.2961731
	c0.118042-1.6329956-1.1119995-3.059021-2.7439575-3.1780396c-1.6480103-0.1279907-3.059021,1.1130371-3.177002,2.7440186
	c-0.0580444,0.7910156,0.1950073,1.5570068,0.7130127,2.15802c0.5189819,0.5999756,1.2399902,0.9619751,2.0299683,1.0189819
	c0.072998,0.0059814,0.1459961,0.0089722,0.2180176,0.0089722C98.005249,113.0471497,99.3112793,111.8551331,99.4272461,110.2961731
	z M107.4104004,105.3920593c0.1189575-1.6329956-1.1120605-3.059021-2.7440186-3.177002
	c-1.6260376-0.117981-3.0579834,1.1119995-3.177002,2.742981c-0.0579834,0.7910156,0.1950073,1.5570068,0.7130127,2.15802
	c0.5189819,0.5999756,1.2399902,0.9619751,2.0310059,1.0200195c0.072998,0.0050049,0.1449585,0.0079956,0.21698,0.0079956
	C105.9893799,108.1440735,107.2953491,106.9510803,107.4104004,105.3920593z M75.2400513,91.4533386
	c-1.6369629,0-2.9679565,1.3309937-2.9679565,2.9680176s1.3309937,2.9689941,2.9679565,2.9689941
	c1.6370239,0,2.9680176-1.3319702,2.9680176-2.9689941S76.8770752,91.4533386,75.2400513,91.4533386z M100.7059937,130.0115662
	c-1.6390381-0.1300049-3.0650024,1.09198-3.1950073,2.7229614c-0.0640259,0.7900391,0.1849976,1.559021,0.7000122,2.1620483
	c0.5139771,0.6040039,1.2329712,0.9709473,2.0229492,1.0339966c0.0800171,0.0059814,0.1589966,0.0089722,0.2369995,0.0089722
	c1.5300293,0,2.8330078-1.1809692,2.9580078-2.7329712c0.0629883-0.7900391-0.1859741-1.5580444-0.7000122-2.1620483
	C102.2139893,130.4415588,101.4959717,130.0745544,100.7059937,130.0115662z M68.2868042,85.9127502
	c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9680176c0,1.6369629,1.3319702,2.9689941,2.9689941,2.9689941
	c1.6369629,0,2.9679565-1.3320312,2.9679565-2.9689941C71.2547607,87.2437439,69.9237671,85.9127502,68.2868042,85.9127502z
	 M48.1878624,89.8269348c-1.6370239,0-2.9680176,1.3309937-2.9680176,2.9680176s1.3309937,2.9689941,2.9680176,2.9689941
	s2.9689941-1.3319702,2.9689941-2.9689941S49.8248863,89.8269348,48.1878624,89.8269348z M54.9185753,83.6788635
	c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9680176c0,1.6369629,1.3319702,2.9689941,2.9689941,2.9689941
	c1.6369629,0,2.9680176-1.3320312,2.9680176-2.9689941C57.8865929,85.0108337,56.5555382,83.6788635,54.9185753,83.6788635z
	 M47.2763634,65.1107483c0-1.6369667-1.3319702-2.9680214-2.9689941-2.9680214s-2.9680176,1.3310547-2.9680176,2.9680214
	c0,1.6370239,1.3309937,2.9680176,2.9680176,2.9680176S47.2763634,66.7477722,47.2763634,65.1107483z M56.9125938,74.6448669
	c-1.6370239,0-2.9680176,1.3309937-2.9680176,2.9680176c0,1.6369629,1.3309937,2.9689941,2.9680176,2.9689941
	c1.6369629,0,2.9679565-1.3320312,2.9679565-2.9689941C59.8805504,75.9758606,58.5495567,74.6448669,56.9125938,74.6448669z
	 M70.0686035,67.8467712c-0.7870483-0.0480347-1.5540161,0.2119751-2.1490479,0.7369995
	c-0.5949707,0.5249634-0.9499512,1.2489624-0.9989624,2.039978c-0.0499878,0.7919922,0.2119751,1.5549927,0.7369995,2.1500244
	c0.5239868,0.5939941,1.2489624,0.9489746,2.039978,0.9979858c0.0629883,0.0039673,0.125,0.0059814,0.1870117,0.0059814
	c1.5529785,0,2.8629761-1.2119751,2.9619751-2.782959C72.9486084,69.3607483,71.7015991,67.948761,70.0686035,67.8467712z
	 M39.1103477,61.0475731c0-1.6370239-1.3309937-2.9680176-2.9679565-2.9680176
	c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9680176c0,1.6369629,1.3319702,2.9679604,2.9689941,2.9679604
	C37.7793541,64.0155334,39.1103477,62.684536,39.1103477,61.0475731z M67.5507202,82.839447
	c0.0629883,0.0039673,0.125,0.0059814,0.1870117,0.0059814c1.5529785,0,2.8629761-1.2119751,2.9619751-2.782959
	c0.0490112-0.7910156-0.2130127-1.5540161-0.7379761-2.1490479c-0.5240479-0.5949707-1.2490234-0.9500122-2.0400391-0.9989624
	c-0.7869873-0.0490112-1.5539551,0.2119751-2.1489868,0.7369995c-0.5949707,0.5249634-0.9500122,1.2489624-0.9989624,2.039978
	c-0.0500488,0.7910156,0.2119751,1.5549927,0.7369995,2.1500244C66.034729,82.4354553,66.7597046,82.7904358,67.5507202,82.839447z
	 M167.1036377,72.7063293c-1.644043-0.1450195-3.0720215,1.0720215-3.2130127,2.7030029
	c-0.1390381,1.6309814,1.072998,3.0709839,2.7039795,3.2119751c0.0859985,0.0079956,0.1729736,0.0110474,0.2600098,0.0110474
	c0.6959839,0,1.3649902-0.243042,1.9049683-0.6970215c0.6069946-0.5100098,0.9790039-1.2260132,1.0469971-2.0159912
	C169.9476318,74.2883606,168.7346191,72.8473206,167.1036377,72.7063293z M247.9038086,31.245512
	c0,1.637022,1.3319702,2.9680157,2.9689941,2.9680157c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680157
	c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565C249.2357788,28.2775555,247.9038086,29.6085491,247.9038086,31.245512z
	 M272.5583496,42.8196068c0.0629883,0.0039673,0.125,0.0059814,0.1870117,0.0059814
	c1.5529785,0,2.8629761-1.2119751,2.9609985-2.782959c0.1019897-1.6330566-1.144043-3.0450439-2.7770386-3.1480103
	c-1.6369629-0.1090088-3.0459595,1.1439819-3.1480103,2.7769775c-0.0499878,0.7910156,0.2120361,1.5549927,0.7360229,2.1489868
	C271.0423584,42.4156151,271.767334,42.7705956,272.5583496,42.8196068z M267.7796021,64.596344
	c0.0630493,0.0040283,0.1270142,0.0060425,0.1890259,0.0060425c0.723999,0,1.4140015-0.2600098,1.9609985-0.7420082
	c0.5939941-0.5250244,0.9489746-1.25,0.9979858-2.0410156c0.0500488-0.7919922-0.2119751-1.5549927-0.7359619-2.1500244
	c-0.5250244-0.5939941-1.2490234-0.9489746-2.0410156-0.9979858c-0.7940063-0.0490112-1.5540161,0.2109985-2.1489868,0.7360229
	c-0.5950317,0.5249634-0.9500122,1.2489624-0.9990234,2.0409546
	C264.9006348,63.0813255,266.1466064,64.4933777,267.7796021,64.596344z M269.6609497,55.6783714
	c0.0629883,0.0039673,0.1270142,0.0059814,0.1900024,0.0059814c0.7219849,0,1.4119873-0.2600098,1.9589844-0.742981
	c0.5950317-0.5240479,0.9500122-1.2490234,0.9990234-2.0400391c0.1030273-1.6329956-1.1430054-3.0449829-2.7769775-3.1480103
	c-1.6430054-0.1029663-3.0450439,1.144043-3.1480103,2.7770386c-0.0499878,0.7910156,0.2119751,1.5540161,0.7359619,2.1489868
	C268.1449585,55.2743797,268.8699341,55.6293602,269.6609497,55.6783714z M265.6322021,73.771759
	c0.0629883,0.0039673,0.125,0.0059814,0.1870117,0.0059814c1.5529785,0,2.8629761-1.2119751,2.9619751-2.782959
	c0.1020508-1.6340332-1.1449585-3.0460205-2.7779541-3.1480103c-0.7870483-0.0480347-1.5540161,0.2119751-2.1490479,0.7369995
	c-0.5949707,0.5249634-0.9499512,1.2489624-0.9989624,2.039978c-0.0499878,0.7919922,0.2119751,1.5549927,0.7369995,2.1500244
	C264.1162109,73.3677673,264.8411865,73.7227478,265.6322021,73.771759z M253.8408203,40.6070213
	c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
	c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176C252.5087891,43.5750389,253.8408203,42.2440453,253.8408203,40.6070213z
	 M248.8286133,25.8465862c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
	c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
	C245.8596191,24.5155926,247.1915894,25.8465862,248.8286133,25.8465862z M246.3317261,49.9684715
	c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
	c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565C247.6636963,47.000515,246.3317261,48.3315086,246.3317261,49.9684715z
	 M337.5828247,70.5116272c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941
	c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176s2.9680176-1.3310547,2.9680176-2.9680176
	C340.5508423,71.8435974,339.2198486,70.5116272,337.5828247,70.5116272z M345.807251,54.8006248
	c1.6370239,0,2.9680176-1.3310547,2.9680176-2.9680176c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941
	s-2.9689941,1.3319702-2.9689941,2.9689941C342.8382568,53.4695702,344.1702271,54.8006248,345.807251,54.8006248z
	 M353.8479004,58.4690819c1.6370239,0,2.9680176-1.3310547,2.9680176-2.9680176
	c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941s-2.9689941,1.3319702-2.9689941,2.9689941
	C350.8789062,57.1380272,352.2108765,58.4690819,353.8479004,58.4690819z M301.7613525,55.7982445
	c1.6370239,0,2.9680176-1.3310547,2.9680176-2.9680176c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941
	s-2.9689941,1.3319702-2.9689941,2.9689941C298.7923584,54.4671898,300.1243286,55.7982445,301.7613525,55.7982445z
	 M337.7666016,58.2125511c1.6370239,0,2.9680176-1.3310547,2.9680176-2.9680176
	c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941s-2.9689941,1.3319702-2.9689941,2.9689941
	C334.7976074,56.8814964,336.1295776,58.2125511,337.7666016,58.2125511z M92.4302979,65.118866
	c1.6369629,0,2.9679565-1.3309975,2.9679565-2.9680214c0-1.6369629-1.3309937-2.9689941-2.9679565-2.9689941
	c-1.6370239,0-2.9690552,1.3320312-2.9690552,2.9689941C89.4612427,63.7878685,90.7932739,65.118866,92.4302979,65.118866z
	 M241.2668457,29.5804119c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
	c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
	C238.2978516,28.2494183,239.6298218,29.5804119,241.2668457,29.5804119z M332.5537109,65.0363464
	c1.6370239,0,2.9680176-1.3310585,2.9680176-2.9680214c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941
	s-2.9689941,1.3319702-2.9689941,2.9689941C329.5847168,63.7052879,330.916687,65.0363464,332.5537109,65.0363464z
	 M309.8894653,54.780117c1.6370239,0,2.9680176-1.3310547,2.9680176-2.9680176c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941
	s-2.9689941,1.3319702-2.9689941,2.9689941C306.9204712,53.4490623,308.2524414,54.780117,309.8894653,54.780117z
	 M115.0828857,58.7219505c1.6369629,0,2.9679565-1.3319702,2.9679565-2.9680176
	c0-1.6369629-1.3309937-2.9689941-2.9679565-2.9689941c-1.6370239,0-2.9689941,1.3320312-2.9689941,2.9689941
	C112.1138916,57.3899803,113.4458618,58.7219505,115.0828857,58.7219505z M113.6306763,64.5538635
	c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176s2.9680176-1.3310547,2.9680176-2.9680176
	c0-1.6370277-1.3309937-2.968998-2.9680176-2.968998S113.6306763,62.9168358,113.6306763,64.5538635z M106.4343872,55.684536
	c1.6369629,0,2.9679565-1.3319702,2.9679565-2.9689941c0-1.6369629-1.3309937-2.9680176-2.9679565-2.9680176
	c-1.6370239,0-2.9689941,1.3310547-2.9689941,2.9680176C103.4653931,54.3525658,104.7973633,55.684536,106.4343872,55.684536z
	 M108.248291,125.2361755c-1.6329956-0.1290283-3.0650024,1.09198-3.1950073,2.7229614
	c-0.0640259,0.7900391,0.1849976,1.559021,0.7000122,2.1619873c0.5139771,0.6040039,1.2329712,0.9710083,2.0229492,1.0340576
	c0.0800171,0.0059814,0.15802,0.0089722,0.2369995,0.0089722c1.5300293,0,2.8330078-1.1810303,2.9580078-2.7319946
	c0.0629883-0.7910156-0.1859741-1.559021-0.7000122-2.1630249
	C109.7562866,125.6661682,109.038269,125.2991638,108.248291,125.2361755z M98.0610962,89.7672424
	c-1.6370239,0-2.9690552,1.3320312-2.9690552,2.9689941c0,1.6370239,1.3320312,2.9680176,2.9690552,2.9680176
	c1.6369629,0,2.9679565-1.3309937,2.9679565-2.9680176C101.0290527,91.0992737,99.6980591,89.7672424,98.0610962,89.7672424z
	 M164.6365967,66.0190125c-0.1389771,1.6310425,1.072998,3.0720215,2.7040405,3.2120361
	c0.0859985,0.0079956,0.1729736,0.0119629,0.2599487,0.0119629c0.6960449,0,1.3660278-0.242981,1.9050293-0.6969604
	c0.6069946-0.5109863,0.9790039-1.2269897,1.0469971-2.0170288c0.0679932-0.789978-0.1760254-1.55896-0.6870117-2.1660156
	c-0.5100098-0.6069984-1.2260132-0.9779701-2.0159912-1.0459633
	C166.2095947,63.1750145,164.7775879,64.3890076,164.6365967,66.0190125z M98.0610962,57.8534203
	c1.6369629,0,2.9679565-1.3309937,2.9679565-2.9679565c0-1.6370239-1.3309937-2.9689941-2.9679565-2.9689941
	c-1.6370239,0-2.9690552,1.3319702-2.9690552,2.9689941C95.092041,56.5224266,96.4240723,57.8534203,98.0610962,57.8534203z
	 M105.869873,91.035553c1.6369629,0,2.9680176-1.3319702,2.9680176-2.9680176c0-1.6369629-1.3310547-2.9689941-2.9680176-2.9689941
	c-1.6370239,0-2.9680176,1.3320312-2.9680176,2.9689941C102.9018555,89.7035828,104.2328491,91.035553,105.869873,91.035553z
	 M235.5097656,45.6245384c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
	c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
	C232.5407715,44.2935448,233.8727417,45.6245384,235.5097656,45.6245384z M237.7099609,37.2185936
	c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176c0-1.6369629-1.3320312-2.9679546-2.9680176-2.9679546
	c-1.6370239,0-2.9689941,1.3309917-2.9689941,2.9679546C234.7409668,35.8875999,236.072937,37.2185936,237.7099609,37.2185936z
	 M233.5594482,54.8825951c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
	c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
	C230.5904541,53.5516014,231.9224243,54.8825951,233.5594482,54.8825951z M237.0057373,73.9111633
	c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
	c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565C238.3377075,70.9432068,237.0057373,72.2742004,237.0057373,73.9111633z
	 M231.0065918,73.2529602c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
	c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
	C228.0375977,71.9219666,229.3695679,73.2529602,231.0065918,73.2529602z M193.4141235,58.2883568
	c1.6369629,0,2.9679565-1.3309937,2.9679565-2.9679565c0-1.6370239-1.3309937-2.9689941-2.9679565-2.9689941
	c-1.6370239,0-2.9690552,1.3319702-2.9690552,2.9689941C190.4450684,56.9573631,191.7770996,58.2883568,193.4141235,58.2883568z
	 M231.8432617,63.8912621c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
	c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
	C228.8742676,62.5602684,230.2062378,63.8912621,231.8432617,63.8912621z M184.6491699,74.1889343
	c1.6369629,0,2.9689941-1.3309937,2.9689941-2.9679565c0-1.6370239-1.3320312-2.9689941-2.9689941-2.9689941
	c-1.6370239,0-2.9680176,1.3319702-2.9680176,2.9689941C181.6811523,72.8579407,183.012146,74.1889343,184.6491699,74.1889343z
	 M39.2314415,91.9180603c-1.6369629,0-2.9679565,1.3319702-2.9679565,2.9689941s1.3309937,2.9680176,2.9679565,2.9680176
	c1.6370239,0,2.9680176-1.3309937,2.9680176-2.9680176S40.8684654,91.9180603,39.2314415,91.9180603z M286.1747437,69.5484314
	c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
	s2.9680176-1.3310547,2.9680176-2.9680176C289.1427612,70.8804016,287.8117676,69.5484314,286.1747437,69.5484314z
	 M280.4579468,85.4016418c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941
	c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176s2.9680176-1.3310547,2.9680176-2.9680176
	C283.4259644,86.7336121,282.0949707,85.4016418,280.4579468,85.4016418z M283.1511841,77.3316345
	c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
	s2.9680176-1.3310547,2.9680176-2.9680176C286.1192017,78.6636047,284.788208,77.3316345,283.1511841,77.3316345z
	 M273.911438,91.0448303c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
	c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
	C276.8794556,92.375824,275.5474243,91.0448303,273.911438,91.0448303z M268.3227539,84.3608704
	c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9680176c0,1.6369629,1.3319702,2.9689941,2.9689941,2.9689941
	c1.6369629,0,2.9679565-1.3320312,2.9679565-2.9689941C271.2907104,85.691864,269.9597168,84.3608704,268.3227539,84.3608704z
	 M289.6937256,61.7651634c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.968998
	c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176s2.9680176-1.3310547,2.9680176-2.9680176
	C292.6617432,63.0971336,291.3307495,61.7651634,289.6937256,61.7651634z M298.0474243,63.3628807
	c-1.6370239,0-2.9689941,1.331974-2.9689941,2.968998c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
	s2.9680176-1.3310547,2.9680176-2.9680176C301.0154419,64.6948547,299.6844482,63.3628807,298.0474243,63.3628807z
	 M298.1577759,58.0321922c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941s-2.9689941,1.3319702-2.9689941,2.9689941
	c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176S298.1577759,59.6691551,298.1577759,58.0321922z M297.0498047,87.8139954
	c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
	s2.9680176-1.3310547,2.9680176-2.9680176C300.0178223,89.1459656,298.6868286,87.8139954,297.0498047,87.8139954z
	 M243.5435181,90.3044128c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
	c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
	C246.5115356,91.6354065,245.1795044,90.3044128,243.5435181,90.3044128z M237.2814331,84.0293884
	c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176
	c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176C240.2494507,85.3603821,238.9174194,84.0293884,237.2814331,84.0293884z
	 M243.5435181,63.6391869c-1.6370239,0-2.9689941,1.3309975-2.9689941,2.9679604
	c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
	C246.5115356,64.9701843,245.1795044,63.6391869,243.5435181,63.6391869z M258.3974609,84.7539368
	c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176
	c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176C261.3654785,86.0849304,260.0334473,84.7539368,258.3974609,84.7539368z
	 M266.4125977,79.8407898c0.0490112-0.7910156-0.2130127-1.5540161-0.7379761-2.1490479
	c-0.5240479-0.5949707-1.2490234-0.9500122-2.0400391-0.9989624c-0.7869873-0.0490112-1.5539551,0.2119751-2.1489868,0.7369995
	c-0.5949707,0.5249634-0.9500122,1.2489624-0.9989624,2.039978c-0.0500488,0.7910156,0.2119751,1.5549927,0.7369995,2.1500244
	c0.5239868,0.5939941,1.2489624,0.9489746,2.039978,0.9979858c0.0629883,0.0039673,0.125,0.0059814,0.1870117,0.0059814
	C265.0036011,82.6237488,266.3135986,81.4117737,266.4125977,79.8407898z M246.5120239,55.3198509
	c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176
	c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176C249.4800415,56.6508446,248.1480103,55.3198509,246.5120239,55.3198509z
	 M252.1043701,91.0448303c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565
	c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176
	C255.0723877,92.375824,253.7403564,91.0448303,252.1043701,91.0448303z M349.1685181,74.8208313
	c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941s-2.9689941,1.3319702-2.9689941,2.9689941
	c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176S349.1685181,76.4577942,349.1685181,74.8208313z M348.2371826,91.044281
	c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
	s2.9680176-1.3310547,2.9680176-2.9680176C351.2052002,92.3762512,349.8742065,91.044281,348.2371826,91.044281z
	 M235.5103149,79.599884c0-1.6369629-1.3320312-2.9679565-2.9680176-2.9679565
	c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176
	C234.1782837,82.5679016,235.5103149,81.236908,235.5103149,79.599884z M306.4610596,72.714386
	c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941s-2.9689941,1.3319702-2.9689941,2.9689941
	c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176S306.4610596,74.3513489,306.4610596,72.714386z M339.9133911,89.7148132
	c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
	s2.9680176-1.3310547,2.9680176-2.9680176C342.8814087,91.0467834,341.550415,89.7148132,339.9133911,89.7148132z
	 M111.3493652,116.629364c-0.7869873-0.0579834-1.5579834,0.1870117-2.1630249,0.7000122
	c-0.6029663,0.5149536-0.9699707,1.2329712-1.032959,2.0229492c-0.1290283,1.632019,1.0929565,3.0650024,2.723999,3.1960449
	c0.0789795,0.0059814,0.157959,0.0089722,0.2359619,0.0089722c1.5300293,0,2.8340454-1.1809692,2.9590454-2.7329712
	C114.201355,118.1913757,112.9793701,116.7583313,111.3493652,116.629364z M362.87146,82.9399109
	c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
	s2.9680176-1.3310547,2.9680176-2.9680176C365.8394775,84.2718811,364.5084839,82.9399109,362.87146,82.9399109z
	 M333.9763794,83.7778015c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941
	c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176s2.9680176-1.3310547,2.9680176-2.9680176
	C336.944397,85.1097717,335.6134033,83.7778015,333.9763794,83.7778015z M356.0261841,88.0763245
	c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
	s2.9680176-1.3310547,2.9680176-2.9680176C358.9942017,89.4082947,357.663208,88.0763245,356.0261841,88.0763245z
	 M357.2530518,71.8725891c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941s-2.9689941,1.3319702-2.9689941,2.9689941
	c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176S357.2530518,73.509552,357.2530518,71.8725891z M333.9758911,79.6003723
	c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941s-2.9689941,1.3319702-2.9689941,2.9689941
	c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176S333.9758911,81.2373352,333.9758911,79.6003723z M305.2220459,90.9169006
	c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
	s2.9680176-1.3310547,2.9680176-2.9680176C308.1900635,92.2488708,306.8590698,90.9169006,305.2220459,90.9169006z
	 M313.3445435,87.8005066c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941
	c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176s2.9680176-1.3310547,2.9680176-2.9680176
	C316.312561,89.1324768,314.9815674,87.8005066,313.3445435,87.8005066z M313.9889526,75.7372131
	c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941s-2.9689941,1.3319702-2.9689941,2.9689941
	c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176S313.9889526,77.374176,313.9889526,75.7372131z M332.2734375,70.4013977
	c0-1.6370239-1.3309937-2.9689941-2.9680176-2.9689941s-2.9689941,1.3319702-2.9689941,2.9689941
	c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176S332.2734375,72.0383606,332.2734375,70.4013977z M323.0586548,74.256073
	c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176
	s2.9680176-1.3310547,2.9680176-2.9680176C326.0266724,75.5880432,324.6956787,74.256073,323.0586548,74.256073z
	 M315.9927368,79.6759949c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941
	c0,1.6369629,1.3319702,2.9680176,2.9689941,2.9680176s2.9680176-1.3310547,2.9680176-2.9680176
	C318.9607544,81.0079651,317.6297607,79.6759949,315.9927368,79.6759949z M357.4179688,60.5515404
	c-1.6370239,0-2.9689941,1.3319702-2.9689941,2.9689941c0,1.6369667,1.3319702,2.9680214,2.9689941,2.9680214
	s2.9680176-1.3310547,2.9680176-2.9680214C360.3859863,61.8835106,359.0549927,60.5515404,357.4179688,60.5515404z
	 M138.2457275,62.7278099c-1.6340332-0.1419678-3.0720215,1.0720215-3.2130127,2.7020302
	c-0.1400146,1.6309814,1.072998,3.0720215,2.7030029,3.2130127c0.0869751,0.0079956,0.1729736,0.0109863,0.2600098,0.0109863
	c0.6969604,0,1.3659668-0.242981,1.9060059-0.6970215c0.6069946-0.5100098,0.9790039-1.2259521,1.0459595-2.0169678
	c0.0679932-0.7890015-0.1749878-1.5579834-0.6859741-2.1650429
	C139.7507324,63.1668358,139.034729,62.7958031,138.2457275,62.7278099z M139.46521,53.3427391
	c-1.6300049-0.1450195-3.0720215,1.0720215-3.2130127,2.7030029c-0.0679932,0.7890015,0.1760254,1.5579834,0.6870117,2.164978
	c0.5100098,0.6080322,1.2269897,0.9790039,2.0159912,1.0469971c0.0870361,0.0079956,0.1740112,0.0109863,0.2609863,0.0109863
	c0.6970215,0,1.3650513-0.242981,1.9050293-0.6969604c0.6069946-0.5100098,0.9790039-1.2269897,1.0460205-2.0159912
	C142.3082275,54.9247093,141.0961914,53.4837303,139.46521,53.3427391z M137.232605,72.1060486
	c-1.6300049-0.1400146-3.0720215,1.0719604-3.2119751,2.7019653c-0.1409912,1.6310425,1.0719604,3.0720215,2.7030029,3.2130127
	c0.0869751,0.0079956,0.1729736,0.0109863,0.2589722,0.0109863c0.697998,0,1.3660278-0.242981,1.9060059-0.6969604
	c0.6069946-0.5100098,0.9790039-1.2260132,1.0469971-2.0170288c0.0679932-0.7890015-0.1760254-1.5579834-0.6870117-2.164978
	C138.7385864,72.5450134,138.0216064,72.1740417,137.232605,72.1060486z M227.5960083,84.0293884
	c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176
	c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176C230.5640259,85.3603821,229.2319946,84.0293884,227.5960083,84.0293884z
	 M145.4650269,68.2629089c-0.7850342-0.0669556-1.5580444,0.1750488-2.1660156,0.6860352
	c-0.6069946,0.5109863-0.9780273,1.2269897-1.0460205,2.0159912c-0.0679932,0.7910156,0.1750488,1.5599976,0.6860352,2.1669922
	c0.5100098,0.6060181,1.2269897,0.9780273,2.0159912,1.0460205c0.0859985,0.0079956,0.1719971,0.0109863,0.257019,0.0109863
	c1.5229492,0,2.8219604-1.1690063,2.9559937-2.7139893c0.0679932-0.7890015-0.1760254-1.5579834-0.6870117-2.1650391
	C146.9710083,68.7019348,146.2550049,68.3309021,145.4650269,68.2629089z M157.2858276,54.3323021
	c-0.7880249-0.0699463-1.5580444,0.1760254-2.1660156,0.6860352c-0.6069946,0.5100098-0.9790039,1.2269897-1.0469971,2.0159912
	c-0.1400146,1.6309814,1.072998,3.0720215,2.7030029,3.2119751c0.0869751,0.0080566,0.1740112,0.0120239,0.2609863,0.0120239
	c0.6970215,0,1.3649902-0.242981,1.9050293-0.6970215c0.6069946-0.5099487,0.9779663-1.2259521,1.0469971-2.0169678
	C160.1287842,55.9133568,158.9158325,54.4723167,157.2858276,54.3323021z M136.7755127,90.9890442
	c-1.6110229-0.0140381-2.9940186,1.2739868-3.026001,2.9099731c-0.0150146,0.7940063,0.2789917,1.5450439,0.8289795,2.1160278
	c0.5490112,0.5709839,1.2890015,0.8939819,2.0820312,0.9099731h0.05896c0.7710571,0,1.4990234-0.2929688,2.0560303-0.8289795
	c0.5709839-0.5490112,0.8950195-1.2890015,0.9099731-2.0809937
	C139.7175293,92.3780212,138.411499,91.0210266,136.7755127,90.9890442z M151.4720459,61.2892113
	c-0.7850342-0.0679932-1.5580444,0.1760254-2.1660156,0.6860352c-0.6069946,0.5109863-0.9780273,1.2269897-1.0460205,2.0159912
	c-0.1409912,1.6309853,1.0720215,3.0719643,2.7020264,3.2119789c0.0859985,0.0079956,0.1719971,0.0120239,0.2579956,0.0120239
	c1.5219727,0,2.8219604-1.1699829,2.9549561-2.7139893C154.243042,63.7112083,154,62.943203,153.4890137,62.3362083
	C152.9790039,61.7282372,152.2620239,61.3572044,151.4720459,61.2892113z M121.8269653,94.5047302
	c-0.7890015-0.0650024-1.5579834,0.1860352-2.1619873,0.7000122c-0.6040039,0.5150146-0.9700317,1.2330322-1.03302,2.0230103
	s0.1849976,1.5579834,0.7000122,2.1619873c0.5139771,0.6040039,1.2329712,0.9700317,2.0230103,1.0339966
	c0.0789795,0.0060425,0.1589966,0.0090332,0.2369995,0.0090332c1.5299683,0,2.8330078-1.1820068,2.9580078-2.7330322
	c0.0629883-0.7909546-0.1860352-1.55896-0.7000122-2.1619873C123.3349609,94.9347839,122.6169434,94.5677795,121.8269653,94.5047302
	z M128.5025635,87.7298279c-1.6389771-0.131958-3.0650024,1.092041-3.1950073,2.7230225
	c-0.1300049,1.632019,1.09198,3.0650024,2.7230225,3.1959839c0.0799561,0.0060425,0.1599731,0.0090332,0.2390137,0.0090332
	c0.7049561,0,1.3799438-0.2479858,1.9219971-0.710022c0.6040039-0.5150146,0.9709473-1.2329712,1.0339966-2.0230103
	c0.0629883-0.7909546-0.1860352-1.55896-0.7000122-2.1619873C130.0105591,88.1598816,129.2925415,87.7928772,128.5025635,87.7298279
	z M112.2215576,107.4529724c-0.7890015-0.0620117-1.5579834,0.1859741-2.1619873,0.6999512
	c-0.6040039,0.5150146-0.9700317,1.2330322-1.03302,2.0230103c-0.0629883,0.7900391,0.1849976,1.5580444,0.7000122,2.1620483
	c0.5139771,0.6039429,1.2330322,0.9699707,2.0230103,1.0339966c0.0800171,0.0059814,0.15802,0.0089722,0.2369995,0.0089722
	c1.5299683,0,2.8330078-1.1820068,2.9580078-2.7329712C115.074585,109.0149231,113.8525391,107.5819397,112.2215576,107.4529724z
	 M113.8723755,89.092926c-0.7930298,0.0280151-1.5269775,0.3640137-2.0670166,0.9440308
	c-0.539978,0.5800171-0.8219604,1.3359985-0.7940063,2.1279907c0.0280151,0.7930298,0.3630371,1.5270386,0.9429932,2.0670166
	c0.5540161,0.5159912,1.2680054,0.7960205,2.0200195,0.7960205c0.0369873,0,0.072998-0.0010376,0.1090088-0.0020142
	c1.6359863-0.0580444,2.9190063-1.4360352,2.8610229-3.0720215c-0.0280151-0.7919922-0.3630371-1.526001-0.9440308-2.0670166
	C115.4203491,89.3469543,114.6743774,89.0739441,113.8723755,89.092926z M113.2367554,98.2766418
	c-1.6329956-0.1329956-3.0650024,1.0930176-3.1950073,2.7230225c-0.0639648,0.789978,0.1849976,1.559021,0.7000122,2.1619873
	c0.5140381,0.6040039,1.2330322,0.9710083,2.0230103,1.0339966c0.0800171,0.0059814,0.15802,0.0090332,0.2369995,0.0090332
	c1.5300293,0,2.8330078-1.1820068,2.9580078-2.7330322c0.0629883-0.789978-0.1860352-1.5579834-0.7000122-2.1619873
	S114.0267944,98.3396301,113.2367554,98.2766418z M165.9518433,59.9329491
	c0.0879517,0.0079956,0.1739502,0.0109863,0.2609863,0.0109863c0.6969604,0,1.3659668-0.242981,1.9049683-0.6970215
	c0.6060181-0.5100098,0.9790039-1.2259521,1.0460205-2.0159912c0.0679932-0.789978-0.1749878-1.55896-0.6849976-2.1660156
	c-0.5109863-0.6069946-1.2269897-0.9790039-2.0180054-1.0469971c-0.7919922-0.072998-1.559021,0.1760254-2.164978,0.6870117
	c-0.6069946,0.5100098-0.9790039,1.2260132-1.0469971,2.0159912c-0.0680542,0.7900391,0.1759644,1.559021,0.6869507,2.1660156
	C164.4458008,59.4939232,165.1618042,59.8649559,165.9518433,59.9329491z M114.9360962,80.0805359
	c-0.7920532-0.0910034-1.5640259,0.1360474-2.1830444,0.632019c-0.6189575,0.4949951-1.0079956,1.2020264-1.0960083,1.9890137
	c-0.1809692,1.6270142,0.9949951,3.0979614,2.6220093,3.2789917c0.1119995,0.0130005,0.2230225,0.0189819,0.3339844,0.0189819
	c0.6700439,0,1.31604-0.2260132,1.8480225-0.6499634c0.6190186-0.4949951,1.0090332-1.2020264,1.0960083-1.9900513
	c0.0889893-0.7879639-0.1359863-1.5629883-0.632019-2.1819458
	C116.4300537,80.5575256,115.7240601,80.1685486,114.9360962,80.0805359z M115.9508667,70.9610291
	c-1.6240234-0.1829834-3.0969849,0.9960327-3.2789917,2.6220093c-0.1799927,1.6270142,0.9959717,3.0969849,2.6220093,3.2780151
	c0.1109619,0.0130005,0.2219849,0.0189819,0.3299561,0.0189819c1.4910278,0,2.7800293-1.125,2.9490356-2.6399536
	C118.7538452,72.6130676,117.5768433,71.1420593,115.9508667,70.9610291z M136.0958252,87.4096375h0.0579834
	c1.6099854,0,2.9359741-1.2940063,2.9680176-2.9100342c0.0149536-0.7929688-0.2789917-1.5439453-0.8290405-2.1159668
	c-0.5489502-0.5709839-1.2890015-0.8950195-2.0819702-0.9099731c-0.7850342-0.0170288-1.5430298,0.2789917-2.1149902,0.8289795
	c-0.5720215,0.5499878-0.8950195,1.2890015-0.9110107,2.0809937
	C133.1538086,86.0206604,134.4598389,87.377655,136.0958252,87.4096375z M212.4186401,70.4656677
	c-1.6240234-0.1829834-3.0969849,0.9960327-3.2789917,2.6220093c-0.1799927,1.6270142,0.9959717,3.0969849,2.6220093,3.2780151
	c0.1109619,0.0130005,0.2219849,0.0189819,0.3299561,0.0189819c1.4910278,0,2.7800293-1.125,2.9490356-2.6399536
	C215.2216187,72.1177063,214.0446167,70.646698,212.4186401,70.4656677z M203.8894043,85.4456482
	c-1.6370239,0-2.9680176,1.3320312-2.9680176,2.9689941c0,1.6360474,1.3309937,2.9680176,2.9680176,2.9680176
	c1.6369629,0,2.9680176-1.3319702,2.9680176-2.9680176C206.8574219,86.7776794,205.5263672,85.4456482,203.8894043,85.4456482z
	 M211.147644,58.1644554c1.6369629,0,2.9679565-1.3319702,2.9679565-2.9680176c0-1.6369629-1.3309937-2.9689941-2.9679565-2.9689941
	c-1.6370239,0-2.9689941,1.3320312-2.9689941,2.9689941C208.1786499,56.8324852,209.5106201,58.1644554,211.147644,58.1644554z
	 M166.4478149,87.9494324c1.5219727,0,2.8220215-1.1690063,2.9559937-2.7139893
	c0.1400146-1.6309814-1.072998-3.0709839-2.7030029-3.2120361c-0.7819824-0.0689697-1.5579834,0.1750488-2.164978,0.6860352
	s-0.9800415,1.2269897-1.0480347,2.0159912c-0.0679932,0.789978,0.1760254,1.5599976,0.6860352,2.1669922
	c0.5109863,0.6060181,1.2269897,0.9780273,2.0169678,1.0460205
	C166.2767944,87.9454041,166.3618164,87.9494324,166.4478149,87.9494324z M221.8516846,91.0448303
	c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176
	c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176C224.8197021,92.375824,223.4876709,91.0448303,221.8516846,91.0448303z
	 M213.2189941,87.9503479c-0.7930298,0.0280151-1.5269775,0.3640137-2.0670166,0.9440308
	c-0.539978,0.5800171-0.8219604,1.3359985-0.7940063,2.1279907c0.0280151,0.7930298,0.3630371,1.5270386,0.9429932,2.0670166
	c0.5540161,0.5159912,1.2680054,0.7960205,2.0200195,0.7960205c0.0369873,0,0.072998-0.0010376,0.1090088-0.0020142
	c1.6359863-0.0580444,2.9190063-1.4360352,2.8610229-3.0720215c-0.0280151-0.7919922-0.3630371-1.526001-0.9440308-2.0670166
	C214.7669678,88.2043762,214.0209961,87.931366,213.2189941,87.9503479z M212.0080566,85.368988
	c0.6700439,0,1.31604-0.2260132,1.8480225-0.6499634c0.6190186-0.4949951,1.0090332-1.2020264,1.0960083-1.9900513
	c0.0889893-0.7879639-0.1359863-1.5629883-0.632019-2.1819458c-0.4949951-0.6200562-1.2009888-1.0090332-1.9889526-1.0970459
	c-0.7920532-0.0910034-1.5640259,0.1360474-2.1830444,0.632019c-0.6189575,0.4949951-1.0079956,1.2020264-1.0960083,1.9890137
	c-0.1809692,1.6270142,0.9949951,3.0979614,2.6220093,3.2789917
	C211.7860718,85.3630066,211.8970947,85.368988,212.0080566,85.368988z M211.9450073,67.5218811
	c1.6370239,0,2.9680176-1.3310547,2.9680176-2.9680176c0-1.6370277-1.3309937-2.968998-2.9680176-2.968998
	s-2.9689941,1.3319702-2.9689941,2.968998C208.9760132,66.1908264,210.3079834,67.5218811,211.9450073,67.5218811z
	 M181.6919556,76.7598572c-1.6369629,0-2.9680176,1.3319702-2.9680176,2.9689941
	c0,1.6369629,1.3310547,2.9679565,2.9680176,2.9679565c1.6370239,0,2.9680176-1.3309937,2.9680176-2.9679565
	C184.6599731,78.0918274,183.3289795,76.7598572,181.6919556,76.7598572z M176.0013428,84.9814148
	c-1.6370239,0-2.9689941,1.3309937-2.9689941,2.9679565c0,1.6370239,1.3319702,2.9680176,2.9689941,2.9680176
	c1.6359863,0,2.9680176-1.3309937,2.9680176-2.9680176C178.9693604,86.3124084,177.6373291,84.9814148,176.0013428,84.9814148z
	 M168.8167725,90.9996643c-0.7849731-0.0689697-1.55896,0.1749878-2.1659546,0.6860352
	c-0.6060181,0.5109863-0.9790039,1.2269897-1.0460205,2.0159912c-0.0679932,0.7910156,0.1749878,1.5599976,0.6849976,2.1660156
	c0.5109863,0.6069946,1.2269897,0.9789429,2.0180054,1.0469971c0.0859985,0.0079956,0.1709595,0.0109863,0.2559814,0.0109863
	c1.5230103,0,2.822998-1.1690063,2.9570312-2.7139893C171.6607666,92.580658,170.4467773,91.139679,168.8167725,90.9996643z
	 M187.6187134,83.7505798c-1.6370239,0-2.9690552,1.3309937-2.9690552,2.9679565
	c0,1.6370239,1.3320312,2.9680176,2.9690552,2.9680176c1.6359863,0,2.9679565-1.3309937,2.9679565-2.9680176
	C190.5866699,85.0815735,189.2546997,83.7505798,187.6187134,83.7505798z M195.4561157,89.0915222
	c-1.6370239,0-2.9690552,1.3320312-2.9690552,2.9689941c0,1.6370239,1.3320312,2.9680176,2.9690552,2.9680176
	c1.6369629,0,2.9679565-1.3309937,2.9679565-2.9680176C198.4240723,90.4235535,197.0930786,89.0915222,195.4561157,89.0915222z
	 M204.9716797,52.7844505c0-1.6369629-1.3309937-2.9680176-2.9679565-2.9680176
	c-1.6370239,0-2.9689941,1.3310547-2.9689941,2.9680176c0,1.6370239,1.3319702,2.9689941,2.9689941,2.9689941
	C203.640686,55.7534447,204.9716797,54.4214745,204.9716797,52.7844505z M188.519104,59.9439354
	c-1.6370239,0-2.9690552,1.3320312-2.9690552,2.9689941c0,1.6370277,1.3320312,2.9680214,2.9690552,2.9680214
	c1.6369629,0,2.9679565-1.3309937,2.9679565-2.9680214C191.4870605,61.2759666,190.1560669,59.9439354,188.519104,59.9439354z"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
