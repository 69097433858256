import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import css from './CommProfJoinContent.module.css';
import hand from './runningHand.png';

const CommProfJoinContent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      
      <h2>Who can join?</h2>
      
      <p>Are you registered with NRCPD, RBSLI, SRLDPC or ITI as a Trainee or Registered Interpreter/translator 
      or a different communication professional? Do you live or work in the North West?</p>
      <p><strong>You can join.</strong></p>

      <h2>Why should I join?</h2>
      <p>Our aim is to build a business of Deaf people and communication professionals in a way that has never 
        been done before. This includes the development of our platform.</p>
        
      <div className={css.handSection}>
        <img src={hand} alt="Signalise running hand" className={css.runningHand} />
      </div>

      <h2>Benefits of Worker membership:</h2>
        <ul className={css.listContainer}>
          <li>Ownership and involvement of a local community interpreting business</li>
          <li>Sustainable fees</li>
          <li>Be offered work first before non-members</li>
          <li>Work more locally, reduce travel</li>
          <li>Being able to feedback directly and change how the business works</li>
          <li>Opportunity to help campaign for change to make sure access for Deaf people improves</li>
          <li>Access to training, workshops, meetings</li>
          <li>Close relationship to interpreting service and interpreters</li>
          <li>Protecting the future of local interpreting services</li>
          <li>If buying shares, opportunity to earn interest in future &#40;see <Link to="join-us-investors">Investor Membership</Link> for more information&#41;</li>
        </ul>

        <p>If you are in the North West and would like to become a member see our <Link to="join-us">Join Us</Link> page.
          <br/> If we are not in your area yet and you would like to pre-register your interest in becoming a worker member when we come to your area,
           please email us at <a href="mailto:hello@signalise.coop">hello@signalise.coop</a>.
        </p>

    </div>
  );
};

CommProfJoinContent.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

CommProfJoinContent.propTypes = {
  rootClassName: string,
  className: string,
};

export default CommProfJoinContent;
