/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../components';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);

  // userTypes for UserNav options
  const privateData = user.attributes.profile.privateData || {};
  const userTypes = privateData.userTypes || [];
  const isUserProvider = userTypes.includes('communicationProfessional');
  const isUserCustomer = userTypes.includes('customer');
  const isUserContractCustomer = userTypes.includes('contractCustomer');
  const isUserDeaf = userTypes.includes('deafUser');
  const notCustomer = ['deafUser', 'communicationProfessional'];

  const viewBookingsOnly =
    notCustomer.some(a => userTypes.includes(a)) && !userTypes.includes('customer');

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
          <div className={css.customLinks}>
            <NamedLink className={css.navigationLink} name="CMSPage" params={{ pageId: 'invest' }}>
              <FormattedMessage id="Footer.toInvestPage" />
            </NamedLink>
            <NamedLink className={css.navigationLink} name="CommProfJoinPage">
              <FormattedMessage id="CommProfJoinPage.commprofTabTitle" />
            </NamedLink>
            <NamedLink className={css.navigationLink} name="DeafPage">
              Deaf Community{' '}
            </NamedLink>
          </div>
        </div>
        <div className={css.footer}>
          <NamedLink
            className={classNames(css.createNewListingLink, css.requirement)}
            name="CreateRequirementPage"
          >
            <span className={css.icon}>person</span>

            <FormattedMessage id="TopbarMobileMenu.createbooking" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const videoConferenceLink = !isAuthenticated ? null : isUserDeaf ? (
    <NamedLink
      className={classNames(
        css.navigationLink,
        currentPageClass('DeafUserVideoAccessPage'),
        css.requirement
      )}
      name="DeafUserVideoAccessPage"
    >
      <span className={css.icon}>videocam</span>

      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.videoConference" />
      </span>
    </NamedLink>
  ) : isUserContractCustomer ? (
    <NamedLink
      className={classNames(
        css.navigationLink,
        currentPageClass('VideoConferencePage'),
        css.requirement
      )}
      name="VideoConferencePage"
    >
      <span className={css.icon}>videocam</span>

      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.videoConference" />
      </span>
    </NamedLink>
  ) : null;

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>

        {videoConferenceLink}
        {/*<NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>*/}
        <div className={css.customLinks}>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('CMSPage'))}
            name="CMSPage"
            params={{ pageId: 'invest' }}
          >
            <FormattedMessage id="Footer.toInvestPage" />
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('CommProfJoinPage'))}
            name="CommProfJoinPage"
          >
            <FormattedMessage id="CommProfJoinPage.commprofTabTitle" />
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('DeafPage'))}
            name="DeafPage"
          >
            Deaf Community{' '}
          </NamedLink>
        </div>
        {isUserProvider ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('SearchPage'))}
            name="SearchPage"
            to={{
              search: 'pub_listingtype=requirement',
            }}
          >
            <FormattedMessage id="TopbarMobileMenu.browseBookingsLink" />
          </NamedLink>
        ) : null}
        {isUserProvider ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>
        ) : null}

        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('FavouritesPage'))}
          name="FavouritesPage"
        >
          <FormattedMessage id="TopbarMobileMenu.FavouritesLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
      </div>
      {isUserProvider ? (
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      ) : null}
      {!viewBookingsOnly ? (
        <div className={css.footer}>
          <NamedLink
            className={classNames(css.createNewListingLink, css.requirement)}
            name="CreateRequirementPage"
          >
            <span className={css.icon}>person</span>

            <FormattedMessage id="TopbarDesktop.createRequirementListing" />
          </NamedLink>
        </div>
      ) : null}{' '}
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
};

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
