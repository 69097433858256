import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldDateInput,
  FieldTextInput,
  FieldSelect,
  FieldPhoneNumberInput,
} from '../../components';

import css from './VtigerBookingCancelForm.module.css';

class VtigerBookingCancelFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.submittedValues = {};
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    this.props.onSubmit(this.submittedValues);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            booking,
            rootClassName,
            className,
            formId,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            pristine,
            ready,
            someError,
            form,
            values,
          } = fieldRenderProps;

          // No `ensureCurrentUser()` because we rely on server API checks for CRM-only data amends.

          // todo counterparts of this for all fields
          // const newPasswordRequired = validators.requiredStringNoTrim(newPasswordRequiredMessage);

          const genericFailure = someError ? (
            <span className={css.error}>Please ensure all fields are filled in correctly.</span>
          ) : null;

          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const classes = classNames(rootClassName || css.root, className);
          const submitDisabled = invalid || pristineSinceLastSubmit || inProgress;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e).catch(() => {
                  // Error is handled in duck file already.
                });
              }}
            >
              <div>
                <FieldSelect id="cancelledBy" name="cancelledBy" label="Cancelled by">
                  <option disabled value="">Choose from...</option>
                  <option value="Cancelled by customer">Cancelled by customer </option>
                  <option value="Cancelled by user">Cancelled by user </option>
                  <option value="Cancelled by CP">Cancelled by communication professional</option>
                  <option value="Cancelled by Signalise">Cancelled by Signalise </option>
                </FieldSelect>

                <FieldTextInput
                  type="textarea"
                  className={css.field}
                  id="reason"
                  name="reason"
                  label="Cancellation reason"
                />
              </div>

              <div className={css.bottomWrapper}>
                {genericFailure}
                <PrimaryButton
                  type="submit"
                  className={css.saveBookingButton}
                  inProgress={inProgress}
                  ready={ready}
                  disabled={submitDisabled}
                >
                  CONTINUE with Cancellation
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

VtigerBookingCancelFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  someError: null,
  inProgress: false,
  formId: null,
};

const { bool, string } = PropTypes;

VtigerBookingCancelFormComponent.propTypes = {
  booking: propTypes.vtigerBookingForCancel.isRequired,
  rootClassName: string,
  className: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  formId: string,
};

const VtigerBookingCancelForm = compose(injectIntl)(VtigerBookingCancelFormComponent);
VtigerBookingCancelForm.displayName = 'VtigerBookingCancelForm';

export default VtigerBookingCancelForm;
