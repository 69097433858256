import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './CPTypesContent.module.css';
import badgeOne from './images/thumbnail_NRCPD-badge-Sign-Language-Interpreter.jpg';
import badgeTwo from './images/thumbnail_NRCPD-badge-Sign-Language-Interpreter---Trainee.jpg';
import badgeThree from './images/NRCPD-badge-Sign-Language-Translator.jpg';
import badgeFour from './images/thumbnail_NRCPD-badge-Lipspeaker.jpg';
import badgeFive from './images/thumbnail_NRCPD-badge-Speech-To-Text-Reporter.jpg';
import badgeSix from './images/thumbnail_NRCPD-badge-Electronic-Notetaker.jpg';
import badgeSeven from './images/thumbnail_interpreter_for_deafblind.jpg';
import runningLeftHand from './images/runningLeftHand.png';

const CPTypesContent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <section id="BSLInterpreters" className={css.intro}>
                <h2>British Sign Language/English interpreters</h2>
                <div>
                  <h3>Who are they?</h3>
                  <p>
                    A British Sign Language (BSL)/English interpreter is a person who has been
                    trained to a high standard in both BSL and English language, as well as
                    interpreting and translation theory and practice. They have trained for many
                    years to postgraduate level.
                  </p>
                  <p>
                    Sign Language is very different from English. It has its own grammar and is a complex and
                    rich language. An interpreter listens (or watches!) and understands the message
                    being given (in either English or BSL), and then produces an equivalent message
                    in the other language. Working with the meaning, rather than individual
                    words/signs.
                  </p>
                  <p>
                    The National Union of British Sign Language Interpreters (NUBSLI) recommends
                    only using the services of a regulated Trainee Sign Language Interpreter or
                    Registered BSL/English Interpreter through one of the three regulatory bodies (
                    <a href="https://www.nrcpd.org.uk/">NRCPD</a>,{' '}
                    <a href="https://rbsli.org/">RBSLI</a>,{' '}
                    <a href="https://thescottishregister.co.uk/">SRLPDC</a>). This gives both the
                    deaf and hearing client/s reassurance that the interpreter has undertaken the
                    required complex and advanced training, is DBS checked and has the appropriate
                    personal indemnity insurance (PII) in place.
                  </p>
                  <p>
                    There are two categories of BSL/English interpreter, Registered Sign Language
                    Interpreter (RSLI) and Trainee Sign Language Interpreter (TSLI). In England, the
                    majority of interpreters are registered with the National Register of
                    Communication Professionals for Deaf people (NRCPD). They hold these badges:{' '}
                  </p>
                  <div className={css.badgeContainer}>
                    <img
                      src={badgeOne}
                      className={css.badge}
                      alt="NRCPD Sign Language Interpreter badge"
                    />
                    <p>
                      RSLI - this is an interpreter who has been deemed safe to practise and has
                      obtained all the necessary qualifications to work independently.
                    </p>
                  </div>
                  <div className={css.badgeContainer}>
                    <img
                      src={badgeTwo}
                      className={css.badge}
                      alt="NRCPD Sign Language Trainee Interpreter badge"
                    />
                    <p>
                      TSLI - this is an interpreter who is on an approved training course and
                      although they are able to undertake work in some areas, should not be used in
                      certain domains.
                    </p>
                  </div>
                </div>
                <div>
                  <h3>Who might use them?</h3>
                  <p>
                    BSL/English interpreters are used by deaf people who use BSL as their first or
                    preferred language, and by hearing people who are unable to communicate in BSL.
                  </p>{' '}
                  <p>
                    It is important to remember that the interpreter is there to communicate for
                    both parties and is not only booked “for the deaf person”. The hearing person is
                    just as reliant on the interpreter being present. This is why we use the term
                    ‘BSL/English interpreter.
                  </p>{' '}
                  <p>
                    Please see NUSBLI's comprehensive{' '}
                    <a href="https://nubsli.com/guidance/interpreter-awareness-guide/#what-interpreters-do">
                      Interpreter Awareness guide
                    </a>{' '}
                    for more information.
                  </p>
                </div>
              </section>
              <section id="signLanguageTranslators" className={css.intro}>
                <h2>Sign Language Translators:</h2>
                <div>
                  <h3>Who are they?</h3>
                  <p>
                    A Registered Sign Language Translator (RSLT) is a Deaf Interpreter who
                    translates from written text/English into BSL. Deaf Interpreters are native,
                    first language BSL users. They are able to produce sign language in a way that is easily
                    understood by Deaf BSL users.
                  </p>
                  <div className={css.badgeContainer}>
                    <img
                      src={badgeThree}
                      className={css.badge}
                      alt="NRCPD Sign Language Translator badge"
                    />
                    <p>Registered Sign Language Translators are regulated by NRCPD and RBSLI.</p>
                  </div>
                </div>
                <div>
                  <h3>Who might use them?</h3>
                  <p>Deaf BSL users whose first language is BSL who may watch the translated content on tv, websites or other forms of content.</p>{' '}
                </div>
              </section>
              <img src={runningLeftHand} alt="Signalise Hand running left" className={css.runningLeftHand}/>
              <section id="lipspeakers" className={css.intro}>
                <h2>Lipspeakers:</h2>
                <div>
                  <h3>Who are they?</h3>
                  <p>
                    A lipspeaker is a hearing person who has been professionally trained to be easy
                    to lipread.
                  </p>
                  <p>
                    They will usually sit directly opposite the deaf, deafened or hard of hearing
                    person, clearly repeating (silently) what the speaker is saying. The Lipspeaker
                    will make sure that the speaker is being understood and will pass on the
                    information in its entirety. They may use some fingerspelling to initialise
                    difficult words or acronyms.
                  </p>
                  <p>
                    Some lipspeakers may also use basic signs to assist in communication
                    (Lipspeakers with additional sign).{' '}
                  </p>
                  <div className={css.badgeContainer}>
                    <img src={badgeFour} className={css.badge} alt="NRCPD Lipspeaker badge" />
                    <p>Registered Lipspeakers are regulated by NRCPD, RBSLI and SRLPDC. </p>
                  </div>
                </div>
                <div>
                  <h3>Who might use them?</h3>
                  <p>
                    Deaf or hard of hearing people who use English as their first and preferred
                    language.
                  </p>{' '}
                </div>
              </section>
              <section id="speechToText" className={css.intro}>
                <h2>Speech-to-Text Reporters:</h2>
                <div>
                  <h3>Who are they?</h3>
                  <p>
                    A Speech-to-Text reporter (STTR), is a person that has been trained to use a
                    special keyboard to type verbatim (word for word), real time (live) text which
                    appears on screen for users to read. They type phonetically (how the word
                    sounds) and this is then converted into English and appears on a large screen or
                    via a laptop.
                  </p>
                  <div className={css.badgeContainer}>
                    <img src={badgeFive} className={css.badge} alt="NRCPD Speech To Text badge" />
                    <p>
                      Registered Speech-to-Text Reporters are regulated by NRCPD. There is also the
                      Association of Verbatim Speech to Text Reporters.{' '}
                    </p>
                  </div>
                </div>
                <div>
                  <h3>Who might use them?</h3>
                  <p>
                    Deaf or hard of hearing people who are comfortable reading English and prefer to
                    have access to this.
                  </p>{' '}
                </div>
              </section>
              <section id="electronicNotes" className={css.intro}>
                <h2>Electronic Notetakers:</h2>
                <div>
                  <h3>Who are they?</h3>
                  <p>
                    Electronic Notetakers (ENT) are people that have been trained to summarise
                    spoken English into electronic notes. They will record all the main points and
                    are often used where a deaf person is also using a BSL/English interpreter or
                    Lipspeaker. Deaf people are unable to write their own notes when using other
                    communication support, as they will miss information if they were to look away.
                  </p>
                  <div className={css.badgeContainer}>
                    <img
                      src={badgeSix}
                      className={css.badge}
                      alt="NRCPD Electronic Notetaker badge"
                    />
                    <p>
                      Electronic Notetakers are regulated by NRCPD and also supported by the
                      Association of Notetaking Professionals.{' '}
                    </p>
                  </div>
                </div>
                <div>
                  <h3>Who might use them?</h3>
                  <p>
                    Deaf or hard of hearing people who need to access a record of the event in
                    English.
                  </p>{' '}
                </div>
              </section>
              <section id="deafblindInterpreters" className={css.intro}>
                <h2>Deafblind interpreters:</h2>
                <div>
                  <h3>Who are they?</h3>
                  <p>
                    A Registered interpreter for Deafblind people are BSL/English interpreters who
                    have been trained in additional skills to work with deaf blind people (people
                    that are deaf and are also affected by a visual impairment). These include
                    Deafblind Manual (where words are fingerspelled onto the hand), Hands on (where
                    the deaf blind person places their hands on top of the interpreters to feel the
                    movement to aid them to access BSL) or visual frame (where the interpreter works
                    in a particular signing space to accommodate the area of vision the deafblind
                    person has). Their work also includes guiding when necessary.
                  </p>
                  <div className={css.badgeContainer}>
                    <img
                      src={badgeSeven}
                      className={css.badge}
                      alt="NRCPD Deafblind Interpreter badge"
                    />
                    <p>
                      Deaflind interpreters are regulated by NRCPD and SRLPDC also have categories
                      for Qualified and Trainee Guide Communicators and Deafblind Manual.
                    </p>
                  </div>
                </div>
                <div>
                  <h3>Who might use them?</h3>
                  <p>Deafblind people who have a dual sensory loss.</p>{' '}
                </div>
              </section>
              <section id="relayInterpreters" className={css.intro}>
                <h2>Relay/Intralingual interpreters:</h2>
                <div>
                  <h3>Who are they?</h3>
                  <p>
                  Relay (Intralingual) interpreters are Deaf professionals who are 
                  trained to work alongside British Sign Language/English Interpreter 
                  to ensure the Deaf person understands.
                  </p>
                  <p>The hearing British Sign Language/English Interpreter will 
                    interpret the message between the hearing client and the Relay (Intralingual) 
                    interpreter. The Relay (Intralingual) interpreter will work using British 
                    Sign Language between the hearing British Sign Language/English Interpreter 
                    and the Deaf client, to support the Deaf client’s understanding of the message 
                    being communicated from the hearing client and to relay what the Deaf person is 
                    saying back to the hearing interpreter and finally the hearing client.</p>
                </div>
                <div>
                  <h3>Who might use them?</h3>
                  <p>Deaf people with specific or complex language needs. 
                    This may be a learning disability, mental health condition, 
                    or someone with limited language development.</p>{' '}
                </div>
              </section>

            </div>
          </div>
      

    </div>
  );
};

CPTypesContent.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

CPTypesContent.propTypes = {
  rootClassName: string,
  className: string,
};

export default CPTypesContent;
