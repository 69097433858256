import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { NamedLink, ExternalLink } from '..';

import hand from './helping-hands.png';
import hoppingHand from '../InvestorJoinContent/hoppingHand.png';
import runningLeftHand from './runningLeftHand.png';
import deafUserOnly from './deafUserOnly.webp';
import deafUserAndBooker from './deafUserAndBooker.webp';
import sms from './deafUserSMS.jpg';
import css from './DeafPageContent.module.css';

const DeafPageContent = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={`${classes} ${css.staticPageWrapper}`}>
      <div className={css.contentWrapper}>
        <div className={css.intro}>
          <img src={hand} className={css.hand} alt="Signalise helping hands" />
          <h1 className={css.pageTitle}>Welcome to Signalise Co&#x2011;op!</h1>
        </div>
        <section className={css.sectionContent}>
          <div className={css.videoSection}>
            <div className={css.videoContainer}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/xi3cDv_M_zE?rel=0&origin=https://signalise.coop"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="embedded YouTube clip of a BSL translation"
              ></iframe>
            </div>
          </div>
        </section>
        <section className={css.sectionContent}>
          <p>
            We provide British Sign Language (BSL) interpreters (and other support e.g. lipspeakers,
            notetakers) for bookings such as health appointments, colleges and universities,
            training, events and conferences, social occasions, employment covered by Access to Work
            and a variety of other situations.
          </p>
          <p>
            For health bookings with the NHS we currently cover some organisations in Merseyside,
            Halton and Cheshire.
          </p>
        </section>
        <section className={css.sectionContent}>
          <h2>Health bookings</h2>
          <p>
            Did you know that doctors and hospitals have a legal duty to book you a British Sign
            Language (BSL) interpreter? They have to follow the NHS Accessible Information
            Standards.{' '}
          </p>
          <p>
            This means it is not your responsibility to book an interpreter, it’s theirs. There is{' '}
            <ExternalLink href="https://www.youtube.com/watch?v=ZJngMo37WvA">
              information about this in BSL here
            </ExternalLink>
            .
          </p>

          <p>
            We are working with doctors and hospitals in the area to make sure services are aware of
            how to book a British Sign Language (BSL) interpreter (or other communication
            professional) and make sure you have full access to your medical appointments.
          </p>

          <p>
            If you have any problems or need any help, please{' '}
            <NamedLink name="ContactPage"> contact us</NamedLink>.
          </p>
          <p>
            More information on the{' '}
            <ExternalLink
              href="https://www.liverpoolccg.nhs.uk/health-and-services/interpretation-services-communications-needs/"
              className={css.cookieLink}
            >
              Liverpool Place (NHS) Know your 5 Rights campaign.
            </ExternalLink>
          </p>
        </section>

        <section className={css.sectionContent}>
          <h2>How to book</h2>
          <p>
            You can contact your doctors’ surgery and ask them to book a British Sign Language (BSL)
            interpreter when they book your appointment.{' '}
          </p>
          <p>
            Signalise has a video interpreting service (VRS) for some doctors and hospitals. If we
            work with them, you can use the service to call and book a doctor's appointment or talk
            to the hospital.
          </p>
          <NamedLink name="DeafUserVideoAccessPage" className={css.primaryButton}>
            Check NHS video call
          </NamedLink>
          {/*TODO info on booking other appointments*/}
          <div className={css.smsContainer}>
            <p>
              This is an on demand service, so you won’t have to wait. Use the button above to check
              if you can call your doctor or hospital using the Signalise video service.
              <br />
              <br />
              If we have a contract with the doctor or hospital, we will book you an interpreter. We
              will send you a text message confirming the time and date of your appointment and the
              interpreter.
            </p>
            <img
              src={sms}
              className={css.sms}
              alt="Signalise SMS confirmation example with a link to an interpreter's profile"
            />
          </div>
        </section>
        <section className={css.sectionContent}>
          <h2>Why register on the Signalise website?</h2>
          <p>
            {/* You can <NamedLink name="SignupPage">register here on the Signalise website</NamedLink>. */}
            If you register and create an account, you will be able to give us more information
            about you so that we can give you the best service.
          </p>
          <p>If you register, you will be able to: </p>
          <ul className={css.listContainer}>
            <li>Tell us your preferred professionals</li>
            <li>
              Use the video interpreting service if the place you want to contact has a Signalise
              contract
            </li>
            <li>Tell us your contact details so we can communicate with you </li>
            <li>
              Tell us your gender and race so we can match you better with communication
              professionals
            </li>
          </ul>
          <p>
            All this information is confidential and will only be used for booking your interpreters
            or other communication professionals. We won’t share it with anyone else.{' '}
            <NamedLink name="PrivacyPolicyPage">Our privacy notice explains more.</NamedLink>
          </p>
          <p>
            We will always do our best to make sure we meet your needs and requests wherever
            possible. If your preferred interpreter isn’t available, we will contact you and check
            that you are happy with who we have booked.{' '}
          </p>
          <p>
            Most of{' '}
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  'address=Merseyside%2C%20England%2C%20United%20Kingdom&bounds=53.89%2C-2.16%2C53.06%2C-3.37&pub_listingtype=listing',
              }}
            >
              our interpreters have profiles
            </NamedLink>{' '}
            on our website, so you can see who they are.{' '}
          </p>
          <p>
            If you would like to know more about the hospitals and GPs that we currently work with,
            please <NamedLink name="ContractsContentPage">check our contracts list. </NamedLink>
          </p>
        </section>
        <img
          src={runningLeftHand}
          alt="Signalise Hand running left"
          className={css.runningLeftHand}
        />
        <section className={css.sectionContent}>
          <h2>How to register on the Signalise website</h2>
          <p>
            It's easy to register. You'll need to enter your email address, first and last name and
            create a password. You can also tell us how you want to use the site:
          </p>
          <figure className={css.userType}>
            <img
              src={deafUserOnly}
              className={css.userTypeImg}
              alt="image showing checkbox for user of communication professionals"
            />
            <p>
              <span className={css.instruction}>
                If someone else books communication professionals for you (for example, doctors,
                hospitals) then select{' '}
                <strong>'I am Deaf and use communication professionals'</strong>.
              </span>
            </p>
          </figure>
          <figure className={css.userType}>
            <img
              src={deafUserAndBooker}
              className={css.userTypeImg}
              alt="image showing checkboxes for deaf user of and booker of communication professionals"
            />
            <p>
              <span className={css.instruction}>
                If you use communication professionals but want to book them yourself then also
                choose <strong>'I want to book communication professionals'</strong>.
              </span>
            </p>
          </figure>
          <NamedLink name="SignupPage" className={css.primaryButton}>
            Create your account
          </NamedLink>
        </section>
        <img src={hoppingHand} alt="Hopping Signalise hand" className={css.hoppingHand} />
        <section className={css.sectionContent}>
          <h2>How to feedback</h2>
          <p>
            If you would like to talk to us about our service and have any questions, comments or
            suggestions, you can contact us in several ways. Choose whichever is easiest for you.
            You can contact us by:{' '}
          </p>
          <p>
            <span>
              <em>SMS:</em>
            </span>{' '}
            07723 469 028
          </p>
          <p>
            <span>
              <em>Email: </em>
            </span>
            <a href="mailto:bookings@signalise.coop">bookings@signalise.coop</a>
          </p>
          <p>
            <span>
              <em>WhatsApp: </em>
            </span>
            07723 469 028 (pictures or video messages)
          </p>
          <p>We can arrange a time to video call you, or you can send us a BSL video.</p>
        </section>
      </div>
    </div>
  );
};

DeafPageContent.defaultProps = { rootClassName: null, className: null };

DeafPageContent.propTypes = {
  rootClassName: string,
  className: string,
};

export default DeafPageContent;
