import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import { compose } from 'redux';
import { createResourceLocatorString } from '../../util/routes';
import { withRouter } from 'react-router-dom';
import { IconSpinner, IconFavourites } from '../../components';

import css from './AddToFavourites.module.css';

const AddToFavouritesComponent = props => {
  const {
    rootClassName,
    className,
    listingId,
    currentUser,
    onAddToFavourites,
    currentFavouritesListingId,
    addToFavouritesInProgress,
    addToFavouritesError,
    history,
  } = props;

  // Get favourites listingIds from
  // currentUser publicData
  const favouritesListingIds = currentUser?.attributes?.profile?.privateData?.favouritesList;

  // Check if users favourites listingIds
  // include the current listing id
  const isAddedToFavourites = favouritesListingIds && favouritesListingIds.includes(listingId);

  // Check if adding listing to favourites
  // is already in progress
  const isAddToFavouritesInProgress =
    currentFavouritesListingId === listingId && addToFavouritesInProgress;

  const hasErrors = addToFavouritesError;

  if (hasErrors) {
    return null;
  }

  const classes = classNames(rootClassName || css.root, className);
  const iconClasses = classNames(css.addToFavouritesIcon, {
    [css.activeFavouritesIcon]: isAddedToFavourites,
  });

  const addToFavourites = id => {
    // If user is autorized
    // add listingId to favourites
    if (currentUser?.id) {
      return onAddToFavourites(id);
    }

    // If user isn't authorized
    // redirect to LoginPage
    history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}));
  };

  return (
    <div className={classes}>
      {isAddToFavouritesInProgress ? (
        <span className={css.addToFavouritesSpinnerContainer}>
          <IconSpinner className={css.addToFavouritesSpinnerIcon} />
        </span>
      ) : (
        <span
          className={css.addToFavouritesIconContainer}
          onClick={() => addToFavourites(listingId)}
        >
          <IconFavourites rootClassName={iconClasses} />
        </span>
      )}
    </div>
  );
};

const { object } = PropTypes;

AddToFavouritesComponent.propTypes = {
  // from withRouter
  history: object.isRequired,
};

const AddToFavourites = compose(withRouter)(AddToFavouritesComponent);

export default AddToFavourites;
