import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { fetchCurrentUser } from '../../ducks/user.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/FavouritesPage/FETCH__LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/FavouritesPage/FETCH__LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/FavouritesPage/FETCH__LISTINGS_ERROR';

export const ADD_TO_FAVOURITES_REQUEST = 'app/FavouritesPage/ADD_TO_FAVOURITES_REQUEST';
export const ADD_TO_FAVOURITES_SUCCESS = 'app/FavouritesPage/ADD_TO_FAVOURITES_SUCCESS';
export const ADD_TO_FAVOURITES_ERROR = 'app/FavouritesPage/ADD_TO_FAVOURITES_ERROR';

export const SAVE_PREFERENCES_REQUEST = 'app/ContactDetailsPage/SAVE_PREFERENCES_REQUEST';
export const SAVE_PREFERENCES_SUCCESS = 'app/ContactDetailsPage/SAVE_PREFERENCES_SUCCESS';
export const SAVE_PREFERENCES_ERROR = 'app/ContactDetailsPage/SAVE_PREFERENCES_ERROR';
export const SAVE_PREFERENCES_CLEAR = 'app/ContactDetailsPage/SAVE_PREFERENCES_CLEAR';

export const UPDATE_LISTING_IDS = 'app/FavouritesPage/UPDATE_LISTING_IDS';

export const SET_INITIAL_STATE = 'app/FavouritesPage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

// ================ Reducer ================ //

const initialState = {
  userId: null, // to load user data for UserNav
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  currentFavouritesListingId: null,
  addToFavouritesInProgress: false,
  addToFavouritesError: null,
  saveGenderError: null,
  savePreferencesInProgress: false,
  preferencesChanged: false,
};

const resultIds = data => data.data.map(l => l.id);
const updateResultIds = (data, listingId) => data.filter(i => i.uuid !== listingId);

const favouritesPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case ADD_TO_FAVOURITES_REQUEST:
      return {
        ...state,
        addToFavouritesInProgress: true,
        currentFavouritesListingId: payload.listingId,
      };
    case ADD_TO_FAVOURITES_SUCCESS:
      return {
        ...state,
        addToFavouritesInProgress: false,
      };
    case ADD_TO_FAVOURITES_ERROR:
      return {
        ...state,
        addToFavouritesError: payload,
      };

    case UPDATE_LISTING_IDS:
      return {
        ...state,
        currentPageResultIds: updateResultIds(state.currentPageResultIds, payload.listingId),
      };

    case SAVE_PREFERENCES_REQUEST:
      return {
        ...state,
        savePreferencesInProgress: true,
        saveGenderError: null,
        preferencesChanged: false,
      };
    case SAVE_PREFERENCES_SUCCESS:
      return {
        ...state,
        savePreferencesInProgress: false,
        preferencesChanged: true,
      };
    case SAVE_PREFERENCES_ERROR:
      return { ...state, saveGenderError: payload };

    case SAVE_PREFERENCES_CLEAR:
      return {
        ...state,
        savePreferencesInProgress: false,
        saveGenderError: null,
        preferencesChanged: false,
      };

    default:
      return state;
  }
};

export default favouritesPageReducer;

// ================ Action creators ================ //

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const addToFavouritesRequest = listingId => ({
  type: ADD_TO_FAVOURITES_REQUEST,
  payload: { listingId },
});

export const addToFavouritesSuccess = () => ({
  type: ADD_TO_FAVOURITES_SUCCESS,
});

export const addToFavouritesError = e => ({
  type: ADD_TO_FAVOURITES_ERROR,
  error: true,
  payload: e,
});

export const updateListingsIds = listingId => ({
  type: UPDATE_LISTING_IDS,
  payload: { listingId },
});

//Added to get user data (see ProfilePage.duck.js)
export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

// Thunk added to get user data
export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

// Added to update contact details
export const savePreferencesRequest = () => ({ type: SAVE_PREFERENCES_REQUEST });
export const savePreferencesSuccess = () => ({ type: SAVE_PREFERENCES_SUCCESS });
export const savePreferencesError = error => ({
  type: SAVE_PREFERENCES_ERROR,
  payload: error,
  error: true,
});
export const savePreferencesClear = () => ({ type: SAVE_PREFERENCES_CLEAR });

// ================ Thunks ================ //

// Throwing error for new (loadData may need that info)
export const queryListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  return sdk.currentUser
    .show()
    .then(res => {
      // Favourites listingIds
      const favouritesListingIds = res.data.data.attributes?.profile?.privateData?.favouritesList;

      // FavouritesListingIds query
      const queryFavouritesListingIds = favouritesListingIds ? favouritesListingIds : [];

      // Listing query params
      const { perPage, ...rest } = queryParams;
      const params = {
        ...rest,
        per_page: perPage,
        pub_favouriteListingId: queryFavouritesListingIds,
      };

      // Query all listings that has
      // currentUser favourites listingIds
      return sdk.listings
        .query(params)
        .then(response => {
          dispatch(addMarketplaceEntities(response));
          dispatch(queryListingsSuccess(response));

          return response;
        })
        .catch(e => {
          dispatch(queryListingsError(storableError(e)));
          throw e;
        });
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const addToFavourites = listingId => (dispatch, getState, sdk) => {
  dispatch(addToFavouritesRequest(listingId));

  return sdk.currentUser.show().then(res => {
    const favouritesList = res.data.data.attributes?.profile?.privateData?.favouritesList;

    if (!favouritesList) {
      return sdk.currentUser
        .updateProfile(
          {
            privateData: {
              favouritesList: [listingId],
            },
          },
          { expand: true }
        )
        .then(response => {
          const entities = denormalisedResponseEntities(response);
          if (entities.length !== 1) {
            throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
          }
          const currentUser = entities[0];

          // Update current user in state.user.currentUser through user.duck.js
          dispatch(currentUserShowSuccess(currentUser));
          dispatch(addToFavouritesSuccess(response));
        })
        .catch(e => dispatch(addToFavouritesError(storableError(e))));
    } else {
      if (favouritesList.includes(listingId)) {
        // Update the listingId array
        const updatedfavouritesList = favouritesList.filter(id => id !== listingId);

        // If listingId exist in array
        // remove it
        return sdk.currentUser
          .updateProfile(
            {
              privateData: {
                favouritesList: updatedfavouritesList?.length === 0 ? null : updatedfavouritesList,
              },
            },
            { expand: true }
          )
          .then(response => {
            const entities = denormalisedResponseEntities(response);
            if (entities.length !== 1) {
              throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
            }
            const currentUser = entities[0];

            // Update current user in state.user.currentUser through user.duck.js
            dispatch(currentUserShowSuccess(currentUser));

            // Update listing ids and add listing
            // to the favourites
            dispatch(updateListingsIds(listingId));
            dispatch(addToFavouritesSuccess(response));
          })
          .catch(e => dispatch(addToFavouritesError(storableError(e))));
      }

      // If it's a unique listingId
      // add it to the existing array
      favouritesList.push(listingId);
      return sdk.currentUser
        .updateProfile(
          {
            privateData: {
              favouritesList,
            },
          },
          { expand: true }
        )
        .then(response => {
          const entities = denormalisedResponseEntities(response);
          if (entities.length !== 1) {
            throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
          }
          const currentUser = entities[0];

          // Update current user in state.user.currentUser through user.duck.js
          dispatch(currentUserShowSuccess(currentUser));
          dispatch(addToFavouritesSuccess(response));
        })
        .catch(e => dispatch(addToFavouritesError(storableError(e))));
    }
  });
};

/**
 * Make a gender pref update request to the API and return the current user.
 */
const requestSaveGenderPreferences = params => (dispatch, getState, sdk) => {
  const preferredGender = params.preferredGender;
  return sdk.currentUser
    .updateProfile(
      { privateData: { preferredGender } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(savePreferencesError(storableError(e)));
      // pass the same error so that the SAVE_PREFERENCES_SUCCESS
      // action will not be fired
      throw e;
    });
};

/**
 * Save Nhs number and update the current user.
 */
const saveGenderPreferences = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestSaveGenderPreferences(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(savePreferencesSuccess());
      })
      // error action dispatched in requestSaveGenderPreferences
      .catch(e => null)
  );
};

export const savePreferences = params => (dispatch, getState, sdk) => {
  dispatch(savePreferencesRequest());
  const { preferredGender } = params;
  return dispatch(saveGenderPreferences({ preferredGender }));
};

export const loadData = (userId, getState, params, search) => dispatch => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(
      queryListings({
        ...queryParams,
        page,
        perPage: RESULT_PAGE_SIZE,
        include: ['images'],
        'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        'limit.images': 1,
      })
    ),
  ]);
};
