import React from 'react';
import { formatMoney } from '../../util/currency';
import { injectIntl } from '../../util/reactIntl';
import { types } from '../../util/sdkLoader';
const { Money } = types;
const currency = process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY;

const formatCode = code =>
  code
    ?.replace('line-item/', '')
    ?.split('-')
    ?.map((p, index) => (index == 0 ? p?.charAt(0)?.toUpperCase() + p?.slice(1) : p))
    ?.join(' ');

const getPrice = items => items?.find(i => i.code == 'line-item/price')?.amount;

const getMoneyFromPercentage = (lineItems, percentage) => {
  const total = getPrice(lineItems);
  const amount = (total * percentage) / 100;
  console.log({ total, amount, percentage });
  return new Money(amount, currency);
};

function LineItemsDisplay({ metadata, isProvider, isCustomer, isRequirementType, intl }) {
  const isRelatedTo = metadata?.relatedTo;
  const lineItems = isCustomer ? metadata?.customerLineItems : metadata?.providerLineItems;
  // const lineItems = metadata?.lineItems;

  if (!isRequirementType || isRelatedTo) return null;

  return (
    <React.Fragment>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          maxWidth: '80%',
          margin: '1rem auto 0 auto',
        }}
      >
        {lineItems?.map(l => {
          const { code, amount, percentage, currency } = l;
          return (
            <React.Fragment>
              <p style={{ margin: 0 }}>{formatCode(code)}</p>
              {amount && (
                <p style={{ margin: 0, justifySelf: 'flex-end' }}>
                  {formatMoney(intl, new Money(amount, currency))}
                </p>
              )}
              {percentage && (
                <p style={{ margin: 0, justifySelf: 'flex-end' }}>
                  {formatMoney(intl, getMoneyFromPercentage(lineItems, percentage))}
                </p>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default injectIntl(LineItemsDisplay);
