import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  InlineTextButton,
  ResponsiveImage,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
} from '../../components';
import config from '../../config';

import MenuIcon from './MenuIcon';
import Overlay from './Overlay';
import css from './FavouritesCard.module.css';

const MENU_CONTENT_OFFSET = -12;
const MAX_LENGTH_FOR_WORDS_IN_TITLE = 7;

const getRegistrationInfo = (registrationOptions, key) => {
  return registrationOptions.find(c => c.key === key);
};

const createListingURL = (routes, listing) => {
  const id = listing.id.uuid;
  const slug = createSlug(listing.attributes.title);

  const linkProps = {
    name: 'ListingPage',
    params: { id, slug },
  };

  return createResourceLocatorString(linkProps.name, routes, linkProps.params, {});
};

// Cards are not fixed sizes - So, long words in title make flexboxed items to grow too big.
// 1. We split title to an array of words and spaces.
//    "foo bar".split(/([^\s]+)/gi) => ["", "foo", " ", "bar", ""]
// 2. Then we break long words by adding a '<span>' with word-break: 'break-all';
const formatTitle = (title, maxLength) => {
  const nonWhiteSpaceSequence = /([^\s]+)/gi;
  return title.split(nonWhiteSpaceSequence).map((word, index) => {
    return word.length > maxLength ? (
      <span key={index} style={{ wordBreak: 'break-all' }}>
        {word}
      </span>
    ) : (
      word
    );
  });
};

export const FavouritesCardComponent = props => {
  const {
    className,
    rootClassName,
    history,
    intl,
    isMenuOpen,
    listing,
    renderSizes,
    onAddToFavourites,
    addToFavouritesInProgress,
    addToFavouritesError,
    filtersConfig,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', publicData } = currentListing.attributes;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const hasError = addToFavouritesError;

  const onOverListingLink = () => {
    // Enforce preloading of ListingPage (loadable component)
    const { component: Page } = findRouteByRouteName('ListingPage', routeConfiguration());
    // Loadable Component has a "preload" function.
    if (Page.preload) {
      Page.preload();
    }
  };

  const registrationOptions = findOptionsForSelectFilter('registration', filtersConfig);
  const registration = publicData
    ? getRegistrationInfo(registrationOptions, publicData.registration)
    : null;

  const reg = registration?.label && registration?.label?.replace('Registered', '');

  const menuItemClasses = classNames(css.menuItem, {
    [css.menuItemDisabled]: addToFavouritesInProgress,
  });

  return (
    <div className={classes}>
      <div
        className={css.threeToTwoWrapper}
        tabIndex={0}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();

          // FavouritesCard contains links, buttons and elements that are working with routing.
          // This card doesn't work if <a> or <button> is used to wrap events that are card 'clicks'.
          //
          // NOTE: It might be better to absolute-position those buttons over a card-links.
          // (So, that they have no parent-child relationship - like '<a>bla<a>blaa</a></a>')
          history.push(createListingURL(routeConfiguration(), listing));
        }}
        onMouseOver={onOverListingLink}
        onTouchStart={onOverListingLink}
      >
        <div className={css.aspectWrapper}>
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
          <div
            className={classNames(css.menuOverlayWrapper, { [css.menuOverlayOpen]: isMenuOpen })}
          >
            <div className={classNames(css.menuOverlay)} />
            <div className={css.menuOverlayContent}>
              <FormattedMessage id="FavouritesCard.viewListing" />
            </div>
          </div>
          <div className={css.menubarWrapper}>
            <div className={css.menubarGradient} />
            <div className={css.menubar}>
              <Menu
                className={classNames(css.menu, { [css.cardIsOpen]: !isMenuOpen })}
                contentPlacementOffset={MENU_CONTENT_OFFSET}
                contentPosition="left"
                useArrow={false}
              >
                <MenuLabel className={css.menuLabel} isOpenClassName={css.listingMenuIsOpen}>
                  <div className={css.iconWrapper}>
                    <MenuIcon className={css.menuIcon} isActive={isMenuOpen} />
                  </div>
                </MenuLabel>
                <MenuContent rootClassName={css.menuContent}>
                  <MenuItem key="remove-favourites">
                    <InlineTextButton
                      rootClassName={menuItemClasses}
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        onAddToFavourites(id);
                      }}
                    >
                      <FormattedMessage id="FavouritesCard.removeFavourites" />
                    </InlineTextButton>
                  </MenuItem>
                </MenuContent>
              </Menu>
            </div>
          </div>
        </div>
        <div className={classNames(css.menuOverlayWrapper, { [css.menuOverlayOpen]: isMenuOpen })}>
          <div className={classNames(css.menuOverlay)} />
          <div className={css.menuOverlayContent}>
            <FormattedMessage id="FavouritesCard.viewListing" />
          </div>
        </div>

        {hasError ? (
          <Overlay errorMessage={intl.formatMessage({ id: 'FavouritesCard.actionFailed' })} />
        ) : null}
      </div>

      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.titleWrapper}>
            <InlineTextButton
              rootClassName={css.title}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                history.push(createListingURL(routeConfiguration(), listing));
              }}
            >
              {formatTitle(title, MAX_LENGTH_FOR_WORDS_IN_TITLE)}
            </InlineTextButton>
          </div>
          <div className={css.registrationInfo}>
            {registration && !registration.hideFromListingInfo ? <span>{reg}</span> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

FavouritesCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  actionsInProgressListingId: null,
  renderSizes: null,

  // config
  filtersConfig: config.custom.filters,
};

const { array, func, shape, string } = PropTypes;

FavouritesCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,

  // config
  filtersConfig: array,
};

export default compose(withRouter, injectIntl)(FavouritesCardComponent);
