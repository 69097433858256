import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import NamedLink from '../NamedLink/NamedLink';

import css from './SectionDeafUsers.module.css';

const SectionDeafUsers = props => {
  const { mainClassName, className } = props;

  const classes = classNames(mainClassName || css.main, className);
  const deafUsersLink = (
    <NamedLink name="DeafPage">
      <FormattedMessage id="SectionDeafUsers.deafUsersLink" />
    </NamedLink>
  );
  const videoAccessLink = (
    <NamedLink name="DeafUserVideoAccessPage">
      <FormattedMessage id="SectionDeafUsers.videoAccessLink" />
    </NamedLink>
  );
  const videoAboutLink = (
    <NamedLink name="VideoAboutPage">
      <FormattedMessage id="SectionDeafUsers.videoAboutLink" />
    </NamedLink>
  );

  return (
    <div className={classes}>
      <div className={css.title}>
        <h2>
          <FormattedMessage id="SectionDeafUsers.titleLineOne" />
        </h2>
      </div>

      <div className={css.contentWrapper}>
        <div className={css.contentSide}>
          <p>
            <FormattedMessage id="SectionDeafUsers.description1" />
          </p>
          <strong>
            <FormattedMessage id="SectionDeafUsers.description2" />
          </strong>
          <ul className={css.deafUsersList}>
            <li>
              <FormattedMessage id="SectionDeafUsers.list1" />
            </li>
            <li>
              <FormattedMessage id="SectionDeafUsers.list2" />
            </li>
            <li>
              <FormattedMessage id="SectionDeafUsers.list3" />
            </li>
            <li>
              <FormattedMessage id="SectionDeafUsers.list4" />
            </li>
            <li>
              <FormattedMessage id="SectionDeafUsers.list5" values={{ videoAccessLink }} />
            </li>{' '}
            <br />
          </ul>
          <p>
            <FormattedMessage
              id="SectionDeafUsers.description3"
              values={{ deafUsersLink, videoAboutLink }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

SectionDeafUsers.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionDeafUsers.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionDeafUsers;
