import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { saveDoc } from '../../util/api';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { required, composeValidators } from '../../util/validators';
import {
  Form,
  Button,
  FieldCheckboxGroup,
  SecondaryButton,
  ValidationError,
} from '../../components';
import config from '../../config';
import hand from './helping-hand-alert-transparent.png';
import css from './EditListingLegalForm.module.css';

export class EditListingLegalFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successPassport: false,
      successInsurance: false,
      imagePassport: '',
      imageInsurance: '',
      passportUpload: '',
      insuranceUpload: '',
      url: '',
    };
  }

  onChangePassport = e => {
    if (e.target.files[0]) {
      const imagePassport = e.target.files[0];
      this.setState(() => ({ imagePassport }));
      console.log(imagePassport);
    }
  };

  onPassportUpload = async e => {
    e.preventDefault();
    console.log('start of upload');

    const imagePassport = this.state.imagePassport;
    if (imagePassport === '') {
      console.error('you have not chosen a file yet');
      this.setState({ successPassport: false, imagePassport: null });
      return;
    }

    const uploadResult = await saveDoc({
      file: imagePassport,
      originalFilename: imagePassport.name,
      purpose: 'ID',
    });

    this.setState({
      url: uploadResult.fileId,
      successPassport: true,
      passportUpload: `Upload reference: ${uploadResult.fileId}`,
      imagePassport: '',
    });
  };

  onChangeInsurance = e => {
    if (e.target.files[0]) {
      const imageInsurance = e.target.files[0];
      this.setState(() => ({ imageInsurance, successInsurance: false }));
    }
  };

  onInsuranceUpload = async e => {
    e.preventDefault();
    console.log('start of upload');

    const imageInsurance = this.state.imageInsurance;
    if (imageInsurance === '') {
      console.error('you have not chosen a file yet');
      this.setState({ successInsurance: false, imageInsurance: null });
      return;
    }

    const uploadResult = await saveDoc({
      file: imageInsurance,
      originalFilename: imageInsurance.name,
      purpose: 'Insurance',
    });

    this.setState({
      url: uploadResult.fileId,
      successInsurance: true,
      insuranceUpload: `Upload reference: ${uploadResult.fileId}`,
      imageInsurance: '',
    });
  };

  getFilePath(path) {
    if (path) {
      const filename = path.replace(/^.*\\/, '');
      return `Your saved file: ${filename}`;
    } else {
      return 'Please upload a file and then save the form';
    }
  }

  render() {
    const isInsuranceEnabled =
      this.state.imageInsurance.size > 1 && this.state.imageInsurance != null;
    const isPassportEnabled = this.state.imagePassport.size > 1 && this.state.imagePassport != null;

    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={formRenderProps => {
          const {
            className,
            disabled,
            ready,
            rootClassName,
            handleSubmit,
            intl,
            invalid,
            onChange,
            pristine,
            saveActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
          } = formRenderProps;

          // Eligibility to work label
          const eligibleUKLabel = intl.formatMessage({
            id: 'EditListingLegalForm.eligibleUKLabel',
          });

          // Passport label
          const passportLabel = intl.formatMessage({
            id: 'EditListingLegalForm.passportLabel',
          });
          // insurance label
          const insuranceLabel = intl.formatMessage({
            id: 'EditListingLegalForm.insuranceLabel',
          });
          const insuranceUploadLabel = intl.formatMessage({
            id: 'EditListingLegalForm.insuranceUploadLabel',
          });

          const agreedTermsLabel = intl.formatMessage({
            id: 'EditListingLegalForm.agreedTermsLabel',
          });

          const { updateListingError, showListingsError } = fetchErrors || {};

          const errorMessage = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingRegistrationForm.updateFailed" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingRegistrationForm.showListingFailed" />
            </p>
          ) : null;

          const SuccessMessage = () => (
            <div className={css.successMessage}>
              <img src={hand} alt="Signalise helping alert hand" className={css.alertHand}></img>
              <p>
                <strong>
                  <FormattedMessage id="EditListingRegistrationForm.uploadSuccess" />
                </strong>
              </p>
            </div>
          );

          //validators
          const registrationCheckboxMessage = intl.formatMessage({
            id: 'EditListingRegistrationForm.checkboxMessage',
          });

          const agreedTermsCheckboxMessage = intl.formatMessage({
            id: 'EditListingLegalForm.agreedTermsCheckboxMessage',
          });

          const checkboxRequired = validators.requiredFieldArrayCheckbox(
            registrationCheckboxMessage
          );
          const agreedTermsCheckboxRequired = validators.requiredFieldArrayCheckbox(
            agreedTermsCheckboxMessage
          );

          const fileErrorMessage = intl.formatMessage({
            id: 'EditListingRegistrationForm.uploadNoFile',
          });

          const classes = classNames(rootClassName || css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          // console.log(this.state.DBSUpload);
          const hasData =
            (this.state.insuranceUpload && this.state.passportUpload) ||
            (this.props.initialValues.insuranceUpload && this.props.initialValues.passportUpload);
          const submitDisabled = !hasData || invalid || disabled || submitInProgress;
          const insuranceCheckOptions = [
            { key: 'insuranceConfirmed', label: 'Please tick to confirm you have PII' },
          ];
          const eligibleUKCheckOptions = [
            {
              key: 'eligibleUKConfirmed',
              label: 'Please tick to confirm you are eligible to work in the UK',
            },
          ];
          const agreedTermsCheckOptions = [
            { key: 'agreedTermsConfirmed', label: 'I agree to the Terms of Service' },
          ];

          const passportUpload = `${this.state.passportUpload}`;
          const insuranceUpload = `${this.state.insuranceUpload}`;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              {errorMessage}
              {errorMessageShowListing}
              <h2 className={css.pageHeading}>Documentation we require from you </h2>
              <p>
                There are certain documents we must collect from you to comply with UK regulations.
                To complete this section, you will need these documents to hand:
              </p>
              <ul className={css.requiredDocuments}>
                <li>your passport or birth certificate</li>
                <li>evidence of your Professional Indemnity Insurance</li>
              </ul>
              <div className={css.sectionContainer}>
                <h2 className={css.sectionTitle}>
                  <FormattedMessage id="EditListingLegalForm.eligibilityToWorkTitle" />
                </h2>
                <div className={css.sectionContainer}>
                  <FieldCheckboxGroup
                    className={css.features}
                    id="eligibleUKCheck"
                    name="eligibleUKCheck"
                    label={eligibleUKLabel}
                    options={eligibleUKCheckOptions}
                    validate={checkboxRequired}
                  />
                </div>

                <div className={css.elementMargin}>
                  <h3>
                    <FormattedMessage id="EditListingLegalForm.passportInstructions" />
                  </h3>
                  <p className={css.helperText}>
                    <FormattedMessage id="EditListingRegistrationForm.imageTypes" />
                  </p>

                  <Field
                    component="input"
                    id="passportUpload"
                    name="passportUpload"
                    passportUpload={passportUpload}
                    label={passportLabel}
                    validate={composeValidators(required(fileErrorMessage))}
                  >
                    {({ input, meta }) => (
                      <div className={css.inputContainer}>
                        <input
                          type="file"
                          className={css.upload}
                          accept=".jpg,.jpeg,.png,.pdf"
                          onChange={e => {
                            input.onChange(e); //final-form's onChange
                            if (onChange) {
                              //props.onChange
                              this.onChangePassport(e);
                            }
                          }}
                        />
                        <ValidationError fieldMeta={meta} />
                      </div>
                    )}
                  </Field>
                  <SecondaryButton
                    type="button"
                    disabled={!isPassportEnabled}
                    onClick={this.onPassportUpload}
                    className={css.uploadButton}
                  >
                    Upload
                  </SecondaryButton>
                  {this.state.successPassport ? <SuccessMessage /> : false}
                  <div>
                    <p className={css.helperText}>
                      {this.getFilePath(this.props.initialValues.passportUpload)}
                    </p>
                  </div>
                </div>
              </div>
              <div className={css.sectionContainer}>
                <h2 className={css.sectionTitle}>
                  <FormattedMessage id="EditListingLegalForm.insuranceTitle" />
                </h2>
                <FieldCheckboxGroup
                  className={css.features}
                  id="insuranceCheck"
                  name="insuranceCheck"
                  label={insuranceLabel}
                  options={insuranceCheckOptions}
                  validate={checkboxRequired}
                />
              </div>

              <div className={css.elementMargin}>
                <h3>
                  <FormattedMessage id="EditListingLegalForm.insuranceUploadInstructions" />
                </h3>
                <p className={css.helperText}>
                  <FormattedMessage id="EditListingRegistrationForm.imageTypes" />
                </p>

                <Field
                  component="input"
                  id="insuranceUpload"
                  name="insuranceUpload"
                  insuranceUpload={insuranceUpload}
                  label={insuranceUploadLabel}
                  validate={composeValidators(required(fileErrorMessage))}
                >
                  {({ input, meta }) => (
                    <div className={css.inputContainer}>
                      <input
                        type="file"
                        className={css.upload}
                        accept=".jpg,.jpeg,.png,.pdf"
                        onChange={e => {
                          input.onChange(e); //final-form's onChange
                          if (onChange) {
                            //props.onChange
                            this.onChangeInsurance(e);
                          }
                        }}
                      />
                      <ValidationError fieldMeta={meta} />
                    </div>
                  )}
                </Field>
                <SecondaryButton
                  type="button"
                  disabled={!isInsuranceEnabled}
                  onClick={this.onInsuranceUpload}
                  className={css.uploadButton}
                >
                  Upload
                </SecondaryButton>
                {this.state.successInsurance ? <SuccessMessage /> : false}
                <div>
                  <p className={css.helperText}>
                    {this.getFilePath(this.props.initialValues.insuranceUpload)}
                  </p>
                </div>
              </div>

              <h2 className={css.sectionTitle}>
                <FormattedMessage id="EditListingLegalForm.agreedTermsTitle" />
              </h2>
              <FieldCheckboxGroup
                className={css.features}
                id="agreedTermsCheck"
                name="agreedTermsCheck"
                label={agreedTermsLabel}
                options={agreedTermsCheckOptions}
                validate={agreedTermsCheckboxRequired}
              />
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingLegalFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingLegalFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  insuranceCheck: shape({
    insuranceCheck: string.isRequired,
  }),
  eligibleUKCheck: shape({
    eligibleUKCheck: string.isRequired,
  }),
  passportUpload: object.isRequired,
  insuranceUpload: object.isRequired,
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingLegalFormComponent);
