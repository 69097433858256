import React from 'react';
import { propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemLongBookingMessage = props => {
  const { transaction, unitType, isCustomer, isProvider, bookingState } = props;
  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  return (
    <div className={css.advisory}>
      {quantity >= 2 && isProvider && bookingState !== 'accepted' && bookingState !== 'cancelled' && bookingState !== 'declined' ? 
      <span>This booking is for more than 2 hours. If you require co-workers for this assignment
        please message the booker <strong>before accepting the booking</strong>.</span> : null}
      {quantity >= 2 && isCustomer ? 
      <span>This booking may require more than one communication professional. The professional will confirm this before accepting this booking.</span> : null}
      
    </div>
  );
};

LineItemLongBookingMessage.propTypes = {
  transaction: propTypes.transaction.isRequired,
};

export default LineItemLongBookingMessage;
