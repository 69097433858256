import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldReactSelect } from '../../components';
import config from '../../config';
import arrayMutators from 'final-form-arrays';
import data from '../../data/signalise.json';
import addresses from '../../data/addresses.json';

import css from './ContractForm.module.css';
import { useEffect } from 'react';
import { GoogleLocationAutocompleteInputField } from '../../components/LocationAutocompleteInput/GoogleAutocompleteInput';
import { types as sdkTypes } from '../../util/sdkLoader';
const { LatLng } = sdkTypes;

const KEY_CODE_ENTER = 13;

const identity = v => v;

const ContractFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        openTermsOfService,
        values,
        form,
      } = fieldRenderProps;

      const [billingCodes, setBillingCodes] = useState([]);
      const [contractLabel, setContractLabel] = useState([]);
      const [contractAddresses, setContractAddresses] = useState([]);
      let billingCodeOptions = data.map(item => {
        return {
          key: item.code ? item.code.trim() : null,
          label: item.code ? `${item.code.trim()} - ${item.department.trim()}` : null,
          email: item.email ? item.email.trim() : null,
          department: item.department ? item.department.trim() : null,
          address: item.address ? item.address : null,
          contractCode: item.contractCode ? item.contractCode : null,
        };
      });
      /*billingCodeOptions.unshift({
        key: 9999,
        label: "I can't find my department/organisation",
        contractCode: 'All',
      });*/
      // let addressOptions = addresses.map(item => {
      //   return { key: item.name, label: item.address };
      // });
      const otherNHSContractCustomers = [
        '20148',
        '20149',
        '20150',
        '20151',
        '20153',
        '20154',
        '20155',
        '20156',
        '20157',
      ];
      useEffect(() => {
        if (values?.billing_code) {
          const found = billingCodeOptions.find(item => item.key === values?.billing_code?.key);
          if (found && found.key != 9999) {
            form.change('department', found.department);
            const foundAddress = {
              search: found.address.place,
              selectedPlace: {
                address: found.address.place,
                origin: new LatLng(found.address.coords.lat, found.address.coords.lng),
              },
            };
            form.change('organisation_address', foundAddress);
          }
        }
      }, [values?.billing_code]);
      useEffect(() => {
        if (values?.contract) {
          const found = addresses[contractLabel].find(item => item.name == values?.contract?.key);
          if (found) {
            form.change('department', found.name);
            const foundAddress = {
              search: found.data.search,
              selectedPlace: found.data.selectedPlace,
            };
            form.change('organisation_address', foundAddress);
          }
        }
      }, [values?.contract]);
      useEffect(() => {
        if (values?.pincode) {
          const found = config.custom.contractPincodes.find(c => c.key == +values?.pincode);
          if (found) {
            form.change('contract_name', found.value);
            const foundOptions = /*found.label === 'wirralCHC'
                ? [
                    {
                      key: 'custom',
                      label: 'Custom Billing Code',
                    },
                  ]
                : */ [
              /*
                    {
                      key: 'custom',
                      label: 'Custom Billing Code',
                    },*/

              ...billingCodeOptions.filter(f => {
                return f.contractCode == found.label || f.contractCode == 'All';
              }),
            ];
            setBillingCodes(foundOptions);
            setContractLabel(found.label);
            if (otherNHSContractCustomers.includes(values.pincode)) {
              setContractAddresses(
                addresses[found.label] && found.label == 'luhft'
                  ? addresses[found.label]
                      .filter(a => a.mainSite)
                      .map(a => {
                        return { key: a.name, label: a.name };
                      })
                  : addresses[found.label].map(a => {
                      return {
                        key: a.name,
                        label: a.name,
                      };
                    })
              );
            }
          }
        }
      }, [values?.pincode]);

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordUpperCaseMessage = intl.formatMessage({
        id: 'PasswordChangeForm.passwordUpperCase',
      });
      const passwordLowerCaseMessage = intl.formatMessage({
        id: 'PasswordChangeForm.passwordLowerCase',
      });
      const passwordNumberMessage = intl.formatMessage({
        id: 'PasswordChangeForm.passwordNumber',
      });
      const passwordSpecialMessage = intl.formatMessage({
        id: 'PasswordChangeForm.passwordSpecial',
      });

      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordUpperCase = validators.complexityTest(
        passwordUpperCaseMessage,
        validators.PASSWORD_UPPERCASE
      );

      const passwordLowerCase = validators.complexityTest(
        passwordLowerCaseMessage,
        validators.PASSWORD_LOWERCASE
      );

      const passwordNumber = validators.complexityTest(
        passwordNumberMessage,
        validators.PASSWORD_NUMBER
      );

      const passwordSpecial = validators.complexityTest(
        passwordSpecialMessage,
        validators.PASSWORD_SPECIAL
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength,
        passwordUpperCase,
        passwordLowerCase,
        passwordNumber,
        passwordSpecial
      );

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      // user types

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          openTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={openTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );

      billingCodeOptions = [
        {
          key: 'custom',
          label: 'Custom Billing Code',
        },
        ...billingCodeOptions,
      ];

      const isBillingCodeDisabled =
        (values?.pincode &&
          !config.custom.contractPincodes.map(c => c.key).includes(+values?.pincode)) ||
        !values?.pincode;
      const isAddressDeptDisabled = values?.pincode != 20148;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <div className={css.contractsInfo}>
              Note: If you do not know your PIN or need help please email{' '}
              <a href="mailto:bookings@signalise.coop">bookings@signalise.coop</a>.
            </div>
            <div className={css.contractsInfoAlt}>
              Please make sure you use a Trust device when using this service.
            </div>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <FieldTextInput
              className={css.password}
              type="text"
              id={formId ? `${formId}.fname` : 'fname'}
              name="fname"
              autoComplete="given-name"
              label={firstNameLabel}
              placeholder={firstNamePlaceholder}
              validate={firstNameRequired}
            />
            <FieldTextInput
              className={css.password}
              type="text"
              id={formId ? `${formId}.lname` : 'lname'}
              name="lname"
              autoComplete="family-name"
              label={lastNameLabel}
              placeholder={lastNamePlaceholder}
              validate={lastNameRequired}
            />
            <FieldTextInput
              className={css.password}
              type="text"
              id={formId ? `${formId}.jobtitle` : 'jobtitle'}
              name="jobtitle"
              autoComplete="jobtitle"
              label={'Job Title • optional'}
              placeholder={'Job Title'}
            />
            <FieldTextInput
              className={css.password}
              type="text"
              id={formId ? `${formId}.pincode` : 'pincode'}
              name="pincode"
              autoComplete="pincode"
              label={'Pincode'}
              placeholder={'Pincode'}
              validate={validators.composeValidators(
                validators.required('Pincode is required'),
                validators.validatePincode('Please enter a valid Pincode.')
              )}
            />
            <FieldTextInput
              className={css.password}
              type="text"
              id={formId ? `${formId}.contract_name` : 'contract_name'}
              name="contract_name"
              autoComplete="contract_name"
              label={'Contract Name'}
              placeholder={'Contract Name'}
              disabled="disabled"
              validate={validators.required('Contract Name is required')}
            />
            {otherNHSContractCustomers.includes(values?.pincode) ? (
              <FieldReactSelect
                id={formId ? `${formId}.contract` : 'contract'}
                name="contract"
                options={contractAddresses}
                autoComplete="contract"
                label={'Location'}
                placeholder={'Location'}
                validate={validators.required('Location is required')}
                isSearchable={true}
              />
            ) : (
              <div>
                <FieldReactSelect
                  id={formId ? `${formId}.billing_code` : 'billing_code'}
                  name="billing_code"
                  options={billingCodes}
                  autoComplete="billing_code"
                  label={'Billing Code'}
                  placeholder={'ODS / NACS code'}
                  validate={validators.required('Billing Code is required')}
                  isSearchable={true}
                  disabled={isBillingCodeDisabled}
                />
                {values?.billing_code?.key == 'custom' ? (
                  <FieldTextInput
                    className={css.password}
                    type="text"
                    id={formId ? `${formId}.billing_code_custom` : 'billing_code_custom'}
                    name="billing_code_custom"
                    autoComplete="billing_code_custom"
                    label={'Custom Billing Code'}
                    placeholder={'ODS / NACS code'}
                    validate={validators.required('Custom Billing Code is required')}
                  />
                ) : null}{' '}
              </div>
            )}
            <FieldTextInput
              className={css.password}
              type="text"
              id={formId ? `${formId}.department` : 'department'}
              name="department"
              autoComplete="department"
              label={
                otherNHSContractCustomers.includes(values?.pincode)
                  ? 'Organisation'
                  : 'Department / GP Name'
              }
              placeholder={'Department / GP Name'}
              disabled={isAddressDeptDisabled}
              validate={validators.required('Department / GP Name is required')}
            />{' '}
            <div className={css.locationContainer}>
              <GoogleLocationAutocompleteInputField
                label={'Organisation Address'}
                placeholder={'Organisation Address'}
                id={formId ? `${formId}.organisation_address` : 'organisation_address'}
                useDefaultPredictions={false}
                format={identity}
                autoComplete="none"
                name="organisation_address"
                valueFromForm={values.organisation_address}
                disabled={isAddressDeptDisabled}
                isTopBarSearchForm={true}
                // validate={composeValidators(
                //   autocompleteSearchRequired(addressRequiredMessage),
                //   autocompletePlaceSelected(addressNotRecognisedMessage)
                // )}
              />
            </div>
            {/* <FieldTextInput
              className={css.password}
              type="text"
              id={formId ? `${formId}.organisation_address` : 'organisation_address'}
              name="organisation_address"
              autoComplete="organisation_address"
              label={'Organisation Address'}
              placeholder={'Organisation Address'}
              validate={validators.required('Organisation Address is required')}
            /> */}
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordValidators}
            />
          </div>
          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

ContractFormComponent.defaultProps = { inProgress: false, filterConfig: config.custom.filters };

const { bool, func } = PropTypes;

ContractFormComponent.propTypes = {
  inProgress: bool,

  openTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ContractForm = compose(injectIntl)(ContractFormComponent);
ContractForm.displayName = 'ContractForm';

export default ContractForm;
