import pick from 'lodash/pick';
import config from '../../config';
import { initiatePrivileged, transitionPrivileged } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  isPrivileged,
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { currentUserShowSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const SAVE_ADDRESS_DETAILS_REQUEST = 'app/AddressDetailsPage/SAVE_ADDRESS_DETAILS_REQUEST';
export const SAVE_ADDRESS_DETAILS_SUCCESS = 'app/AddressDetailsPage/SAVE_ADDRESS_DETAILS_SUCCESS';
export const SAVE_ADDRESS_DETAILS_ERROR = 'app/AddressDetailsPage/SAVE_ADDRESS_DETAILS_ERROR';

export const SAVE_ADDRESS_DETAILS_CLEAR = 'app/AddressDetailsPage/SAVE_ADDRESS_DETAILS_CLEAR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  bookingData: null,
  bookingDates: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  saveAddressDetailsInProgress: false,
  addressDetailsChanged: false,
  saveAddressDetailsError: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    case SAVE_ADDRESS_DETAILS_REQUEST:
      return {
        ...state,
        saveAddressDetailsInProgress: true,
        saveAddressDetailsError: null,
        addressDetailsChanged: false,
      };
    case SAVE_ADDRESS_DETAILS_SUCCESS:
      return { ...state, saveAddressDetailsInProgress: false, addressDetailsChanged: true };
    case SAVE_ADDRESS_DETAILS_ERROR:
      return { ...state, saveAddressDetailsInProgress: false, saveAddressDetailsError: payload };
    case SAVE_ADDRESS_DETAILS_CLEAR:
      return {
        ...state,
        saveAddressDetailsInProgress: false,
        saveAddressDetailsError: null,
        addressDetailsChanged: false,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const saveAddressDetailsRequest = () => ({ type: SAVE_ADDRESS_DETAILS_REQUEST });
export const saveAddressDetailsSuccess = () => ({ type: SAVE_ADDRESS_DETAILS_SUCCESS });

export const saveAddressDetailsError = error => ({
  type: SAVE_ADDRESS_DETAILS_ERROR,
  payload: error,
  error: true,
});

export const saveAddressDetailsClear = () => ({ type: SAVE_ADDRESS_DETAILS_CLEAR });

/* ================ Thunks ================ */

export const initiateOrder = (requestParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const bookingData = {
    startDate: requestParams.bookingStart,
    endDate: requestParams.bookingEnd,
    deafUserName: requestParams.deafUserName,
    bookingBuilding: requestParams.bookingBuilding,
    bookingLocation: requestParams.bookingLocation,
    buildingOrRemote: requestParams.buildingOrRemote,
    bookingInfo: requestParams.bookingInfo,
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: requestParams,
      }
    : {
        processAlias: config.bookingProcessAlias,
        transition,
        params: requestParams,
      };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: requestParams.listingId.uuid,
      bookingStart: requestParams.bookingStart,
      bookingEnd: requestParams.bookingEnd,
      location: requestParams.bookingLocation,
      buildingOrRemote: requestParams.buildingOrRemote,
      protectedData: {
        deafUserName: requestParams.deafUserName,
        bookingBuilding: requestParams.bookingBuilding,
        bookingLocation: requestParams.bookingLocation,
        buildingOrRemote: requestParams.buildingOrRemote,
        bookingInfo: requestParams.bookingInfo,
      },
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged

    bookingData.bookingLocation = bodyParams.params.protectedData.bookingLocation;
    bookingData.buildingOrRemote = bodyParams.params.protectedData.buildingOrRemote;
    return initiatePrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const confirmPayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: {},
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Make a address details update request to the API and return the current user.
 */

const requestSaveAddressDetails = params => (dispatch, getState, sdk) => {
  const {
    jobTitle,
    addressDepartment,
    addressLineOne,
    addressLineTwo,
    addressCity,
    postalCode,
  } = params;

  return sdk.currentUser
    .updateProfile(
      {
        privateData: {
          jobTitle,
          addressDepartment,
          addressLineOne,
          addressLineTwo,
          addressCity,
          postalCode,
        },
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveAddressDetailsError(storableError(e)));
      // pass the same error so that the SAVE_ADDRESS_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

// Exported function
export const saveAddressDetails = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestSaveAddressDetails(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveAddressDetailsSuccess());
      })
      // error action dispatched in requestSavePhoneNumber
      .catch(e => null)
  );
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (requestParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const bookingData = {
    startDate: requestParams.bookingStart,
    endDate: requestParams.bookingEnd,
    bookingLocation: requestParams.bookingLocation,
    buildingOrRemote: requestParams.buildingOrRemote,
    protectedData: {
      deafUserName: requestParams.deafUserName,
      bookingBuilding: requestParams.bookingBuilding,
      bookingLocation: requestParams.bookingLocation,
      buildingOrRemote: requestParams.buildingOrRemote,
      bookingInfo: requestParams.bookingInfo,
    },
  };

  const params = {
    ...requestParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params,
      }
    : {
        processAlias: config.bookingProcessAlias,
        transition,
        params,
      };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    const { listingId, bookingStart, bookingEnd, protectedData } = params;
    log.error(e, 'speculate-transaction-failed', {
      listingId: listingId.uuid,
      bookingStart,
      bookingEnd,
      protectedData,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};
