import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import PoweringUpShareOffer from './PoweringUpShareOffer.png';
import css from './SectionInvest.module.css';

const SectionInvest = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <h2>
          <FormattedMessage id="SectionInvest.title" />
        </h2>
        <div className={css.stepText}>
          <p>
            <FormattedMessage id="SectionInvest.text" />
          </p>
        </div>
      </div>

      <img src={PoweringUpShareOffer} alt="Signalise Invest Info" className={css.investImage} />
    </div>
  );
};

SectionInvest.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionInvest.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionInvest;
