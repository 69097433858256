import React from 'react';
import { string } from 'prop-types';
import { Field } from 'react-final-form';
import DateRangeController from './DateRangeController';
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import config from '../../config';
import moment from 'moment';

const component = props => {
  const { input, controllerRef, allDates, ...rest } = props;
  const { type, checked, ...restOfInput } = input;
  return <DateRangeController ref={controllerRef} {...restOfInput} {...rest} />;
};

const FieldDateRangeController = props => {
  const isOutsideRange = day => {
    if (props.allDates) return false;
    const endOfRange = config.dayCountAvailableForBooking - 1;
    return (
      !isInclusivelyAfterDay(day, moment()) ||
      !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
    );
  };

  return <Field component={component} isOutsideRange={isOutsideRange} {...props} />;
};

FieldDateRangeController.defaultProps = {
  rootClassName: null,
  className: null,
};

FieldDateRangeController.propTypes = {
  rootClassName: string,
  className: string,
};

export default FieldDateRangeController;
