import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldSelect } from '../../components';
import counties from './Counties.js';
import css from './ExpressionOfInterestForm.module.css';

class ExpressionOfInterestFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.submittedValues = {};
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    this.props.onSubmit(this.submittedValues);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            ready,
            someError,
            form,
            values,
            expressionSent,
          } = fieldRenderProps;
          // No `ensureCurrentUser()` because we rely on server API checks for CRM-only data amends.

          // todo counterparts of this for all fields
          // const newPasswordRequired = validators.requiredStringNoTrim(newPasswordRequiredMessage);

          const genericFailure = someError ? (
            <span className={css.error}>Please ensure all fields are filled in correctly.</span>
          ) : null;

          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const classes = classNames(rootClassName || css.root, className);
          const submitDisabled = invalid || pristineSinceLastSubmit || inProgress || expressionSent;

          const firstNameLabel = intl.formatMessage({
            id: 'ExpressionOfInterestForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ExpressionOfInterestForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ExpressionOfInterestForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // lastName
          const lastNameLabel = intl.formatMessage({
            id: 'ExpressionOfInterestForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ExpressionOfInterestForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'ExpressionOfInterestForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          //email
          const emailLabel = intl.formatMessage({
            id: 'ExpressionOfInterestForm.emailLabel',
          });
          const emailPlaceholder = intl.formatMessage({
            id: 'ExpressionOfInterestForm.emailPlaceholder',
          });
          const emailRequiredMessage = intl.formatMessage({
            id: 'ExpressionOfInterestForm.emailRequired',
          });
          const emailRequired = validators.required(emailRequiredMessage);
          const emailInvalidMessage = intl.formatMessage({
            id: 'ExpressionOfInterestForm.emailInvalid',
          });
          const emailValid = validators.emailFormatValid(emailInvalidMessage);

          const countyRequiredMessage = intl.formatMessage({
            id: 'ExpressionOfInterestForm.countyRequired',
          });
          const countyRequired = validators.required(countyRequiredMessage);

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div>
                <h3 className={css.editFormMessage}>
                  Interested in becoming a member of Signalise in future? Please fill out the form
                  below:
                </h3>
                <div className={css.name}>
                  <FieldTextInput
                    className={css.firstNameRoot}
                    type="text"
                    id="firstname"
                    name="firstname"
                    autoComplete="given-name"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={firstNameRequired}
                  />
                  <FieldTextInput
                    className={css.lastNameRoot}
                    type="text"
                    id="lastname"
                    name="lastname"
                    autoComplete="family-name"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={lastNameRequired}
                  />
                  <FieldTextInput
                    type="email"
                    id="email"
                    name="email"
                    autoComplete="email"
                    label={emailLabel}
                    placeholder={emailPlaceholder}
                    validate={validators.composeValidators(emailRequired, emailValid)}
                  />
                </div>

                <FieldTextInput
                  type="text"
                  className={css.field}
                  id="city"
                  name="city"
                  label="City (optional)"
                />

                <FieldSelect
                  className={css.field}
                  id="state"
                  name="state"
                  label="County"
                  validate={countyRequired}
                >
                  <option disabled value="">
                    Choose from...{' '}
                  </option>
                  {counties.map((a, i) => {
                    return (
                      <option key={i} value={a}>
                        {a}
                      </option>
                    );
                  })}
                </FieldSelect>
                <FieldSelect
                  id="userType"
                  name="userType"
                  label="I am interested in joining Signalise (optional)"
                >
                  <option disabled value="">
                    Choose from...
                  </option>
                  <option value="commProf">as a Communication Professional</option>
                  <option value="customer">to book services</option>
                  <option value="deafUser">as a Deaf person</option>
                </FieldSelect>
                <FieldTextInput
                  type="text"
                  className={css.field}
                  id="reason"
                  name="reason"
                  label="Reason for Interest (optional)"
                />
              </div>
              <div className={css.bottomWrapper}>
                {genericFailure}
                <PrimaryButton
                  type="submit"
                  className={css.primaryButtonRoot}
                  inProgress={inProgress}
                  ready={ready}
                  disabled={submitDisabled}
                >
                  Contact us
                </PrimaryButton>
                {expressionSent ? <p>Thank you for reaching out, we'll be in touch soon</p> : null}
                {expressionSent == false && (
                  <p>
                    We already have your email on file, contact hello@signalise.coop if you'd like
                    more information
                  </p>
                )}
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ExpressionOfInterestFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  someError: null,
  inProgress: false,
};

const { bool, string } = PropTypes;

ExpressionOfInterestFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const ExpressionOfInterestForm = compose(injectIntl)(ExpressionOfInterestFormComponent);
ExpressionOfInterestForm.displayName = 'ExpressionOfInterestForm';

export default ExpressionOfInterestForm;
