import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import data from '../../data/signalise.json';
import * as validators from '../../util/validators';
import { GoogleLocationAutocompleteInputField } from '../../components/LocationAutocompleteInput/GoogleAutocompleteInput';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  LocationAutocompleteInputField,
  FieldReactSelect,
} from '../../components';

import css from './GPDetailsForm.module.css';
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
} from '../../util/validators';
import { types as sdkTypes } from '../../util/sdkLoader';
const { LatLng } = sdkTypes;

const identity = v => v;

class GPDetailsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.submittedValues = {};
    this.state = {
      open: false,
      inputValue: '',
    };
    this.selectRef = React.createRef();
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            currentUser,
            disabled,
            formId,
            form,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            saveAddressDetailsError,
            updateInProgress,
            values,
            types,
            signaliseLocations,
            gpCodeOptions,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);
          const [gpCodes, setgpCodes] = useState([]);

          if (!user.id) {
            return null;
          }

          useEffect(() => {
            if (values?.GpCode) {
              const found = gpCodeOptions.find(item => item.key === values?.GpCode?.key);
              if (found && found.key != '9999') {
                form.change('GpName', found.GpName);
                const foundAddress = {
                  search: found.GpAddress.place,
                  selectedPlace: {
                    address: found.GpAddress.place,
                    origin: new LatLng(found.GpAddress.coords.lat, found.GpAddress.coords.lng),
                  },
                };
                form.change('GpAddress', foundAddress);
              } else {
                if (values.reset) {
                  form.change('GpName', undefined);
                  form.change('GpAddress', undefined);
                }
              }
            }
          }, [values?.GpCode, values.reset]);

          //TODO
          let genericError = null;

          if (saveAddressDetailsError) {
            genericError = (
              <span className={css.error}>
                <FormattedMessage id="AddressDetailsForm.genericFailure" />
              </span>
            );
          }

          const classes = classNames(rootClassName || css.root, className);
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitInProgress = updateInProgress;
          const submitDisabled =
            invalid || disabled || submitInProgress || values?.GpCode?.value == 9999
              ? !values.GpName ||
                !values.GpAddress ||
                !values.GpAddress?.search ||
                !values.GpAddress?.selectedPlace ||
                !values.GpAddress?.selectedPlace?.address ||
                !values.GpAddress?.selectedPlace?.origin
              : !values.GpCode;

          const addressRequiredMessage = intl.formatMessage({
            id: 'AddressDetailsForm.addressRequired',
          });
          const addressNotRecognisedMessage = intl.formatMessage({
            id: 'AddressDetailsForm.addressNotRecognised',
          });

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.contactDetailsSection}>
                <FieldReactSelect
                  className={css.features}
                  id={formId ? `${formId}.GpCode` : 'GpCode'}
                  name="GpCode"
                  options={gpCodeOptions}
                  autoComplete="GpCode"
                  label={"Doctor's address"}
                  placeholder={'Search for your doctor'}
                  validate={validators.required('Please select your doctor')}
                  isSearchable={true}
                  selectRef={value => {
                    this.selectRef = value;
                  }}
                  noOptionsMessage={() => (
                    <button
                      type="button"
                      style={{
                        width: '100%',
                        display: 'block',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        form.batch(() => {
                          form.change('reset', true);
                          form.change('GpCode', {
                            key: 9999,
                            value: 9999,
                            label: "I can't find my doctor",
                          });
                        });
                        this.setState({ open: false, inputValue: '' });
                        this.selectRef.blur();
                      }}
                    >
                      I can't find my doctor
                    </button>
                  )}
                  filterOption={(option, searchText) => {
                    const optionValue = option.value;
                    const optionLabel = option.label;
                    const results =
                      optionLabel.toLowerCase().indexOf(searchText.toLowerCase()) != -1;
                    // if (optionLabel.indexOf("can't") != -1) return false;
                    if (optionValue == 9999) return false;
                    if (results) return true;
                  }}
                  menuIsOpen={this.state.open}
                  onInputChange={inputValue => this.setState({ inputValue })}
                  inputValue={this.state.inputValue}
                  onFocus={() => {
                    this.setState({ open: true });
                  }}
                  onBlur={() => this.setState({ open: false })}
                  blurInputOnSelect
                  // closeMenuOnSelect

                  // disabled={isBillingCodeDisabled}
                />

                {/* <FieldTextInput
              className={css.password}
              type="text"
              id={formId ? `${formId}.GpAddress` : 'GpAddress'}
              name="GpAddress"
              autoComplete="GpAddress"
              label={'Organisation Address'}
              placeholder={'Organisation Address'}
              validate={validators.required('Organisation Address is required')}
            /> */}

                {values?.GpCode?.key == 9999 && (
                  <>
                    <FieldTextInput
                      className={css.features}
                      type="text"
                      id={formId ? `${formId}.GpName` : 'GpName'}
                      name="GpName"
                      autoComplete="GpName"
                      label={'Department / GP Name'}
                      placeholder={'Department / GP Name'}
                      validate={validators.required('Department / GP Name is required')}
                    />

                    <div className={css.features}>
                      <GoogleLocationAutocompleteInputField
                        label={'Organisation Address'}
                        placeholder={'Organisation Address'}
                        id={formId ? `${formId}.GpAddress` : 'GpAddress'}
                        useDefaultPredictions={false}
                        format={identity}
                        autoComplete="none"
                        name="GpAddress"
                        valueFromForm={values.GpAddress}
                        isTopBarSearchForm={true}
                        validate={composeValidators(
                          autocompleteSearchRequired(addressRequiredMessage),
                          autocompletePlaceSelected(addressNotRecognisedMessage)
                        )}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className={css.bottomWrapper}>
                {genericError}
                {values?.GpCode?.key == 9999 && (
                  <p style={{ margin: 0, lineHeight: 1.4 }}>
                    <small>
                      Sorry we don't have a contract with them but save your GP Address anyway.
                    </small>
                  </p>
                )}

                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={pristineSinceLastSubmit}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="AddressDetailsForm.saveChanges" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

GPDetailsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  saveAddressDetailsError: null,
  inProgress: false,
  jobTitle: null,
  addressLocation: null,
  addressBuilding: null,
  invoiceName: null,
  selectedPlace: null,
  fetchErrors: null,
};

const { bool, string } = PropTypes;

GPDetailsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  saveAddressDetailsError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
};

const GPDetailsForm = compose(injectIntl)(GPDetailsFormComponent);

GPDetailsForm.displayName = 'GPDetailsForm';

export default GPDetailsForm;
