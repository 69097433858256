//import merge from 'lodash/merge';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SAVE_GP_DETAILS_REQUEST = 'app/GPDetailsPage/SAVE_GP_DETAILS_REQUEST';
export const SAVE_GP_DETAILS_SUCCESS = 'app/GPDetailsPage/SAVE_GP_DETAILS_SUCCESS';
export const SAVE_GP_DETAILS_ERROR = 'app/GPDetailsPage/SAVE_GP_DETAILS_ERROR';

export const SAVE_GP_DETAILS_CLEAR = 'app/GPDetailsPage/SAVE_GP_DETAILS_CLEAR';

// ================ Reducer ================ //

const initialState = {
  saveGPDetailsInProgress: false,
  GPDetailsChanged: false,
  saveGPDetailsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_GP_DETAILS_REQUEST:
      return {
        ...state,
        saveGPDetailsInProgress: true,
        saveGPDetailsError: null,
        GPDetailsChanged: false,
      };
    case SAVE_GP_DETAILS_SUCCESS:
      return { ...state, saveGPDetailsInProgress: false, GPDetailsChanged: true };
    case SAVE_GP_DETAILS_ERROR:
      return { ...state, saveGPDetailsInProgress: false, saveGPDetailsError: payload };
    case SAVE_GP_DETAILS_CLEAR:
      return {
        ...state,
        saveGPDetailsInProgress: false,
        saveGPDetailsError: null,
        GPDetailsChanged: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveGPDetailsRequest = () => ({ type: SAVE_GP_DETAILS_REQUEST });
export const saveGPDetailsSuccess = () => ({ type: SAVE_GP_DETAILS_SUCCESS });

export const saveGPDetailsError = error => ({
  type: SAVE_GP_DETAILS_ERROR,
  payload: error,
  error: true,
});

export const saveGPDetailsClear = () => ({ type: SAVE_GP_DETAILS_CLEAR });

// ================ Thunks ================ //

/**
 * Make a address details update request to the API and return the current user.
 */
const requestSaveGPDetails = params => (dispatch, getState, sdk) => {
  const { GpCode, GpName, GpAddress, GpCoords } = params;

  // let locationData = {};

  // if (location) {
  //   const {
  //     selectedPlace: { address, origin, bounds },
  //   } = location;

  //   const { lat, lng } = origin;
  //   let boundingBoxMaybe;
  //   if (!bounds?.ne || !bounds?.sw) {
  //     boundingBoxMaybe = {};
  //   } else {
  //     const { ne, sw } = bounds;
  //     boundingBoxMaybe = {
  //       boundingBox: [
  //         { NElat: ne.lat, NElng: ne.lng },
  //         { SWlat: sw.lat, SWlng: sw.lng },
  //       ],
  //     };
  //   }

  //   locationData = {
  //     address: {
  //       address,
  //       building: '',
  //     },
  //     origin: { lat, lng },
  //     ...boundingBoxMaybe,
  //   };
  // }

  return sdk.currentUser
    .updateProfile(
      {
        privateData: {
          deafUserGpCode: GpCode,
          deafUserGpName: GpName,
          deafUserGpAddress: GpAddress,
          deafUserGpCoords: GpCoords,
        },
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveGPDetailsError(storableError(e)));
      // pass the same error so that the SAVE_GP_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

// Exported function
export const saveGPDetails = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestSaveGPDetails(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveGPDetailsSuccess());
      })
      // error action dispatched in requestSavePhoneNumber
      .catch(e => null)
  );
};
