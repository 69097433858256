import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { saveDoc } from '../../util/api';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { required, composeValidators } from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldCheckboxGroup,
  SecondaryButton,
  ValidationError,
} from '../../components';
import config from '../../config';
import hand from '../EditListingLegalForm/helping-hand-alert-transparent.png';
import css from './EditListingDBSForm.module.css';
import helpercss from '../ProfileSettingsForm/ProfileSettingsForm.module.css';
import FieldAirbnbDatePicker from '../../components/FieldAirbnbDateTimePicker/FieldAirbnbDatePicker';
import moment from 'moment';

const identity = v => v;

export class EditListingDBSFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successDBS: false,
      url: '',
      imageDBS: '',
      DBSUpload: '',
    };
  }

  onChangeDBS = e => {
    if (e.target.files[0]) {
      const imageDBS = e.target.files[0];
      this.setState(() => ({ imageDBS, successDBS: false }));
      console.log(imageDBS);
    }
  };

  onDBSUpload = async e => {
    e.preventDefault();
    console.log('start of DBS upload');

    const imageDBS = this.state.imageDBS;
    const date = new Date().toLocaleString('en-GB');
    const dateFormatted = date.replace(/\//g, '-');

    if (imageDBS === '') {
      console.error('no image chosen');
      this.setState({ successDBS: false, imageDBS: null });
      return;
    }

    const uploadResult = await saveDoc({
      file: imageDBS,
      originalFilename: imageDBS.name,
      purpose: 'DBS',
    });

    this.setState({
      url: uploadResult.fileId,
      successDBS: true,
      DBSUpload: `Upload reference: ${uploadResult.fileId}`,
      imageDBS: '',
    });
  };

  getFilePath(path) {
    if (path) {
      const filename = path.replace(/^.*\\/, '');
      return `Your saved file: ${filename}`;
    } else {
      return 'Please upload a file and then save the form using the button at the end of this form';
    }
  }

  render() {
    const isDBSEnabled = this.state.imageDBS.size > 1 && this.state.imageDBS != null;
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={formRenderProps => {
          const {
            className,
            disabled,
            ready,
            rootClassName,
            handleSubmit,
            intl,
            invalid,
            onChange,
            pristine,
            saveActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
            values,
            formId,
            initialValues,
          } = formRenderProps;

          const { birthday } = values;

          const hasDBSCertNumber = values.dbsCertificateNumber;
          const mustBeNumber = value =>
            value && isNaN(value) ? 'This should be a 12 digit number' : null;
          // Messaging and labels //
          const optionalText = intl.formatMessage({
            id: 'EditListingLocationForm.optionalText',
          });
          // dbs certificate label
          const dbsCertificateLabel = intl.formatMessage(
            { id: 'EditListingDBSForm.dbsCertificateLabel' },
            { optionalText: optionalText }
          );
          // dbs certificate label
          const dbsCertificatePlaceholder = intl.formatMessage({
            id: 'EditListingDBSForm.dbsCertificatePlaceholder',
          });
          const dbsCertificateAgreeCheckLabel = intl.formatMessage({
            id: 'EditListingDBSForm.dbsCertificateAgreeCheckLabel',
          });
          const { updateListingError, showListingsError } = fetchErrors || {};
          const errorMessage = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingRegistrationForm.updateFailed" />
            </p>
          ) : null;
          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingRegistrationForm.showListingFailed" />
            </p>
          ) : null;

          const SuccessMessage = () => (
            <div className={css.successMessage}>
              <img src={hand} alt="Signalise helping alert hand" className={css.alertHand}></img>
              <p>
                <strong>
                  <FormattedMessage id="EditListingRegistrationForm.uploadSuccess" />
                </strong>
              </p>
            </div>
          );
          const agreedDBSUpdateServiceCheckMessage = intl.formatMessage({
            id: 'EditListingDBSForm.agreedDBSUpdateServiceCheckMessage',
          });
          const dbsCertificateAgreeCheckRequired = validators.requiredFieldArrayCheckbox(
            agreedDBSUpdateServiceCheckMessage
          );
          const fileErrorMessage = intl.formatMessage({
            id: 'EditListingRegistrationForm.uploadNoFile',
          });

          // validate birthday
          const bRequired = validators.required('A valid date is required');
          const minAge = 18;
          const minAgeRequired = validators.ageAtLeast(
            `You need to be at least ${minAge} years old`,
            minAge
          );

          // birthday labels
          const birthdayLabel = intl.formatMessage({ id: 'PayoutDetailsForm.birthdayLabel' });
          const birthdayLabelMonth = intl.formatMessage({
            id: 'PayoutDetailsForm.birthdayLabelMonth',
          });
          const birthdayLabelYear = intl.formatMessage({
            id: 'PayoutDetailsForm.birthdayLabelYear',
          });
          const birthdayRequired = validators.required(
            intl.formatMessage({
              id: 'PayoutDetailsForm.birthdayRequired',
            })
          );

          const classes = classNames(rootClassName || css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const hasData = this.state.DBSUpload || this.props.initialValues.DBSUpload;
          const submitDisabled = !hasData || invalid || disabled || submitInProgress;
          const dbsCertificateAgreeCheckOptions = [
            {
              key: 'dbsCertificateAgreeCheckConfirmed',
              label: 'I give permission for Signalise to check my DBS status',
            },
          ];
          const DBSUpload = `${this.state.DBSUpload}`;
          const dobLabel = intl.formatMessage({ id: 'EditListingDBSForm.dobLabel' });
          return (
            <Form className={classes} onSubmit={handleSubmit}>
              {errorMessage}
              {errorMessageShowListing}

              <div className={helpercss.sectionContainer}>
                <h2 className={helpercss.sectionTitle}>
                  <FormattedMessage id="EditListingDBSForm.dbsTitle" />
                </h2>
                <h3>
                  <FormattedMessage id="EditListingDBSForm.dbsInstructions" />
                </h3>
                <p className={css.helperText}>
                  <FormattedMessage id="EditListingRegistrationForm.imageTypes" />
                </p>
                <Field
                  component="input"
                  id="DBSUpload"
                  name="DBSUpload"
                  DBSUpload={DBSUpload}
                  validate={composeValidators(required(fileErrorMessage))}
                >
                  {({ input, meta }) => (
                    <div className={css.inputContainer}>
                      <input
                        type="file"
                        className={css.upload}
                        accept=".jpg,.jpeg,.png,.pdf"
                        onChange={e => {
                          input.onChange(e); //final-form's onChange
                          if (onChange) {
                            //props.onChange
                            this.onChangeDBS(e);
                          }
                        }}
                      />
                      <ValidationError fieldMeta={meta} />
                    </div>
                  )}
                </Field>
                <SecondaryButton
                  type="button"
                  disabled={!isDBSEnabled}
                  onClick={this.onDBSUpload}
                  className={css.uploadButton}
                >
                  Upload
                </SecondaryButton>
                {this.state.successDBS ? <SuccessMessage /> : false}
                <div>
                  <p className={css.helperText}>
                    {this.getFilePath(this.props.initialValues.DBSUpload)}
                  </p>
                </div>
              </div>

              <div className={helpercss.sectionContainer}>
                <h2 className={helpercss.sectionTitle}>
                  <FormattedMessage id="EditListingDBSForm.dbsUpdateServiceTitle" />
                </h2>
                <h3>
                  <FormattedMessage id="EditListingDBSForm.dbsUpdate" />
                </h3>
                <p>
                  <FormattedMessage id="EditListingDBSForm.dbsUpdateProcess" />
                  For more information on this{' '}
                  <a href="https://www.gov.uk/dbs-update-service">
                    {' '}
                    visit the GOV.UK DBS update service website
                  </a>
                  .
                </p>
                <div className={css.elementMargin}>
                  <FieldTextInput
                    type="text"
                    id="dbsCertificateNumber"
                    name="dbsCertificateNumber"
                    label={dbsCertificateLabel}
                    placeholder={dbsCertificatePlaceholder}
                    validate={composeValidators(mustBeNumber)}
                    minLength={12}
                    maxLength={12}
                                        />
                </div>
                <div className={css.elementMargin}>
                  {hasDBSCertNumber && (
                    <p className={css.formInputHelperInfo}>
                      <FormattedMessage id="EditListingDBSForm.dbsUpdateDOBInfo" />
                    </p>
                  )}

                  <FieldAirbnbDatePicker
                    name="birthday"
                    id="birthday"
                    label={dobLabel}
                    validate={composeValidators(required('Date of birth is required'))}
                    maxDate={moment(new Date())
                      .subtract(18, 'years')
                      .toISOString()}
                  />
                </div>
                <div className={css.elementMargin}>
                  {hasDBSCertNumber && (
                    <FieldCheckboxGroup
                      className={css.features}
                      id="dbsCertificateAgreeCheck"
                      name="dbsCertificateAgreeCheck"
                      label={dbsCertificateAgreeCheckLabel}
                      options={dbsCertificateAgreeCheckOptions}
                      validate={dbsCertificateAgreeCheckRequired}
                    />
                  )}
                </div>
              </div>

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingDBSFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingDBSFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  dbsCertificateAgreeCheck: shape({
    dbsCertificateAgreeCheck: string,
  }),
  DBSUpload: object.isRequired,
  dbsCertificateNumber: string,
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingDBSFormComponent);
