const fixedCanonicalUrls = {
  '/p/blog-190724-signco-platform-coop':
    'https://medium.com/signalisecoop/signco-io-why-do-we-need-a-platformcoop-to-deliver-interpreting-services-for-deaf-people-8360e4250068',
  '/p/blog-191106-history-of-interpreter-contracts':
    'https://medium.com/signalisecoop/the-history-of-british-sign-language-interpreter-contracts-in-merseyside-and-reversing-the-effects-47b14cfad59c',
  '/p/blog-191125-unltd-support':
    'https://medium.com/signalisecoop/signco-io-secures-support-from-unltd-delivering-social-change-in-our-community-b80a7f7f9048',
  '/p/blog-191218-importance-of-signco':
    'https://medium.com/signalisecoop/the-rise-of-amateurisation-and-why-signco-io-is-important-fcfc2e10e604',
  '/p/blog-200115-renaming-and-rebranding':
    'https://medium.com/signalisecoop/renaming-and-re-branding-has-begun-84aa29b687b7',
  '/p/blog-200703-signco-update':
    'https://medium.com/signalisecoop/signco-yes-we-are-still-here-3c3013ab14a5',
  '/p/blog-200819-new-name':
    'https://medium.com/signalisecoop/signco-no-more-introducing-our-new-name-and-branding-ff53cf85ed8',
  '/p/blog-200828-signing-the-right-path':
    'https://medium.com/signalisecoop/signing-the-right-path-report-published-a5c1f66d49af',
  '/p/blog-201001-support-signalise':
    'https://medium.com/signalisecoop/supportsignalise-were-crowdfunding-532ace5c1b72',
  '/p/blog-201015-live-q-and-a-highlights':
    'https://medium.com/signalisecoop/live-q-a-highlights-6f12fa91b1c4',
  '/p/blog-201105-crowdfunder':
    'https://medium.com/signalisecoop/lets-do-a-crowdfunder-let-s-do-it-during-a-global-pandemic-said-no-one-ever-d0e4f408119b',
  '/p/blog-201210-unite-the-union-support':
    'https://medium.com/signalisecoop/local-unite-branches-back-signalise-79af46a764c9',
  '/p/blog-201217-summary-of-2020':
    'https://medium.com/signalisecoop/2020-hasnt-all-been-bad-45aff12cd788',
  '/p/blog-210112-new-years-update':
    'https://medium.com/signalisecoop/new-years-update-658852781239',
  '/p/blog-210114-channel-swim-fundraiser':
    'https://medium.com/signalisecoop/clare-chilton-is-swimming-the-english-channel-for-signalise-985291b2544b',
  '/p/blog-210122-our-new-team':
    'https://medium.com/signalisecoop/introducing-our-new-team-ea136ad50364',
  '/p/blog-210427-community-share-offer':
    'https://medium.com/signalisecoop/signalise-co-op-is-growing-and-we-want-you-to-be-part-of-it-e2890e38380c',
  '/p/blog-210505-geraldine-ohalloran-board-member':
    'https://medium.com/signalisecoop/geraldine-ohalloran-why-i-got-involved-in-signalise-co-op-2b2f1882f913',
  '/p/blog-210518-sarah-hannett-joins-board':
    'https://medium.com/signalisecoop/sarah-hannett-joins-the-signalise-board-54bc16df95d1',
  '/p/blog-210816-main-provider-for-liverpool-ccg':
    'https://medium.com/signalisecoop/signalise-co-op-announced-as-nhs-liverpool-ccg-main-provider-5a52b1346fdc',
  '/p/blog-211203-international-day-of-disabled-people':
    'https://medium.com/signalisecoop/international-day-of-disabled-people-ec43e1e1aeb1',
  '/p/blog-220404-welcome-romy':
    'https://medium.com/signalisecoop/a-very-warm-welcome-to-romy-af1a472ce8a',
  '/p/blog-220914-comings-and-goings':
    'https://medium.com/signalisecoop/coming-and-goings-at-signalise-co-op-4123b8d6138',
  '/p/blog-221208-summary-of-2022':
    'https://medium.com/signalisecoop/what-a-year-heres-a-summary-of-2022-ab9df704fa19',
  '/p/blog-230503-new-contract':
    'https://medium.com/signalisecoop/we-have-some-exciting-news-to-share-with-you-f77a71d36dde',
  '/p/blog-230509-tech-update':
    'https://medium.com/signalisecoop/tech-update-what-we-have-been-up-to-6b2b904c35aa',
  '/p/blog-230524-managing-a-migration':
    'https://medium.com/signalisecoop/managing-a-migration-406f8e539d33',
  '/p/blog-230613-top-5-benefits':
    'https://medium.com/signalisecoop/top-5-benefits-of-our-video-interpreting-services-for-the-nhs-7c1135c7cfc5',
  '/p/blog-231220-tech-development':
    'https://medium.com/signalisecoop/hunker-down-it-was-time-for-consolidation-053448d0af3c',
  '/p/blog-240209-bye-bye-2023': 'https://medium.com/signalisecoop/bye-bye-2023-8ce1ac38ac7d',
  '/p/blog-240320-hello-2024': 'https://medium.com/signalisecoop/hello-spring-2024-abeedba0966f',
};
const canonicalBlogURL = pathname => {
  return fixedCanonicalUrls[pathname];
};

export default canonicalBlogURL;
