import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import css from './JoinUsContent.module.css';

const JoinUsContent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>Do you live or work in the North West? Are you a deaf person, use BSL and interpreters 
        or other types of communication professionals? 
        Are you a BSL/English interpreter or translator? Are you a different language professional 
        e.g. lipspeaker, speech-to-text-reporter etc...? You don't have a conflict of interest?
        Good news! You are eligible to join Signalise Co-operative as a member. </p>

      <p>Our membership is what sets us apart from our competitors. We are run by and for deaf people 
        and interpreters/translators. 
        If you want to improve access standards and change the way that services are delivered, sign up today.</p>
        
        <p>You will be asked to pay £1 for your share in Signalise and will need to agree to follow our by-laws, 
        members’ commitment and the co-operative principles. 
        By working together, we can revolutionise how services are booked and delivered. Join us!</p>

      <p>If you would like to become a member see the steps below: </p>
      <ol>
        <li>Check you are allowed to join (North West / Deaf or a communication professional / no conflict of interest) </li>
        <li>Fill out the membership <a href="https://forms.gle/3LMyazAX4GQhyCyC9">joining form</a></li>
        <li><a href="https://pay.sumup.com/b2c/QBI7NPH2">Pay £1 via our SumUp account</a> and <a href="mailto:hello@signalise.coop">email us</a> to let us know you have paid. Or contact us for bank transfer information.</li>
      </ol>
      <p>If you would like to know more please get in touch at <a href="mailto:hello@signalise.coop">hello@signalise.coop</a>.</p>

      <p>Don’t meet the criteria above? Look out for opportunities to become an <Link to="join-us-investors">Investor Member</Link> and fill our the form below. </p> 
    </div>
  );
};

JoinUsContent.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

JoinUsContent.propTypes = {
  rootClassName: string,
  className: string,
};

export default JoinUsContent;
