import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-liverpool',
    predictionPlace: {
      address: 'Liverpool, Merseyside, England, United Kingdom',
      bounds: new LatLngBounds(
        new LatLng(53.589405, -2.777467),
        new LatLng(53.322243, -3.10547)
      ),
      origin: new LatLng(53.589405, -2.777467),
    },
  },
  {
    id: 'default-sefton',
    predictionPlace: {
      address: 'Sefton, Liverpool, Merseyside, England, United Kingdom',
      bounds: new LatLngBounds(
        new LatLng(53.5195298045611, -2.94641842948246),
        new LatLng(53.4892822088692, -2.99944021112905)
      ),
      origin: new LatLng(53.504, -2.97143),
    },
  },
  {
    id: 'default-knowsley',
    predictionPlace: {
      address: 'Knowsley, Merseyside, England, United Kingdom',
      bounds: new LatLngBounds(
        new LatLng(53.5039071217145, -2.80569515811073),
        new LatLng(53.3949445792062, -2.92261611058295)
      ),
      origin: new LatLng(53.5039071217145, -2.80569515811073),
    },
  },
  {
    id: 'default-st-helens',
    predictionPlace: {
      address: 'St Helens, Merseyside, England, United Kingdom',
      bounds: new LatLngBounds(
        new LatLng(53.4927847143492, -2.57674303884982),
        new LatLng(53.4094723383281, -2.77929402531203)
      ),
      origin: new LatLng(53.4927847143492, -2.57674303884982),
    },
  },
  {
    id: 'default-wirral',
    predictionPlace: {
      address: 'Wirral, Merseyside, England, United Kingdom',
      bounds: new LatLngBounds(
        new LatLng(53.425108, -2.939519),
        new LatLng(53.296265, -3.229018)
      ),
      origin: new LatLng(53.425108, -2.939519),
    },
  },
  {
    id: 'default-cheshireWest',
    predictionPlace: {
      address: 'Cheshire West and Chester, England, United Kingdom',
      bounds: new LatLngBounds(
        new LatLng(53.16610935238498, -2.727878065729086),
        new LatLng(53.3050200681699, -2.83245725594396)
      ),
      origin: new LatLng(53.16610935238498, -2.727878065729086),
    },
  },
  {
    id: 'default-cheshireEast',
    predictionPlace: {
      address: 'Cheshire East, England, United Kingdom',
      bounds: new LatLngBounds(
        new LatLng(53.17085226649275, -2.3561374775353014),
        new LatLng(53.3050200681699, -2.83245725594396)
      ),
      origin: new LatLng(53.17085226649275, -2.3561374775353014),
    },
  },
  {
    id: 'default-halton',
    predictionPlace: {
      address: 'Halton, Merseyside, England, United Kingdom',
      bounds: new LatLngBounds(
        new LatLng(53.4025371549728, -2.5952223988011),
        new LatLng(53.3050200681699, -2.83245725594396)
      ),
      origin: new LatLng(53.4025371549728, -2.5952223988011),
    },
  },
  {
    id: 'default-warrington',
    predictionPlace: {
      address: 'Warrington, England, United Kingdom',
      bounds: new LatLngBounds(
        new LatLng(53.496582,-2.44894),
        new LatLng(53.283198, -2.697725)
      ),
      origin: new LatLng(53.496582,-2.44894),
    }
  },
  {
    id: 'default-lancashire',
    predictionPlace: {
      address: 'Lancaster, Lancashire, England, United Kingdom',
      bounds: new LatLngBounds(
        new LatLng(54.172777,-2.299964),
        new LatLng(53.9243, -2.883175)
      ),
      origin: new LatLng(54.172777,-2.299964),
    },
  },
  {
    id: 'default-manchester',
    predictionPlace: {
      address: 'Manchester, Greater Manchester, England, United Kingdom',
      bounds: new LatLngBounds(
        new LatLng(53.586199, -2.087698),
        new LatLng(53.348612, -2.52355)
      ),
      origin: new LatLng(53.586199, -2.087698),
    },
  },
];
export default defaultLocations;
